import api from "api"
import Card from "components/Card"
import { useAuth } from "components/ProvideAuth/hook"
import { useState , useEffect } from "react"
import { toast } from "react-toastify"
import PreviewImageModal from "../Locations/PreviewImageModal"
import styles from "./AssemblyPoints.module.scss"
const AssemblyPoints = () => {

    const auth = useAuth()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileType, setFileType] = useState('');

    const [list , setList] = useState([])

    const previewFile = ( arrayIndex: number , index: number, isUrl = false) => {
        if (!isUrl) {
            if (index > -1) {
                const file = list[arrayIndex].attachments[index];
                if (!file) {
                    setPreviewUrl(null);
                    return;
                }
                else {
                    setPreviewUrl(`${process.env.REACT_APP_API_URL}uploads/locations${file?.file_path}`);
                    setFileType(file?.type);
                    setIsModalOpen(true);
                }
            }
        }
    }

    useEffect ( () => {
        const companyId = auth.company.id;
        const id = auth.user.uuid;
        api.locations.getUserLocations({companyId , id}).then ((result) => {
            setList(result.map((res) =>{
                return {
                    title : res.title,
                    address : res.address, 
                    primary : res.primary_assembly_point,
                    secondary : res.secondary_assembly_point,
                    attachments : res.location_files,
                    city: res.city,
                    state: res.state,
                    zip: res.zip
                }
            }))
        }).catch((error) => {
            toast.error(error);
        })
    },[])
    return (
        <div>
            <div className={styles.mainHeading}>Assembly Points</div>
            {
                list.length !== 0 ? 
                    <>
                    <div className={styles.cardContainer}>
                        {
                        list.map( (card , key) => {
                            const title = card?.title;
                            const titleToMap = card?.title.charAt(0).toUpperCase() + title.slice(1);
                            return (
                                <Card style={{padding : "20px 20px" , width : "330px"}} key={key}>
                                    <div style= {{display : "flex" , flexDirection : "column" , gap : "8px"}}>
                                        <div className={styles.cardHeading}>{titleToMap}</div>
                                        <div>{`${card?.address}, ${card?.city}, ${card?.state}, ${card?.zip}`}</div>
                                        <div className={styles.cardHeading}>Primary Assembly Point</div>
                                        <div >{card?.primary}</div>
                                        <div className={styles.cardHeading}>Secondary Assembly Point</div>
                                        <div>{card?.secondary}</div>
                                        <div className={styles.cardHeading}>Attachments</div>
                                        <div style={{display : "flex" , flexDirection: "column" , gap :"8px"}}>
                                            {card?.attachments?.map( (attach , index) => {
                                                return (
                                                    <div key={index} onClick={() => previewFile(key , index)} style={{cursor : "pointer" , textDecoration: "underline" , color: "red"}}> {attach?.file_name} </div>
                                                )
                                            })}
                                            {
                                                card?.attachments?.length === 0 || !card?.attachments && 
                                                <div>
                                                    There are no attachments
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>
                                )
                            })
                        }
                    </div>
                    </> 
                : 
                    <div className={styles.noLocs}>
                        There are no Locations
                    </div>
            }
            {isModalOpen && <PreviewImageModal previewUrl={previewUrl} onClose={() => setIsModalOpen(false)} fileType={fileType} />}
        </div>
    )
}

export default AssemblyPoints