import { memo } from 'react';
import styles from './BreadCrumbs.module.scss';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { useAuth } from 'components/ProvideAuth/hook';

export interface IBreadCrumbItem {
    name: string;
    url?: string;
}

const BreadCrumbs = ({ list, root = 'Home', rootLinkTo = '/home' }: { list: Array<IBreadCrumbItem>; root?: string; rootLinkTo?: string; }) => {

    const superAdmin = useAuth().user.user_type === "superAdmin"
    return (
        <nav className={styles.root}>
            {!superAdmin && <span className={styles.flex}><Link className={styles.link} to={rootLinkTo}> <FaHome size={14} className={styles.icon} /> <span className={styles.text}>{root}</span></Link></span>}
            {list?.map?.((item, key) => (
                <span key={key}>
                    <span className={styles.page}>&raquo;</span>
                    {item.url ? (
                        <Link to={`/${item.url}`} className={styles.text}>
                            {(list.length === key + 1) ? (<b>{item.name}</b>) : `${item.name}`}
                        </Link>
                    ) : (
                        <span className={styles.text}>{(list.length === key + 1) ? (<b>{item.name}</b>) : `${item.name}`}</span>
                    )}
                </span>
            ))}
        </nav>
    );

};

export default memo(BreadCrumbs);