import { useAtomValue,useAtom } from 'jotai';
import isSidebarOpenState from 'state/isSidebarOpen';

export const useSidebarVisible = () => {
    return useAtomValue(isSidebarOpenState);
}

export const useSidebarToggle = () => {
    let [isSidebarOpen,toggleSidebar] = useAtom(isSidebarOpenState);
    return visible => toggleSidebar(visible);
}