import { memo } from 'react';
import ReactDOM from 'react-dom';
import cs from './component.module.scss';
import { IoMdClose } from 'react-icons/io';

const Modal = ({ title = 'Untitled', isOpen = true, onClose = null, maxWidth = '540px', hasPadding = true, overflow, overflowX, heightMargin = 0, style = {}, portal = true, children }: {
  title?: string; isOpen?: boolean; onClose?: any; maxWidth?: string; hasPadding?: boolean; overflow?: string; overflowX?: string; heightMargin?: number; style?: any; portal?: boolean; children?: any
}) => {
  const rootClassName = isOpen ? '' : cs.hidden;
  const contentStyle = {
    maxWidth: maxWidth,
    padding: hasPadding ? '20px 20px 30px' : 0,
    overflowY: overflow,
    overflowX: overflowX,
    maxHeight: `calc(100vh - ${heightMargin}px)`,
    ...style,
  };

  let extraStyle = {};
  if (style?.zIndex) {
    extraStyle = { style: { zIndex: (style?.zIndex) } };
  }

  if (portal) {
    return ReactDOM.createPortal(
      <div className={rootClassName}>
        <div className={cs.overlay} {...extraStyle} onClick={onClose} role="presentation" />
        <div className={cs.content} style={contentStyle}>
          {title && (
            <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'space-between', fontSize: "1.42857143em", fontWeight: 500, lineHeight: 1.2, letterSpacing: '-.008em', marginBottom: 16 }}>
              <span>{title}</span>
              <IoMdClose style={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
          )}
          {children}
        </div>
      </div>
      ,
      document.getElementsByTagName('body')[0]
    );
  } else {
    return (
      <div className={rootClassName}>
        <div className={cs.overlay} onClick={onClose} role="presentation" />
        <div className={cs.content} style={contentStyle}>
          {title && (
            <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'space-between', fontSize: "1.42857143em", fontWeight: 500, lineHeight: 1.2, letterSpacing: '-.008em', marginBottom: 16 }}>
              <span>{title}</span>
              <IoMdClose style={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }

};

export default memo(Modal);
