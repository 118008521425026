import {memo} from 'react';
import { Route, Navigate,useLocation } from 'react-router-dom';
import { useAuth } from 'components/ProvideAuth/hook';
import AccessDenied from 'components/AccessDenied';
// import { useSelector } from 'react-redux';
import { hasPerm } from 'shared/utils/accessControl';
// import { useEffect } from 'react';
//import styled from 'styled-components';
// import styles from './PrivateRoute.module.scss';


const PrivateRoute = ({ path,element,children = null,permission = null, ...rest }:{path?:string;element:any;children?:any;permission?:any;}) => {
  // let state = useSelector(state => state);
  // const userPermissions = useSelector<any>(state => state?.userPermissions);

  // let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  // console.log('PrivateRoute->',hasPerm(permission,userPermissions));
  // if (permission && !hasPerm(permission,userPermissions)) {
  //   return <AccessDenied />;
  // }

  if(!auth?.user){
    return <AccessDenied />;
  }
  // useEffect(() => {

  // },[]);

  // console.log('auth->',auth);
  // else-> (<Navigate to="/login" state={{from:location.pathname}} />)
  return element;
};

export default memo(PrivateRoute);