import { memo } from "react";
import styles from "./HomeWidget.module.scss";
import Card from "components/Card";

const HomeWidget = ({
  onClick = null,
  label,
  children,
  icon,
  backgroundColor = "#D1E5FF",
}) => {
  return (
    <div onClick={onClick} className={styles.root}>
      <Card
        style={{
          width: 280,
          height: 210,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'column'
        }}
      >
        <div style={{marginRight: '0px', display: 'contents'}}>
          <div className={styles.icon} style={{ backgroundColor }}>
            {icon}
          </div>
          <div className={styles.label}>{label}</div>
          <div className={styles.number}>{children}</div>
        </div>
      </Card>
    </div>
  );
};

export default memo(HomeWidget);
