import BreadCrumbs from "components/BreadCrumbs"
import Heading from "components/Heading"
import TopHeader from "components/TopHeader"
import Table from "components/Table";
import { useCallback, useReducer, useState } from "react";
import basicReducer from "reducers/basicReducer";
import api from "api";
import Actions from "./Actions";
import { Chip } from "@mui/material";
import { toast } from "react-toastify";


var tempSearchValue = ""
const columns = [
    {
        Header: "Company",
        accessor: "companyName",
    },
    {
        Header: "No. of Contacts",
        accessor: "size",
    },
    {
        Header: "Contact",
        accessor: "contact",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: "User Type",
        accessor: "userType",
    },
    {
        Header: "Status",
        accessor: "isSuccessful",
        disableSortBy: true,
		disableFilters: true,
		hideFilter: true,
    },
    {
		Header: 'Actions',
		accessor: 'actions',
		disableSortBy: true,
		disableFilters: true,
		hideFilter: true,
		width: 120
	},
  ];

const Companies = () => {

    const [state, setState] = useReducer(basicReducer, {
        list: [],
        pageSize: 10,
        showImportModal: false,
    });
    const [companySize, setCompanySizeValue] = useState(0)
    const [pageCount, setPageCount] = useState(0);


    // const getSizeOfCompany = (val : number) => { // For select size
    //     const arr = [{value:1,label:'10-20'},{value:2,label:'20-40'},{value:3,label:'40-60'},{value:4,label:'60-100'}]
    //     const values =  arr.map ((object) => {
    //         if (object.value === val)
    //         return  object.label
    //     })
    //     return values
    // }

    const onApprove = (obj) => {
        api.users.updateSuccessfullyRegister({ id: obj?.user?.id, isSuccessfulRegistration: !obj.user?.isSuccessfulRegistration}).then( () => {
            toast.success("Status updated successfully")
            loadList({ action: "loadList", pageSize: 10, pageIndex: 1 });
        }).catch ((msg)=> {
            toast.error(msg.data.message[0]);
        });
    }

    const loadList = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
        let filter = { search: value ?? tempSearchValue };
        if (action === "search") {
          tempSearchValue = value;
        }
        filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
        api.users.listCompanyDetails({options : filter }).then((list) => {
            let formattedData = [];
            if (list?.result?.length > 0) {
                formattedData = list?.result?.map((item) => ({
                    id: item?.user?.id,
                    name: `${item?.user?.firstName} ${item?.user?.lastName}`,
                    email: item?.user?.email,
                    userType: item?.user?.userType,
                    companyName: item?.company?.name,
                    size: item?.company?.numberOfContacts,
                    contact: item?.company?.contact,
                    isSuccessful: <Chip label={item?.company?.isActive ?  "Active" :  "Not active"} color={item?.company?.isActive ? "success" : "error"} />,
                    actions: <Actions id={item?.user?.id} onDisable={() => onApprove(item)} approved={item?.company?.isActive} />
                    
                }));
                setPageCount(Math.ceil(list?.pagination?.meta?.totalItems / pageSize));
                setState({ totalCount: list?.pagination?.meta?.totalItems });
            }
            setState({ list: formattedData });
          });
      };

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        loadList({ action: "loadList", pageSize, pageIndex: pageIndex + 1 });
    }, []);

    const updatePageSize = (size) => {
        setState({ pageSize: size });
    }

    return (
        <div>
            <BreadCrumbs
                list={[
                    {
                        name: "Companies",
                    },
                ]}
            />
            <TopHeader>
                <Heading>Companies</Heading>
            </TopHeader>
			<Table columns={columns} data={state?.list} pageCount={pageCount} fetchData={fetchData} parentPageSize={updatePageSize} totalCount={state?.totalCount} />
        </div>
    )
}

export default Companies