import { memo, useReducer, useState } from "react";
import styles from "./Register.module.scss";

import PublicLayout from "layout/PublicLayout";
import { useAuth } from "components/ProvideAuth/hook";
import Input from "components/Form/Input";
import Button from "components/Button";
import Group from "components/Group";
import basicReducer from "reducers/basicReducer";
import { useLocation, useNavigate } from "react-router-dom";
import rightImage from "assets/reminder_re_fe15.svg";
import Select from "components/Form/Select";
import { Tooltip } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import Loading from "components/Loading";

const Register = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [IsLoading, setLoading] = useState(false);

  let [form, setForm]: [any, any] = useReducer(basicReducer, {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    name: "",
    size_id: 1,
    category_id: null,
    contact: "",
    coordinates: "none",
    active: true,
    prefer_via_invoice: false,
  });

  const doRegister = () => {
    if (
      !form.first_name ||
      !form.last_name ||
      !form.email ||
      !form.password ||
      !form.name ||
      !form.contact
    ) {
      toast.error("Fields are missing. All fields must be filled!");
      return;
    }

    const standardEmail =
      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;
    if (!standardEmail.test(form.email)) {
      toast.error("Email Must be Business Email!");
      return;
    }
    setLoading(true);
    auth.signup({ ...form, contact: "+1" + form.contact }, (res, IsLoading) => {
      setLoading(IsLoading);
      if (IsLoading) {
        toast.success("Sign up Process succesfully completed.");
        let { from }: any = location?.state || {
          from: { pathname: "/home" },
        };
        navigate(from, { replace: true });
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Loading fixed={IsLoading} size="sm" type="ball-pulse-sync" />
      <PublicLayout rightContent={<img src={rightImage} height={300} />}>
        <div className={styles.root}>
          {/* <div className="text-center*">
                        <h3>Login</h3>
                    </div> */}
          <form className="user">
            <Group vertical={false}>
              <Group label={`First Name`}>
                <Input
                  type="text"
                  className="form-control form-control-user"
                  value={form?.first_name}
                  name="first_name"
                  onChange={(first_name) => setForm({ first_name })}
                  placeholder="First name"
                />
              </Group>
              <Group label={`Last Name`}>
                <Input
                  type="text"
                  className="form-control form-control-user"
                  value={form?.last_name}
                  name="last_name"
                  onChange={(last_name) => setForm({ last_name })}
                  placeholder="Last name"
                />
              </Group>
            </Group>
            <Group vertical={false}>
              <Group label={`Email Address`}>
                <Input
                  type="email"
                  className="form-control form-control-user"
                  value={form?.email}
                  name="email"
                  onChange={(email) => setForm({ email })}
                  placeholder="Enter Address"
                />
              </Group>
              <Group label={`Password`}>
                <Input
                  type="password"
                  className="form-control form-control-user"
                  marginLeft="165px"
                  value={form?.password}
                  name="password"
                  onChange={(password) => setForm({ password })}
                  placeholder="Password"
                />
              </Group>
            </Group>
            <Group vertical={false}>
              <Group label={`Company Name`}>
                <Input
                  type="text"
                  className="form-control form-control-user"
                  value={form?.name}
                  name="name"
                  onChange={(name) => setForm({ name })}
                  placeholder="Company name"
                />
              </Group>
              <Group label={`Mobile Number`}>
                <div className={styles.numberWrapper}>
                  US(+1)
                  <Input
                    type="text"
                    className="form-control form-control-user"
                    value={form?.contact}
                    name="contact"
                    onChange={(contact) => setForm({ contact })}
                    placeholder="123456789"
                    style={{ marginTop: "7px" }}
                  />
                </div>
              </Group>
            </Group>
            <Group style={{ marginTop: 20 }}>
              <Button
                style={{ width: 400, fontWeight: "bold" }}
                contentDirection="center"
                disabled={IsLoading}
                view="primary"
                type="button"
                onClick={doRegister}
              >
                Sign Up
              </Button>
            </Group>
          </form>
          <br />
          <div className={styles.loginWrapper}>
            Already have an account?&nbsp;
            <a className={styles.termsPolicy} href="/login">
              Login
            </a>
          </div>

          <Group className={styles.privacyMain}>
            <div className={styles.privacyFirst}>
              By using our services you agree to our{" "}
            </div>
            <div>
              <span
                className={styles.termsPolicy}
                onClick={() =>
                  window.open(
                    "https://continuityinnovations.com/terms-of-use-emeva",
                    "_blank"
                  )
                }
              >
                Terms of Use
              </span>
              &nbsp;and&nbsp;
              <span
                className={styles.termsPolicy}
                onClick={() =>
                  window.open(
                    "https://continuityinnovations.com/privacy-policy",
                    "_blank"
                  )
                }
              >
                Privacy Policy
              </span>
            </div>
          </Group>
          <br />
        </div>
      </PublicLayout>
    </>
  );
};

export default memo(Register);
