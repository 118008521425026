import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Actions.module.scss';
import Button from 'components/Button';
import { FaTrash, FaEdit, FaUnlock } from 'react-icons/fa';

const Actions = props => {
    const { id, deleteItem, unlockItem} = props;
    const navigate = useNavigate();
    return (
        <div className={styles.root}>
            <Button onClick={() => navigate(`edit/${id}`)}><FaEdit /></Button>
            {/* <Button style={{marginLeft:10}}>Copy</Button> */}
            <Button onClick={() => deleteItem(id)} view="danger" requireConfirmation={true} style={{ marginLeft: 10 }} ><FaTrash /></Button>
            {/* <Button>Edit</Button> */}
            {unlockItem && <Button onClick={() => unlockItem(id)} view="danger" style={{ marginLeft: 10 }} ><FaUnlock /></Button>}
        </div>
    );

};

export default memo(Actions);