import internalApi from "shared/utils/internalApi"


export const listLocations = ({companyId, page = 1 , limit = 10, getAll = false , search = ""}) => {
    return internalApi.get({ server: `company/`, path: `${companyId}/locations/?page=${page}&limit=${limit}&getAll=${getAll}&search=${search}`});
}

export const getLocation = ({companyId,id}) => {
    return internalApi.get({ server: `company/`, path: `${companyId}/locations/${id}` });
}

export const getUserLocations = ({companyId,id}) => {
  return internalApi.get({ server: `company/`, path: `${companyId}/locations/user/${id}` });
}

export const createLocation = ({companyId,title,address,city,state,zip,primary_assembly_point,secondary_assembly_point, locationFiles}) => {
    return internalApi.post({ server: `company/`, path: `${companyId}/locations/` },{title,address,city,state,zip,primary_assembly_point,secondary_assembly_point, locationFiles});
}

export const updateLocation = ({companyId,id,title,address,city,state,zip,primary_assembly_point,secondary_assembly_point, locationFiles, existingFiles}) => {
    return internalApi.patch({ server: `company/`, path: `${companyId}/locations/${id}` },{title,address,city,state,zip,primary_assembly_point,secondary_assembly_point, locationFiles, existingFiles});
}

export const deletLocation = ({companyId ,id}) => {
  return internalApi.delete({ server: `company/`, path: `${companyId}/locations/${id}` });
}

export const deletLocationFile = ({companyId ,id}) => {
  return internalApi.delete({ server: `company/`, path: `${companyId}/location-files/${id}` });
}

export const importLocations = async (
    companyId: string,
    payload: any
  ) => {
    return  internalApi.post({ server: `company/`, path: `${companyId}/locations/import` } , payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  export const uploadFile = async (
    companyId: string,
    payload: any
  ) => {
    return  internalApi.post({ server: `company/`, path: `${companyId}/locations/uploadlocation` } , payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
