import { memo,useId } from 'react';
import styles from './Radio.module.scss';

const Radio = ({value,label,checked,disabled,onChange}:{disabled?:boolean;value:string;label?:string;checked:boolean;onChange?:Function;}) => {
    let id = useId();

    const _onChange = ({ target }) => {
        // const { disabled, field, onChange } = this.props;
    
        if (disabled) {
          return;
        }
    
        const { name, value } = target;
    
        onChange(value);
    };

    return (
        <label htmlFor={id}>
            <input
                type="radio"
                id={id}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={_onChange}
                // onClick={this.props.onClick}
            />
            <span style={{ marginLeft: 10 }}>{label?label:value}</span>
        </label>
    );

};

export default memo(Radio);