import { memo, useReducer, useState } from 'react';
import { useLocation, useNavigate, Link, useParams, useSearchParams } from 'react-router-dom';
import PublicLayout from 'layout/PublicLayout';
import styles from './VerifyOTP.module.scss';

import { useAuth } from 'components/ProvideAuth/hook';
import Input from 'components/Form/Input';
import Button from 'components/Button';
import Group from 'components/Group';
import basicReducer from 'reducers/basicReducer';
// import swal from 'sweetalert';
import rightImage from 'assets/reminder_re_fe15.svg';
import { toast } from 'react-toastify';

const VerifyOTP = props => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isOTPVerified, setIsOTPVerified] = useState(false);

    let [form, setForm]: [any, any] = useState({
        email: '',
        otp: '',
    });

    let [resetForm, setResetForm]: [any, any] = useState({
        newPassword: '',
        confirmPassword: '',
        otp: '',
        email: ''
    });

    const [userEmail, setUserEmail] = useState('');

    let { from }: any = location?.state || { from: { pathname: "/home" } };

    const resendOTP = () => {
        form.email = searchParams.get('email')
        auth.forgotPassword(form,() => {   
            // navigate(`/verify-otp?email=${form?.email}`);
            toast.success("OTP sent. Please check your inbox.")
        });
    }

    const resetPassowrd = () => {
        form.email = searchParams.get('email')
        auth.verifyOTP(form, () => {
            // navigate('/verify-otp');
            setIsOTPVerified(true)
            setUserEmail(form?.email)
        });
    }

    const resetPassowrdFinal = () => {
        if (!resetForm?.newPassword || !resetForm?.confirmPassword) return toast.error("Password is required")
        if (resetForm?.newPassword !== resetForm?.confirmPassword) return toast.error("Password and Confirm password does not match")
        if ((resetForm.newPassword && resetForm.confirmPassword) && (resetForm?.newPassword.length < 8 || resetForm?.confirmPassword.length < 8)) return toast.error("Password should be atleast 8 characters")

        resetForm.otp = form?.otp
        resetForm.email = userEmail
        auth.resetForgotPasswordFinal(resetForm, () => {
            toast.success("Successfully changed the password.")
            navigate('/');
            // setIsOTPVerified(true)
        });
    }

    const handlePW = pw => {
        setResetForm({...resetForm, newPassword: pw })
    }

    return (
        <PublicLayout rightContent={<img src={rightImage} height={300} />}>
            {!isOTPVerified ? <div className={styles.root}>

                <form className="user">
                    <Group label={`OTP`}>
                        <Input
                            type="text"
                            className="form-control form-control-user"
                            value={form?.otp}
                            name="otp"
                            onChange={otp => setForm({ otp })}
                            placeholder="Enter OTP here" />
                    </Group>
                    <Group>
                            <div className={styles.keepLoggedIn}>
                                <label onClick={resendOTP} className={styles.forgotPassword}>Resend OTP</label>
                            </div>
                        </Group>
                    <Group style={{ marginTop: 20 }}>
                        <Button style={{ width: 400, fontWeight: 'bold' }} contentDirection="center" view="primary" type="button" onClick={resetPassowrd}>Verify OTP</Button>
                    </Group>
                </form>

            </div> : <div className={styles.root}>
                <form className="user">
                    <Group label={`New Password`}>
                        <Input
                            type="password"
                            className="form-control form-control-user"
                            value={resetForm?.newPassword}
                            name="newpassword"
                            onChange={newPassword => handlePW(newPassword)}
                            placeholder="New password" />
                    </Group>
                    <Group label={`Confirm Password`}>
                        <Input
                            type="password"
                            className="form-control form-control-user"
                            value={resetForm?.confirmPassword}
                            name="confirm"
                            onChange={confirmPassword => setResetForm({ ...resetForm, confirmPassword })}
                            placeholder="Confirm password" />
                    </Group>
                    <Group style={{ marginTop: 20 }}>
                        <Button style={{ width: 400, fontWeight: 'bold' }} contentDirection="center" view="primary" type="button" onClick={resetPassowrdFinal}>Reset Password</Button>
                    </Group>
                </form>
            </div>
            }
        </PublicLayout>
    );

};

export default memo(VerifyOTP);