import { memo } from "react";
import styles from "./PublicLayout.module.scss";
import logo from "assets/logo_blue.png";

const PublicLayout = ({ children, rightContent = null }) => {
  return (
    <div className={styles.root}>
      <div className={styles.leftContent}>
        <img src={logo} alt="CPRO" />
        <div className={styles.mainContentWrapper}>{children}</div>
      </div>
      {/* <div className={styles.contentWrapper}>
                {rightContent}
            </div> */}
    </div>
  );
};

export default memo(PublicLayout);
