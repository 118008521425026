import { memo } from 'react';
import cx from "classnames";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { MdEventAvailable, MdFormatListBulleted, MdPeopleOutline, MdLockOutline, MdHome, MdFileUpload, MdSettings, MdReport } from 'react-icons/md';
import { AiOutlineTeam, AiOutlineNotification, AiOutlineUser, AiFillThunderbolt } from 'react-icons/ai';
import { VscSymbolEvent } from 'react-icons/vsc';
import { GoLocation } from 'react-icons/go';
import { FiLock, FiUsers } from 'react-icons/fi';
import { useSidebarToggle, useSidebarVisible } from 'hooks/sidebar';
import { useAuth } from 'components/ProvideAuth/hook';
// import { useSelector } from 'react-redux';
import * as acl from 'shared/utils/accessControl';
import { BsCalendar2EventFill } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';
import ModuleGroupItem from './ModuleGroupItem';
import _contacts from 'assets/svg/_contacts.svg';
import _locations from 'assets/svg/_locations.svg';
import _role from 'assets/svg/_role.svg';
import _events from 'assets/svg/_events.svg';
import _users from 'assets/svg/_users.svg';
import _home from 'assets/svg/_Home.svg';
import  _templates from 'assets/svg/_Templates.svg';
import  _credits from 'assets/svg/_Credits.svg';
import { useMediaQuery } from '@mui/material';

const Sidebar = props => {
    const userPermissions = [];//useSelector((state:any) => state?.userPermissions);
    // const moduleGroups = useModuleGroupState();
	const smallScreen = useMediaQuery('(max-width:500px)');
    const auth = useAuth();
    const isContact = auth.user.user_type === "contact";
    const superAdmin = auth.user.user_type === "superAdmin"
    const navigate = useNavigate();
    const location = useLocation();
    const isSidebarOpen = useSidebarVisible();
    // console.log('location.pathname->',location.pathname);
    const setIsSideBarOpen = useSidebarToggle();
    if (smallScreen)
        setIsSideBarOpen(false)

    return (
        <div style={{ width: isSidebarOpen ? 260 : 90 }} className={styles.root}>
            <ul className={styles.menu}>
                {superAdmin ?
                    <li onClick={() => navigate('/companies')} className={location.pathname.includes('/companies') ? styles.selected : ''}><MdHome size={30} className={styles.menuIcon} /> {isSidebarOpen ? "Companies" : ""}</li>
                    : (isContact ? 
                        <>
                            <li onClick={() => navigate('/home')} className={location.pathname.includes('/home') ? styles.selected : ''}><img style={{ height: 30 }} src={_home}/> {isSidebarOpen ? "Home" : ""}</li>
                            <li onClick={() => navigate('/assemblyPoints')} className={location.pathname.includes('/assemblyPoints') ? styles.selected : ''}><img style={{ height: 30 }} src={_locations} alt="Emeva" /> {isSidebarOpen ? "Assembly Points" : ""}</li>
                            <li onClick={() => navigate('/messages')} className={location.pathname.includes('/messages') ? styles.selected : ''}><img style={{ height: 30 }} src={_home}/> {isSidebarOpen ? "Messages" : ""}</li>
                        </>
                         :
                        <>
                            <li onClick={() => navigate('/home')} className={location.pathname.includes('/home') ? styles.selected : ''}><img style={{ height: 30 }} src={_home}/> {isSidebarOpen ? "Home" : ""}</li>
                            {/* <ModuleGroupItem id={moduleGroups?.find?.(g => !g?.parentId)?.id} /> */}

                            <li onClick={() => navigate('/events')} className={location.pathname.includes('/events') ? styles.selected : ''}><img style={{ height: 30 }} src={_events} alt="Emeva" /> {isSidebarOpen ? "Events" : ""}</li>
                            <li onClick={() => navigate('/locations/')} className={location.pathname.includes('/locations/') ? styles.selected : ''}><img style={{ height: 30 }} src={_locations} alt="Emeva" /> {isSidebarOpen ? "Locations" : ""}</li>
                            <li onClick={() => navigate('/admin/contacts')} className={location.pathname.includes('/admin/contacts') ? styles.selected : ''}><img style={{ height: 30 }} src={_contacts} alt="Emeva" /> {isSidebarOpen ? "Contacts" : ""}</li>
                        </>)
                }
                {/* {modules?.map?.(module => (
                    <li onClick={() => navigate(`module/${module?.name}`)} className={location.pathname.includes(`module/${module?.name}`)?styles.selected:''}><span className={cx(styles.menuIcon,"fa",module?.icon)} /> {isSidebarOpen?`${module?.name}`:""}</li>
                ))} */}
                {/* <li onClick={() => navigate('/module/user')} className={location.pathname.includes('/module/user')?styles.selected:''}><AiFillThunderbolt size={20} className={styles.menuIcon} /> {isSidebarOpen?"Module":""}</li> */}
                {/* {acl.hasPerm(acl.canViewPlaybook(),userPermissions) && (
                    <li onClick={() => navigate('/playbooks')} className={location.pathname.includes('/playbooks')?styles.selected:''}><BsListCheck size={20} className={styles.menuIcon} /> {isSidebarOpen?"Playbooks":""}</li>
                    )} */}
                {/* <li onClick={() => navigate('/bia')} className={location.pathname.includes('/bia')?styles.selected:''}><AiOutlineTeam size={20} className={styles.menuIcon} /> {isSidebarOpen?"Business Impact Analysis":""}</li>
                    <li onClick={() => navigate('/ra')} className={location.pathname.includes('/ra')?styles.selected:''}><GoLocation size={20} className={styles.menuIcon} />{isSidebarOpen?"Risk Assessment":""} </li>
                    <li onClick={() => navigate('/plans')} className={location.pathname.includes('/plans')?styles.selected:''}><IoDocumentOutline size={20} className={styles.menuIcon} />{isSidebarOpen?"Plans":""} </li>
                    <li onClick={() => navigate('/insidents')} className={location.pathname.includes('/insidents')?styles.selected:''}><IoDocumentOutline size={20} className={styles.menuIcon} />{isSidebarOpen?"Incidents":""} </li>
                    <li onClick={() => navigate('/reports')} className={location.pathname.includes('/reports')?styles.selected:''}><IoDocumentOutline size={20} className={styles.menuIcon} />{isSidebarOpen?"Reports":""} </li> */}
            </ul>
            {/* { !isContact && <ul className={styles.menu}>
                {isSidebarOpen?
                    <li className={location.pathname === '/admin'?styles.selected:''}><b>  Administration</b></li>:""
                }
                    <li onClick={() => navigate('/admin/users')} className={location.pathname === '/admin/users'?styles.selected:''}><img style={{ height: 30 }} src={_users} alt="Emeva" />{isSidebarOpen?"Users":""}   </li>
                    <li onClick={() => navigate('/admin/roles')} className={location.pathname.includes('admin/roles')?styles.selected:''}><img style={{ height: 30 }} src={_role} alt="Emeva" />{isSidebarOpen?"Roles":""}   </li>
                </ul>
            } */}
            {(!superAdmin && !isContact) && <ul className={styles.menu}>
                {isSidebarOpen ?
                    <li className={location.pathname === '/admin' ? styles.selected : ''}><b>  Administration</b></li> : ""
                }
                <li onClick={() => navigate('/admin/users')} className={location.pathname === '/admin/users' ? styles.selected : ''}><img style={{ height: 30 }} src={_users} alt="Users" />{isSidebarOpen ? "Users" : ""}   </li>
                <li onClick={() => navigate('/admin/roles')} className={location.pathname.includes('admin/roles') ? styles.selected : ''}><img style={{ height: 30 }} src={_role} alt="Roles" />{isSidebarOpen ? "Roles" : ""}   </li>
                <li onClick={() => navigate('/admin/templates')} className={location.pathname.includes('admin/templates') ? styles.selected : ''}><img style={{ height: 30 }} src={_templates} alt="Templates" />{isSidebarOpen ? "Templates" : ""}   </li>
                <li onClick={() => navigate(`/company/edit/${auth?.user?.uuid}/credits`)} className={location.pathname.includes('/company/edit') ? styles.selected : ''}><img style={{ height: 30 }} src={_credits} alt="Emeva" />{isSidebarOpen ? "Settings" : ""}   </li>
            </ul>}
            {/* {acl.hasPerm(acl.isAdmin(),userPermissions) && (
                <ul className={styles.menu}>
                {isSidebarOpen?
                    <li className={location.pathname === '/administration'?styles.selected:''}><b>  Administration</b></li>:""
                }
                    {acl.hasPerm(acl.canViewUsers(),userPermissions) && (
                        <li onClick={() => navigate('/admin/users')} className={location.pathname.includes('/admin/users')?styles.selected:''}><AiOutlineUser size={30} className={styles.menuIcon} />{isSidebarOpen?"Users":""} </li>
                    )}
                    {acl.hasPerm(acl.canViewRoles(),userPermissions) && (
                        <li onClick={() => navigate('/admin/roles')} className={location.pathname.includes('/admin/roles')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Roles":""}   </li>
                    )}
                    <li onClick={() => navigate('/admin/company/settings')} className={location.pathname.includes('/admin/company/settings')?styles.selected:''}><MdSettings size={30} className={styles.menuIcon} />{isSidebarOpen?"Settings":""}   </li>
                    <li onClick={() => navigate('/admin/credits')} className={location.pathname === '/admin/credits'?styles.selected:''}><FiCreditCard size={30} className={styles.menuIcon} />{isSidebarOpen?"Credits":""} </li>
                </ul>
            )} */}
            {/* {auth?.user?.isSuperAdmin && (
                <ul className={styles.menu}>
                    {isSidebarOpen?
                <li className={location.pathname === '/superadmin'?styles.selected:''}><b>Super Admin</b></li>:""}
                <li onClick={() => navigate('/superadmin/companies')} className={location.pathname.includes('/superadmin/companies')?styles.selected:''}><AiOutlineUser size={30} className={styles.menuIcon} /> {isSidebarOpen?"Companies":""}</li>
                <li onClick={() => navigate('/superadmin/leads')} className={location.pathname.includes('/superadmin/leads')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Leads":""} </li>
                <li onClick={() => navigate('/superadmin/users')} className={location.pathname.includes('/superadmin/users')?styles.selected:''}><MdLockOutline size={30} className={styles.menuIcon} />{isSidebarOpen?"Users":""} </li>
            </ul>
            )} */}
        </div>
    );

};

export default memo(Sidebar);