import { memo, useEffect, useReducer, useState } from "react";
import { Country, State, City } from "country-state-city";
import styles from "./EditEvent.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "components/BreadCrumbs";
import Select from "components/Form/Select";
import moment from "moment";
import { toast } from "react-toastify";
import Input from "components/Form/Input";
import Group from "components/Group";
import Button from "components/Button";
import Heading from "components/Heading";
import Switch from "components/Switch";
// import faker from 'faker';
// import { saveItem,getItemById } from 'shared/api/location';
import basicReducer from "reducers/basicReducer";
// import { copyAttr } from 'shared/utils/common';
import Validator from "shared/utils/form-validator";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import Radio from "components/Form/Radio";
import Textarea from "components/Form/Textarea";
import { FaTrash } from "react-icons/fa";
import { Grid, Tooltip } from "@mui/material";
import { FiInfo } from "react-icons/fi";
import LocationContactsModal from "./LocationContactsModal";
import Checkbox from "components/Form/Checkbox";
let validator = new Validator({
  name: {
    required: true,
    message: "Fields are missing",
  },
});

let usStates = State.getStatesOfCountry("US");
let usstatesOptions = usStates?.map((s) => ({
  value: s?.isoCode,
  label: s?.name,
}));

enum EventTypes {
  Drill = "Drill",
  RealEvent = "Event",
}

const EditGroup = (props) => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [templatesList, setTemplatesList] = useState([
    { value: "none", label: "Choose template" },
  ]);
  const [selectedTemplate, setSelectedTemplate] = useState("none");
  const [isModalOpen, handleModal] = useState(false);
  const [chooseTemplate, setChooseTemplate] = useState(false);
  const [contactsListData, setContactListData] = useState({
    items: [],
    meta: { totalItems: 0 },
    links: {},
  });

  let [state, setState] = useReducer(basicReducer, {
    errors: {},
    submitInProgress: false,
    states: usstatesOptions,
    locations: [],
  });
  const [form, setForm] = useReducer(basicReducer, {
    id: "",
    name: "",
    is_drill: true,
    event_type: "General",
    labels: [
      { label: `I'm Safe`, color: "#008000" },
      { label: `Not Safe`, color: "#ff0000" },
      { label: `Away from danger zone`, color: "#000000" },
    ],
    locations_ids: [],
    trigger_now: true,
  });

  const isValid = () => {
    let [isValid, errors] = validator.isValid(form);

    setState({
      errors,
    });
    return { isValid, errors };
  };

  const saveOrCreate = () => {
    const isValidResponse = isValid();
    // console.log('isValidResponse-> ',isValidResponse);
    if (!isValidResponse.isValid) {
      setState({ submitInProgress: false });

      for (let key of Object.keys(isValidResponse.errors)) {
        toast.error(isValidResponse.errors[key]);
        break;
      }
      return;
    }

    if (form?.locations_ids.length === 0) {
      toast.error("Locations can not be empty");
      return;
    }

    const labelWithColor = form.labels;
    let _toSave = {
      ...form,
      companyId: auth?.company?.id,
      labels: labelWithColor.map((item) => item.label),
      colors: labelWithColor.map((item) => item.color),
    };

    if (typeof _toSave.id !== "undefined" && !_toSave?.id) {
      delete _toSave?.id;
    }

    let saveItem = !_toSave?.id
      ? api.events.createEvent
      : api.events.updateEvent;

    saveItem(_toSave)
      .then((saved) => {
        // if(saved && saved?.payload?.data?.id){
        if (_toSave?.id) {
          toast.success(`Changes saved`);
          navigate(`/events/`);
        } else {
          navigate(`/events/`);
          // navigate(`/locations/edit/${saved?.payload?.data?.id}`)
          toast.success(`Event successfully created`);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.data.message);
      });
  };

  useEffect(() => {
    api.locations
      .listLocations({ companyId: auth?.company?.id, getAll: true })
      .then?.((locations) => {
        // console.log('locations ->',locations);
        if (locations?.length > 0) {
          let locationsToUpdate = locations?.map?.((location) => ({
            value: location?.id,
            label: location?.title,
          }));

          locationsToUpdate.sort((a, b) => (a.label > b.label ? 1 : -1));
          locationsToUpdate.unshift({ value: "all", label: "All" });
          setState({ locations: locationsToUpdate });
        }
      });
  }, []);

  useEffect(() => {
    if (form?.locations_ids && state?.locations?.length) {
      const checkLocation =
        form?.locations_ids?.length === state?.locations?.length - 1;
      api.contacts
        .listContacts({
          companyId: auth?.company?.id,
          location: checkLocation ? "all" : form?.locations_ids.join(","),
        })
        .then?.((contacts) => {
          setContactListData({
            ...contacts,
          });
        });
    }
  }, [form?.locations_ids]);

  useEffect(() => {
    api.templates
      .listTemplates({ companyId: auth?.company?.id, fetch_all: true })
      .then?.((templates) => {
        if (templates?.length > 0) {
          let templatesToUpdate = templates?.map?.((temp) => ({
            value: temp?.id,
            label: temp?.name,
          }));
          templatesToUpdate.unshift({
            value: "none",
            label: "Choose template",
          });
          setTemplatesList(templatesToUpdate);
        }
      });
  }, []);

  useEffect(() => {
    if (params?.id) {
      api.events
        .getEvent({ id: params?.id, companyId: auth?.company?.id })
        .then((eventDetails) => {
          if (eventDetails) {
            let locations_ids = [];
            if (
              eventDetails?.eventToLocations &&
              Array.isArray(eventDetails?.eventToLocations)
            ) {
              locations_ids = eventDetails?.eventToLocations?.map?.(
                (i) => i?.location?.id
              );
            }

            if (eventDetails?.event_datetime) {
              const ev_datetime = moment(eventDetails.event_datetime)
                .subtract(4, "hours")
                .toISOString();
              eventDetails.event_datetime = ev_datetime.replace("Z", "");
            }

            setForm({ ...eventDetails, locations_ids });
          }
        });
    }
  }, [params?.id]);

  const handleDateForm = (date) => {
    setForm({ event_datetime: date });
  };

  const addNewPollOption = () => {
    setForm({
      labels: [
        ...form?.labels,
        {
          label: "",
          color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        },
      ],
    });
  };

  const removePollOption = (index) => {
    let labels = [...form?.labels];
    labels?.splice?.(index, 1);
    setForm({ labels: [...labels] });
  };

  const updatePoll = (poll, index, type) => {
    let labels = [...form?.labels];

    let restrictedColor = ["#ffffff"];
    if (type === "color") {
      if (restrictedColor.includes(poll)) {
        toast.error("White color is not allowed!");
        return;
      }
    }

    labels[index][type] = poll;
    setForm({ labels: [...labels] });
  };

  const setLocations = (v: Array<{ value; label }>) => {
    let hasAll = v?.find?.((l) => l?.value === "all");
    if (hasAll) {
      setForm({
        locations_ids: state?.locations
          ?.filter?.((l) => l?.value !== "all")
          ?.map?.((i) => i?.value),
      });
    } else {
      setForm({ locations_ids: v?.map?.((i) => i?.value) });
    }
  };

  const getTemplateDetails = (templateId) => {
    setSelectedTemplate(templateId);
    if (templateId !== "none") {
      api.templates
        .getEventTemplate({ id: templateId, companyId: auth?.company?.id })
        .then((eventDetails) => {
          if (eventDetails) {
            let locations_ids = [];
            if (
              eventDetails?.eventToLocations &&
              Array.isArray(eventDetails?.eventToLocations)
            ) {
              locations_ids = eventDetails?.eventToLocations?.map?.(
                (i) => i?.location?.id
              );
            }

            const labels = eventDetails?.options?.map(
              (option) => { return { label: option?.title, color: option?.color ?? "#" + Math.floor(Math.random() * 16777215).toString(16) } }
            );
            setForm({ ...eventDetails, locations_ids, labels });
          }
        });
    } else {
      setForm({
        id: "",
        name: "",
        message: "",
        is_drill: true,
        event_type: "General",
        labels: [
          { label: `I'm Safe`, color: "#ffffff" },
          { label: `Not Safe`, color: "#ff0000" },
          { label: `Away from danger zone`, color: "#000000" },
        ],
        locations_ids: [],
        trigger_now: true,
        save_as_template: false,
      });
    }
  };

  const EventTypeLabel = () => {
    return (
      <>
        Event Type
        <Tooltip
          title="Choose the type of event. Select Drill if conducting a drill. Select Event if an actual incident."
          placement="top"
        >
          <span style={{ marginLeft: "5px" }}>
            <FiInfo size={15}></FiInfo>
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <Breadcrumb
        list={[
          {
            name: "Events",
            url: "events",
          },
          {
            name: `${params?.id ? "Edit" : "Add"} Event`,
          },
        ]}
      />
      <Heading>{params?.id ? "Edit" : "Add"} Event</Heading>

      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
        <Grid item md={6}>
          <div>
            <Group
              label="Would you like to use a template?"
              className={styles.checkBoxStyle}
            >
              <div style={{ marginTop: "3px" }}>
                <Checkbox
                  checked={chooseTemplate}
                  onChange={() => setChooseTemplate(!chooseTemplate)}
                />
              </div>
            </Group>
            {chooseTemplate && (
              <Select
                isMultiple={false}
                value={selectedTemplate}
                options={templatesList}
                onChange={({ value }) => getTemplateDetails(value)}
              />
            )}
            <Group label={<EventTypeLabel />}>
              <Select
                isMultiple={false}
                value={
                  form?.is_drill === true
                    ? EventTypes.Drill
                    : EventTypes.RealEvent
                }
                options={[EventTypes.Drill, EventTypes.RealEvent]}
                onChange={(update) => setForm({ is_drill: !form?.is_drill })}
              />
            </Group>
            <Group label="Message Type">
              <div>
                <Radio
                  value="General"
                  label="General (No response required from contacts)"
                  checked={form?.event_type === "General"}
                  onChange={(update) => setForm({ event_type: update })}
                />
              </div>
              <div>
                <Radio
                  value="Poll"
                  label="Poll (Contacts will respond with a choice)"
                  checked={form?.event_type === "Poll"}
                  onChange={(update) => setForm({ event_type: update })}
                />
              </div>
            </Group>
          </div>
          <div>
            <Group label="Message Title">
              <Input
                value={form?.name || ""}
                onChange={(name) => setForm({ name })}
              />
            </Group>
            <Group label="Message Description" vertical={false}>
              <Textarea
                value={form?.message}
                onChange={(message) => setForm({ message })}
              />
            </Group>
            {form?.event_type === "Poll" && (
              <Group label="Poll Options">
                <div style={{ backgroundColor: "#efefef", padding: 10 }}>
                  {form?.labels?.map?.((poll, index) => (
                    <div key={index} className={styles.eachPollOption}>
                      <Input
                        value={poll.label || ""}
                        onChange={(updatedPoll) =>
                          updatePoll(updatedPoll, index, "label")
                        }
                        name="poll"
                      />
                      <Input
                        value={poll.color || ""}
                        onChange={(updatedPoll) =>
                          updatePoll(updatedPoll, index, "color")
                        }
                        name="color"
                        type="color"
                        style={{
                          width: "20%",
                        }}
                      />

                      <Button
                        onClick={() => removePollOption(index)}
                        view="transparent"
                      >
                        <FaTrash />
                      </Button>
                    </div>
                  ))}
                  <Button onClick={addNewPollOption}>Add New Option</Button>
                </div>
              </Group>
            )}
            <Group label="Locations to Alert">
              <Select
                isMultiple={true}
                value={form?.locations_ids}
                options={state?.locations}
                onChange={setLocations}
              />
              {form?.locations_ids.length > 0 && (
                <label
                  style={{
                    display: "block",
                    float: "right",
                    fontSize: "13px",
                    paddingTop: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleModal(!isModalOpen)}
                >
                  Total Contact - {contactsListData?.meta?.totalItems} Preview
                  Contacts
                </label>
              )}
            </Group>
            <Group label="When do you want to send this alert?">
              <div>
                <Radio
                  value="Now"
                  checked={form?.trigger_now === true}
                  onChange={(update) => setForm({ trigger_now: true })}
                />
              </div>
              <div>
                <Radio
                  value="Schedule"
                  checked={form?.trigger_now === false}
                  onChange={(update) => setForm({ trigger_now: false })}
                />
              </div>
            </Group>

            {form?.trigger_now === false && (
              <Group label="Schedule Date/Time">
                <Input
                  type="datetime-local"
                  value={form?.event_datetime || ""}
                  onChange={(date) => handleDateForm(date)}
                />
              </Group>
            )}

            <Group label="Save as template">
              <Switch
                checked={form?.save_as_template}
                onChange={(save_as_template) => setForm({ save_as_template })}
              />
            </Group>
          </div>

          <Group>
            <Button onClick={() => navigate("/events")} view="cancel">
              Cancel
            </Button>
            <Button onClick={saveOrCreate} style={{ marginLeft: 10 }}>
              {form?.trigger_now ? "Send" : "Schedule"}
            </Button>
          </Group>
        </Grid>
        <Grid item md={6}></Grid>
      </Grid>
      {isModalOpen && (
        <LocationContactsModal
          onClose={() => handleModal(!isModalOpen)}
          isOpen={isModalOpen}
          locationIds={form?.locations_ids}
          allSelected={form?.locations_ids.length === state?.locations.length - 1}
        />
      )}
    </div>
  );
};

export default memo(EditGroup);
