import React, { useEffect, useState } from 'react';
import styles from './ImportLocation.module.scss';
import api from 'api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'components/ProvideAuth/hook';
import Breadcrumb from 'components/BreadCrumbs';
import Button from 'components/Button';
import { toast } from 'react-toastify';


const ImportLocation = () => {

    const auth = useAuth();
    const [ignoreHeader, setIgnoreHeader] = useState<boolean>(true);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [formData , setFormData] = useState<any>()

    const CreateLocation = (_toSave) => {
        setLoading(true)
        api.locations.importLocations( auth.company.id , _toSave).then((out) => {
            toast.success("Locations updated: " + out.updated + ". Locations created: " + out.created)
            navigate('/locations');
        }).catch((error)=>{
            toast.error("Error while importing")
            console.error ("catch " , error)
        }).finally(()=> {
            setLoading(false);
        });
    }

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files) {
            console.error("No file uploaded");
            return;
        }
        setFileName(event.target.files[0].name);
        const file = event.target.files[0]
        
        const formDatum= {
            file ,
            isFileUpload: true,
            ignoreHeader: ignoreHeader,
        }
        setFormData(formDatum)
        
    };

    const uploadFile = () => {
        if (formData){
            CreateLocation(formData);
        }
    };

    useEffect(()=> {

        if (formData)
        {
            const formDatum= {
                ...formData,
                ignoreHeader: ignoreHeader
            }
            setFormData(formDatum)
        }
        
    },[ignoreHeader])

    return (
        <div className={styles.root}>
            <Breadcrumb list={[
                {
                    name: 'Locations',
                    url: '/locations'
                },
                {
                    name:`Import Locations`
                },
            ]} />
            <div className={styles.heading}>Import Location</div>
            <div>Select a CSV file to begin importing contacts</div>
            <div>Please make sure the order of the columns matches the following:</div>
            <div>Title, Address, City, State, Zip, Primary Assembly Point, Secondary Assembly Point</div>
            <div className={styles['file-picker']}>
                <label htmlFor="file-input" className={styles['file-picker-label']}>
                    <div className={styles['file-picker-placeholder']}>Click or drag file here to upload</div>
                    <div style={{ alignItems : "center" , display : "flex" , justifyContent : "space-around"}}>
                        <label htmlFor="file-input" style={{ backgroundColor: " #007bff", color: "white", padding: "10px 20px", borderRadius: "10px", cursor: "pointer", fontSize: "18px" }}>
                            Choose File
                            <input
                                id="file-input"
                                type="file"
                                onChange={handleFileInputChange}
                                accept=".csv"
                                style={{ display: "none" }}
                            />
                        </label>
                    </div>
                    {fileName ? <p>{fileName}</p> : null}
                </label>
            </div>
            <div style={{display : "flex" , width : "40%" , justifyContent  :"space-between"}}>
                <label style={{display: 'flex', alignItems: 'center',}}>
                    <input type="checkbox" defaultChecked={ignoreHeader} onChange={() => setIgnoreHeader(!ignoreHeader)} style={{marginRight: '5px'}} />
                    <span style={{fontSize: '16px'}}>Ignore Headers</span>
                </label>
            </div>
            <Button  disabled={isLoading} className={styles['file-picker-button']} onClick={uploadFile} style={{width : "150px"}}>Import Locations</Button>            
        </div>
    );
};

export default ImportLocation;
