import Button from 'components/Button';
import { useEffect, useState } from 'react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './PaymentPlan.module.scss';
import linkStyle from '../Login/Login.module.scss';
import RightContent from './RightContent';
import { Box, Container } from '@mui/material';
import storage from 'shared/utils/storage';
import Modal from 'components/Modal';
import Stripe from '../Stripe/Stripe';
import { useAuth } from 'components/ProvideAuth/hook';
import api from 'api';
import Group from 'components/Group';

const PaymentPlan = ({login=false}) => {
    const navigate = useNavigate()
    const auth = useAuth()
	const [selectedCard, setSelectedCard] = useState('trial');
    const [price , setPrice]  = useState(1500)
    const [credits ,  setCredits] = useState(0)
    const [openModal , setOpenModal] =  useState(false)
	const handleCardSelection = (card) => {
		setSelectedCard(card);
	};

	const handlePaymentPlanSelection = () => {
		if (selectedCard === 'trial'){
            toast.success("Trial Period is selected")
            if (!login){
                api.payment.paymentSuccess({payment_intent :"", payment_intent_client_secret: "trial"});
                storage.remove("token");
                storage.remove("user");
                storage.remove("company");
                storage.remove("profile");
                storage.remove("companyId");
                navigate('/login')
            }
            else{
                api.payment.paymentSuccess({payment_intent :"", payment_intent_client_secret: "trial"});
                navigate('/')
            }
        }
        else{
            setOpenModal(true)
        }
	};

    useEffect(() => {
        if (!login){
            const unloadHandler = () => {
                auth.signout(()=> {
                    navigate('/login')
                })
                console.log('Component unloaded');
            };
        
            window.addEventListener('beforeunload', unloadHandler);
        
            return () => {
            // Cleanup function
            auth.signout(()=> {
                navigate('/login')
                })
            window.removeEventListener('beforeunload', unloadHandler);
            };
        }
      }, []);

	return ( <>
            <Container maxWidth="lg">
            <div className={styles.root}>
            <h1 className={styles.mainHead}>Select Plan</h1>
                <Box className={styles.cardsBox} sx={{ 
                  display: "flex",
                  justifyContent: "center",
                   alignItems: "center",
                   flexDirection:{xs:'column',md:'row'}}}>
                    <Box
                        sx={{width: {xs:"90%",md:"50%",lg:"30%"},
                        margin:{xs:"6%",md:"3%",lg:"1%"}}}
                        className={`${styles.card}${
                            selectedCard === 'trial' ? ` ${styles.selected}` : ''
                        }`}
                        onClick={() => handleCardSelection('trial')}
                    >
                        <div className={styles.priceCard}>
                        <div className={styles.priceInfo}>
                          <p className={styles.priceHeading}>Trial</p>
                          <p className={styles.price}>$0</p>
                          <p className={styles.paymentType}>Free</p>
                        </div>
                        </div>

                        <div className={styles.planDetails}>
                        <p style={{margin:"0px"}}>1. Get 500 connects.</p>
                        <p style={{margin:"5px 0px 0px 0px"}}>2. 7 day trial period.</p>
                        </div>
                        <div className={styles.priceCard}>
                        <Button 
                         style={{marginTop:"45px",boxShadow:" 0px 0px 20px 0px rgba(0, 0, 0, 0.15)",display:'grid',
                           placeItems:"center",width:"30%",
                           color:selectedCard === 'trial' ? '#0B52C8':'#fff',
                           backgroundColor:selectedCard === 'trial' ? '#fff' : '#0B52C8'
                           }}>
                            Choose
                            </Button>
                        </div>
                    </Box>
                    <Box
                        sx={{width: {xs:"90%",md:"50%",lg:"30%"}, 
                        margin:{xs:"6%",md:"3%",lg:"1%"}}}
                        className={`${styles.card}${
                            selectedCard === 'premium' ? ` ${styles.selected}` : ''
                        }`}
                        onClick={() => handleCardSelection('premium')}
                    >
                        <div className={styles.priceCard}>
                        <div className={styles.priceInfo}>
                          <p className={styles.priceHeading}>Premium</p>
                          <p className={styles.price}>${price}</p>
                          <p className={styles.paymentType}>Pro / Annually</p>
                        </div>
                        </div>
                        <div className={styles.planDetails}>
                        <p style={{margin:"0px"}}>1. Pay through stripe.</p>
                        <p style={{margin:"5px 0px 0px 0px",visibility:'hidden'}}>2. 7 day trail period.</p>
                        </div>
                        <div className={styles.priceCard}>
                        <Button
                         style={{marginTop:"45px",boxShadow:" 0px 0px 20px 0px rgba(0, 0, 0, 0.15)",display:'grid',
                           placeItems:"center",width:"30%",
                           color:selectedCard === 'premium' ? '#0B52C8':'#fff',
                           backgroundColor:selectedCard === 'premium' ? '#fff' : '#0B52C8'
                           }}>
                            Choose
                           </Button>
                           </div>
                </Box>
            </Box>
        </div>
        {openModal && <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
            <h3>Add Card details</h3>
            <Stripe prices={price} credits={credits} login={login}/>
            <Group>
            <Box sx={{fontSize:'12px'}}>
                By clicking “Start Subscription”, you agree to Emeva’s
                <span
                className={linkStyle.termsPolicy}
                onClick={() =>  window.open("https://continuityinnovations.com/terms-of-use-emeva" , "_blank")}
                > 
                   <strong>Terms of Service  </strong>
                </span>,             
                    <span className={linkStyle.termsPolicy} onClick={() => window.open("https://continuityinnovations.com/privacy-policy" , "_blank")}  >
                        <strong>Privacy Policy</strong>
                    </span> and that <strong>
                    Emeva automatically continue your subscription and charge the annual fee (currently {price}) to your payment method until you cancel. You may cancel at any time to avoid future charges.
                    </strong> To cancel email support at <a href={"mailto:support@emeva.io"}>support@emeva.io</a> or go to your Dashboard and click “Cancel”.
            </Box>
         </Group>
        </Modal>}
     <RightContent setPrice={setPrice} handlePaymentPlanSelection={handlePaymentPlanSelection} selectedCard={selectedCard === 'trial'} setCredits={setCredits} login={login}/>
    </Container>
    </> 
	);
};

export default memo(PaymentPlan);
