import {memo} from 'react';
import styles from './Card.module.scss';

const Card = ({style = {},children}) => {

    return (
        <div style={style} className={styles.root}>
            {children}
        </div>
    );

};

export default memo(Card);