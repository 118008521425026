import { memo, useReducer, useCallback, useState } from 'react';
import styles from './Locations.module.scss';
import Table from 'components/Table';
import Heading from 'components/Heading';
import Button from 'components/Button';
import TopHeader from 'components/TopHeader';
import TopActionBar from 'components/TopActionBar';
import Actions from './Actions';
import SearchBar from 'components/SearchBar';
// import faker from 'faker';
import Breadcrumb from 'components/BreadCrumbs';
import { useNavigate } from 'react-router-dom';

import basicReducer from 'reducers/basicReducer';
// import { fetchItems, deleteItem } from 'shared/api/location';
import * as acl from 'shared/utils/accessControl';
import ImportCsvModal from './ImportCsvModal';
import api from 'api';
import { useAuth } from 'components/ProvideAuth/hook';
import { toast } from 'react-toastify';

// const data = [];
// for(let i=0;i < 10;i++){
//     data.push({
//         name: faker.commerce.department(),
//         city: 'San Francisco',
//         state: 'CA',
//         country: 'USA',
//         actions: <Actions />,
//         modifiedBy: 'Andrew K',
//         modifiedOn: '12/15/2020',
//     })
// }
var tempSearchValue = '';
const columns = [
	// {
	//     Header: 'Item #',
	//     accessor: 'col1', // accessor is the "key" in the data
	// },
	{
		Header: 'Location Name',
		accessor: 'title',
	},
	{
		Header: 'Address',
		accessor: 'address',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'State',
		accessor: 'state',
	},
	{
		Header: 'Zip',
		accessor: 'zip',
	},
	// {
	// 	Header: 'Country',
	// 	accessor: 'country',
	// },
	{
		Header: 'Modified By',
		accessor: 'modifiedBy',
	},
	{
		Header: 'Modified On',
		accessor: 'modifiedOn',
		type: 'date'
	},
	{
		Header: 'Actions',
		accessor: 'actions',
		disableSortBy: true,
		disableFilters: true,
		hideFilter: true,
		width: 120
	},
];


const Locations = props => {
	const auth = useAuth();
	const userPermissions = [];//useSelector((state:any) => state?.userPermissions);

	const navigate = useNavigate();
	const [state, setState] = useReducer(basicReducer, {
		list: [],
		pageSize: 10,
		showImportModal: false
	});
	const [pageCount, setPageCount] = useState(0)

	const onDelete = id => {
		const form = {
			companyId: auth.company.id,
			id
		}
		api.locations.deletLocation(form).then(() => {
			toast.success("Successfully deleted");
			loadList({ action: 'loadList' });
		}).catch((err) => {
			toast.error(err)
		})
	}

	const loadList = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
		let filter = { search: value ?? tempSearchValue };
		if (action === 'search') {
			tempSearchValue = value;
			// filter = Object.assign({}, { search: value });
		}
		filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
		api.locations.listLocations({ ...filter, companyId: auth?.company?.id }).then(list => {
			let formattedData = [];
			if (list?.items?.length > 0) {
				formattedData = list?.items?.map(item => ({
					// title: <span className="link" onClick={() => navigate(`edit/${item?.id}`)}>{item?.title}</span>,
					title: item?.title,
					city: item?.city,
					state: item?.state,
					// country: item?.country,
					zip: item?.zip,
					address: item?.address,
					actions: <Actions id={item?.id} onDelete={onDelete} />,
					modifiedBy: item?.updated_by?.first_name ? `${item?.updated_by?.first_name} ${item?.updated_by?.last_name}` : item?.created_by?.first_name ? `${item?.created_by?.first_name} ${item?.created_by?.last_name}` : "",
					modifiedOn: item?.updated_at,
					id: item?.id
				}));
				setPageCount(Math.ceil(list.meta?.totalItems / pageSize));
				setState({ totalCount: list.meta?.totalItems })
			}
			setState({ list: formattedData })
		});
	}

	const fetchData = useCallback(({ pageSize, pageIndex }) => {
		loadList({ action: 'loadList', pageSize, pageIndex: pageIndex + 1 });
	}, []);

	let updatePageSize = size => {
		setState({ pageSize: size });
	}

	const onLocationsImported = () => {
		setState({ showImportModal: false });
		loadList({ action: 'loadList' });
	}

	return (
		<div className={styles.root}>
			<Breadcrumb list={[{
				name: 'Locations'
			}]} />
			<TopHeader>
				<Heading>Locations</Heading>
				<SearchBar onSearch={loadList} pageSize={state.pageSize} />
			</TopHeader>
			{acl.hasPerm(acl.canCreateLocations(), userPermissions) && (
				<TopActionBar>
					<div className={styles.buttons}>
						<Button onClick={() => navigate('add')}>Add Location</Button>
						<Button onClick={() => navigate('import')}>Import Locations</Button>
					</div>
					{/* {acl.hasPerm(acl.isAdmin(),userPermissions) && (
						<Button style={{marginLeft:10}} onClick={() => setState({showImportModal:true})}>Import</Button>
					)} */}
				</TopActionBar>
			)}
			<Table columns={columns} data={state?.list} pageCount={pageCount} fetchData={fetchData} parentPageSize={updatePageSize} totalCount={state?.totalCount} />
			{state?.showImportModal && (
				<ImportCsvModal onClose={onLocationsImported} />
			)}
		</div>
	);

};

export default memo(Locations);