import { memo } from "react";
// import SocketBase from 'components/SocketBase';
import styles from "./AppLayout.module.scss";
import Header from "layout/AppLayout/Header";
import Sidebar from "layout/AppLayout/Sidebar";
import TermPolicy from "views/auth/TermPolicy";
import Group from "components/Group";

const AppLayout = ({ children }) => {
  return (
    <div className={styles.root}>
      {/* <SocketBase> */}
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <div className={styles.mainContentWrapper}>
          <div id="rootInnerContent" className={styles.innerContent}>
            {children}
          </div>
          <Group className={styles.privacyMain}>
            <div>
              <span
                className={styles.termsPolicy}
                onClick={() =>
                  window.open(
                    "https://continuityinnovations.com/terms-of-use-emeva",
                    "_blank"
                  )
                }
              >
                Terms of Use
              </span>
              &nbsp;and&nbsp;
              <span
                className={styles.termsPolicy}
                onClick={() =>
                  window.open(
                    "https://continuityinnovations.com/privacy-policy",
                    "_blank"
                  )
                }
              >
                Privacy Policy
              </span>
            </div>
          </Group>
        </div>
      </div>

      {/* </SocketBase> */}
    </div>
  );
};

export default memo(AppLayout);
