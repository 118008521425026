import { memo } from 'react';
import styles from './Dropdown.module.scss';
import cx from 'classnames';

const DropdownItem = ({ children, onClick = null, childCustomStyling = '',style = {} }) => {
    const handleClick = (e) => {
        // console.log('handle click');
        e.preventDefault();
        // e.stopPropagation();
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <div onClick={handleClick} style={style} className={cx(styles.ddItem, childCustomStyling)}>
            <div style={{ pointerEvents: 'none' }}>{children}</div>
        </div>
    );

};

export default memo(DropdownItem);