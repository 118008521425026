import { memo, useEffect, useState } from "react";
// import styles from './ImportCsvModal.module.scss';
import Modal from "components/Modal";
import Button from "components/Button";
import Table from "components/Table";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import Group from "components/Group";
import Textarea from "components/Form/Textarea";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllClearModal = ({ onClose, isOpen, eventId }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [allClearMsg, setAllClearMessage] = useState("");

  const sendAllClear = () => {
    api.events
      .updateAllClearStatus({
        companyId: auth?.company?.id,
        eventId,
        payload: { message: allClearMsg },
      })
      .then((status) => {
        onClose();
        navigate('/events')
        toast.success("Event clear notification sent successfully");
      });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div>
        <h2>Send All Clear Message</h2>
      </div>
      <div>
        <Group label="Message" vertical={false}>
          <Textarea
            value={allClearMsg}
            onChange={(message) => setAllClearMessage(message)}
          />
        </Group>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
      >
        <Button view="secondary" style={{ marginLeft: 0 }} disabled={!allClearMsg} onClick={() => sendAllClear()}>
          Send
        </Button>
      </div>
    </Modal>
  );
};

export default memo(AllClearModal);
