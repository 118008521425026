import { useAuth } from "components/ProvideAuth/hook";
import images from "assets/svg/Happy Sun-bro.svg";
import styles from "./Home.module.scss";
import { useEffect, useState } from "react";
import api from "api";
import Card from "components/Card";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const isAwaitingLike = (status: string) => {
    return [
      "awaiting response",
      "awaiting",
      "waiting for response",
      "response awaiting",
    ].includes(status.toLowerCase());
}

const isSafeLike = (status: string) => {
    return ["safe", "secure", "i'm safe", "im safe"].includes(
        status.toLowerCase().trim()
    );
}
  
function isDangerLike(status: string) {
    return ["not safe", "in danger", "need help", "i need help"].includes(
      status.toLowerCase().trim()
    );
}

function getEventOptionColor(optionTitle: string, index = 0): string {
    const chartColors = [
        "#ad343e",
        "#A020F0",
        "#3C3C3C",
        "#232c33",
        "#eec643",
        "#f2af29",
    ]
    if ("Away from danger zone" === optionTitle)
        return "#3C3C3C"
    if (isAwaitingLike(optionTitle)) {
      return "rgb(65, 105, 225)";
    }
  
    return isSafeLike(optionTitle)
      ? "#50C878"
      : isDangerLike(optionTitle)
      ? "#FF5733"
      : chartColors[index];
}

const ContactHome = () => {
    const user = useAuth().user;
    const [result, setResult] = useState([])

    const callApi = ( event, id) => {
        if (result)
            api.events.updateEventContactStatus({companyId:user.company_id ,eventId: event?.id , contactId : user.uuid, payload: {optionId : id} }).then((res)=> {
                api.events.getOpenEvents({ companyId: user.company_id , id: user.uuid}).then((res)=>{
                    setResult(res)
                })
            }).catch((err) => {
                toast.error(err)
            })
    }

    useEffect (()=> {
        api.events.getOpenEvents({ companyId: user.company_id , id: user.uuid}).then((res)=>{
            setResult(res)
        }).catch((err)=> {
            toast.error("error" , err)
        })
    } , [])
    
    return (
        <div className={styles.container}>
            <div className={styles.mainHeading} >Welcome {user.first_name} {user.last_name}</div>
            {result.length === 0 && 
            <>
                <div className={styles.subHeading} >Good News! No active events</div>
                <div className={styles.imageContainer}>
                    <img src={images} alt="Happy Sun-bro" className={styles.image} />
                </div>
            </>
            }
            <div>
            { 
                result?.map( (event,index) => {
                const eventToContacts = event?.eventToContacts?.filter((val)=>{
                    if (val?.user?.uuid === user.uuid)
                        return true
                    else false
                })
                return (
                    <Card key={index} style={{marginTop : "40px" , width : "500px" , gap : "10px" , display : "flex" , flexDirection: "column"}}>
                            <div>
                                You have an event {event?.name}
                            </div>
                            <div className={styles.message}>
                                Message:  {event?.message}
                            </div>
                            <div style={{display: "flex" , alignItems: "center" , gap : "10px"}}>
                                Current status is 
                                <div className={styles.chip} style={{backgroundColor: eventToContacts?.[0]?.status?.title ? getEventOptionColor( eventToContacts?.[0]?.status?.title) : "blue"}}>
                                { eventToContacts?.[0]?.status?.title ? eventToContacts?.[0]?.status?.title : "Awaiting"}
                                </div>
                            </div>
                            <div>
                                Please respond by clicking following status
                            </div>
                            <div className={styles.chipBox}>
                                {
                                event.options?.map ((value , index) => {
                                    return (
                                        <div key={index} className={styles.chip} style={{backgroundColor : getEventOptionColor( value.title, index)}} onClick={() => callApi(event, value.id)}>
                                            {value.title}
                                        </div>
                                    )
                                })
                                }
                            </div>
                    </Card>
                )
                })  
            }
            </div>
        </div>
    );
};

export default ContactHome;
