import internalApi from "shared/utils/internalApi"
import { UpdatePermission } from "types";

export const listRoles = ({limit = 10, page = 1 ,companyId , search=""}) => {
    const query = `limit=${limit}&page=${page}&search=${search}`;
    return internalApi.get({ server: `company/`, path: `${companyId}/roles/?${query}` });
}

export const getRolePermission = ({companyId,roleId}) => {
    return internalApi.get({ server: `company/`, path: `${companyId}/roles/${roleId}` });
}

export const deleteRole = ({companyId,roleId}) => {
    return internalApi.delete({ server: `company/`, path: `${companyId}/roles/${roleId}` });
}

export const createRole = ({companyId,title,description,active,permissions}:{companyId:number;title:string;description:string;active:boolean;permissions:UpdatePermission[]}) => {
    return internalApi.post({ server: `company/`, path: `${companyId}/roles/`},{title,description,active,permissions});
}

export const updateRole = ({id,companyId,title,description,active,permissions}:{id:string;companyId:number;title:string;description:string;active:boolean;permissions:UpdatePermission[]}) => {
    return internalApi.patch({ server: `company/`, path: `${companyId}/roles/${id}`},{title,description,active,permissions});
}