import {memo} from 'react';
import {useProvideAuth} from './hook';
import authContext from './context';

const ProvideAuth = ({children}) => {
    const auth = useProvideAuth();
    // console.log('auth.user-> ',auth.user);
    return (
        <authContext.Provider value={auth}>
          {children}
        </authContext.Provider>
    );
};

export default memo(ProvideAuth);