import { memo, useEffect, useReducer, useState } from 'react';
import _ from 'lodash';
import styles from './EditRole.module.scss';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from 'components/BreadCrumbs';
import { toast } from 'react-toastify';
import basicReducer from 'reducers/basicReducer';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';
import Group from 'components/Group';
import Button from 'components/Button';
import Heading from 'components/Heading';
import api from 'api';
import { useAuth } from 'components/ProvideAuth/hook';
import Textarea from 'components/Form/Textarea';

const extractPermissionType = ({ moduleId, type, permissions }: { moduleId; type; permissions; }) => {
  if (permissions) {
    let permission = permissions?.find?.(p => p?.module?.id === moduleId);
    if (permission) {
      return permission?.[type];
    }
  }
  return false;
}


const EditRole = props => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = useReducer(basicReducer, {
    permissions: [],
    selectedPermissions: [],
    modules: []
  });
  const [form, setForm] = useReducer(basicReducer, {
    id: '',
    title: '',
    description: '',
  });
  const [errors, setErrors] = useState({
    name: false
  })

  useEffect(() => {
    api.modules.listModules?.()?.then?.(result => {
      //result.pop(); // TODO: Change when settings Module is implemented
      result.sort((a, b) => {
        var titleA = a.title.toUpperCase();
        var titleB = b.title.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });      
      setState({ modules: result })
    });
  }, []);

  useEffect(() => {
    if(params.id){
        api.roles.getRolePermission({companyId:auth?.company?.id,roleId:params.id}).then(out => {
            setForm(out);
            setState({selectedPermissions: out?.permissions});
        });
    }
  }, [params.id]);

  const createOrSave = () => {
    if(form?.title){
        let _toSave = {...form};
        if(!_toSave?.id){
            delete _toSave.id;
        }

        let toRun = _toSave?.id ? api.roles.updateRole : api.roles.createRole;

        toRun({
            ..._toSave,
            companyId:auth?.company?.id,
            permissions: state.selectedPermissions,
        }).then(updated => {
            if(form?.id){
                toast.success('Successfully updated');
            }else{
                toast.success('Successfully created');
            }
            navigate('/admin/roles');
            // console.log('updated-> ',updated);
        });
    }
  }

  const onChangePerm = (isChecked, moduleId,permissionType) => {
    let { selectedPermissions } = state;
    let modulePresent = selectedPermissions?.find?.(p => p?.module?.id === moduleId);
    if(modulePresent){
      modulePresent[permissionType] = isChecked;
    }else{
      let modulePerms = {
        add: false,
        delete: false,
        update: false,
        read: false
      };
      modulePerms[permissionType] = isChecked;
      selectedPermissions.push({
        module: {
          id: moduleId
        },
        ...modulePerms
      });
    }

    setState({
      selectedPermissions: [...selectedPermissions]
    });
  };
  return (
    <div className={styles.root}>
      <Breadcrumb list={[
        {
          name: 'Roles',
          url: 'admin/roles'
        },
        {
          name: `${params?.id ? 'Edit' : 'Add'} Role`
        },
      ]} />
      <Heading>{params?.id ? 'Edit' : 'Add'} Role</Heading>

      <Group label="Name">
        <Input value={form?.title} onChange={title => setForm({ ...form, title })} />
      </Group>
      {/* <Group label="Description">
        <Textarea value={form?.description} onChange={description => setForm({ ...form, description })} />
      </Group> */}

      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Module Name</th>
              <th>View</th>
              <th>Add</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {state?.modules.map((module, key) => (
              <tr key={key}>
                <td>{module?.title}</td>
                <td>
                  <Checkbox
                    checked={extractPermissionType({moduleId:module?.id, permissions: state?.selectedPermissions,type: 'read'})}
                    onChange={isChecked => onChangePerm(isChecked,module?.id,'read')}
                  />
                </td>
                <td >
                  <Checkbox
                    checked={extractPermissionType({moduleId:module?.id, permissions: state?.selectedPermissions,type: 'add'})}
                    onChange={isChecked => onChangePerm(isChecked,module?.id,'add')}
                  />
                </td>
                <td >
                  <Checkbox
                    checked={extractPermissionType({moduleId:module?.id, permissions: state?.selectedPermissions,type: 'update'})}
                    onChange={isChecked => onChangePerm(isChecked,module?.id,'update')}
                    />
                </td>
                <td >
                  <Checkbox
                    checked={extractPermissionType({moduleId:module?.id, permissions: state?.selectedPermissions,type: 'delete'})}
                    onChange={isChecked => onChangePerm(isChecked,module?.id,'delete')}
                    />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Group>
        <Button onClick={() => navigate('/admin/roles')} view="cancel">Cancel</Button>
        <Button onClick={createOrSave} style={{ marginLeft: 10 }}>Save</Button>
      </Group>
    </div>
  );

};

export default memo(EditRole);