import internalApi from "shared/utils/internalApi";

export const signIn = (form: any) => {
  // return internalApi.post({ server: 'auth/', path: 'authenticate' }, form);
  return internalApi.post({ server: "auth/", path: "login" }, form);
};

export const signUp = (form: any) => {
  return internalApi.post({ server: "auth/", path: "register" }, form);
};

export const getProfile = () => {
  return internalApi.get({ server: "auth/", path: "profile" });
};

export const resetPassword = (form: any) => {
  return internalApi.post({ server: "auth/", path: "reset-password" }, form);
};

export const forgotPassword = (form: any) => {
  return internalApi.post({ server: "auth/", path: "forgot-password" }, form);
};

export const verifyOTP = (form: any) => {
  return internalApi.post({ server: "auth/", path: "forgot-password/verify-otp" }, form);
};

export const getCompanyByAlias = (alias: any) => {
  return internalApi.post(
    { server: "company/", path: "getByAlias" },
    { alias }
  );
};

export const changePassword = (form: any) => {
  return internalApi.patch({ server: "auth/", path: "update-password" }, form);
};

export const resetForgotPassword = (form: any) => {
  return internalApi.patch({ server: "auth/", path: "reset-password" }, form);
};

export const updateProfile = (form: any) => {
  return internalApi.patch({ server: "auth/", path: "profile" }, form);
};

export const updateNotifications = (form:any) => {
  return internalApi.patch({ server: "auth/", path: "notification" },form);
};


