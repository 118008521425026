import {memo} from 'react';
import styles from './AccessDenied.module.scss';

const AccessDenied = props => {

    return (
        <div className={styles.root}>
            Access Denied
        </div>
    );

};

export default memo(AccessDenied);