import { v4 as uuidv4 } from "uuid";

export const DateFormat = "MM/DD/YYYY hh:mm A";

export const currentYear = () => {
    return new Date().getFullYear();
}

export const to = <Type>(promise: Promise<Type>): Promise<[any, Type]> => {
    return promise.then(data => {
        return [null, data];
    })
        .catch(err => {
            return [err] as any;
        });
}

export type ObjectValues<T extends object> = T[keyof T];

export const uniqueId = uuidv4;

export const fireAndForget = (cb:Promise<any>) => {
    cb?.then?.(data => data)?.catch?.(e => { throw e; });
}

export const isSanitizedTxt = str => {
    let isValid = true;
    ['<script>','</script>'].forEach(forbidden => {
        if(str.toLowerCase().indexOf(forbidden) !== -1){
            isValid = false;
        }
    });

    return isValid;
}

export const postForm = ({ url, body }) => {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);
    form.setAttribute("target", "_blank");
    form.setAttribute("enctype", "application/x-www-form-urlencoded");

    if (body && typeof body === 'object') {
        Object.keys(body).forEach(attr => {
            var _field = document.createElement("input");
            _field.setAttribute("name", attr);
            _field.setAttribute("value", body[attr]);
            form.appendChild(_field);
        });

    }

    document.body.appendChild(form); // Not entirely sure if this is necessary          
    form.submit();
    document.body.removeChild(form);
}

export const sortFields = (moduleFields:Array<any>) => {
    let filteredFields = [];
    if (moduleFields && Array.isArray(moduleFields)) {
        filteredFields = [...moduleFields];
        filteredFields?.sort?.((a, b) => {
            let keyA = a?.sortIndex;
            let keyB = b?.sortIndex;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
    return filteredFields;
}

export function camelToPascal(string) {
    let str = string.replace(/[\w]([A-Z])/g, function(m) {
        return m[0] + " " + m[1]?.toUpperCase?.();
    });//.toLowerCase();
    str = str?.[0]?.toUpperCase?.() + str?.substring?.(1);
    return str;
}

export const toSnakeCase = function (oStr) {
	var upperChars = oStr.match(/([A-Z])/g);
	if (!upperChars) {
		return oStr;
	}

	var str = oStr.toString();
	for (var i = 0, n = upperChars.length; i < n; i++) {
		str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
	}

	if (str.slice(0, 1) === '-') {
		str = str.slice(1);
	}

	return str;
};

export const lowerFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1);
export const upperFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

export const createDisplayNameFromFieldName = (name = '') => toSnakeCase(name).split('-').map(i => upperFirstLetter(i)).join(' ')

export const getFormattedDateTime = (datea) => {
    if (!datea) return "";
    const date = new Date(datea);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    hours = (hours % 12) || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes} ${amOrPm}`;
    return formattedDate;          
}
