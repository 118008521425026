import { memo, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// import { useSelector } from 'react-redux';

import Loading from "components/Loading";
import PrivateRoute from "components/PrivateRoute";
// import SuperAdminRoute from 'components/SuperAdminRoute';
// import styles from './App.module.scss';
import * as routeAccess from "shared/utils/accessControl";

import Home from "views/app/Home";
import Administration from "views/app/Administration";
import Contacts from "views/app/Contacts";
import Events from "views/app/Events";
import Locations from "views/app/Locations";
import Users from "views/app/Administration/Users";
import Roles from "views/app/Administration/Roles";
import EditLocation from "views/app/Locations/EditLocation";
import EditUser from "views/app/Administration/Users/EditUser";
import EditRole from "views/app/Administration/Roles/EditRole";
import EditEvent from "views/app/Events/EditEvent/EditEvent";
import EditContact from "views/app/Contacts/EditContact";
import GeneralEvents from "views/app/Events/EventsTracking/GeneralEvents";
import PollEvents from "views/app/Events/EventsTracking/PollEvents";
import ImportContact from "views/app/Contacts/ImportContact";
import ImportLocation from "views/app/Locations/ImportLocation";
import Companies from "views/app/Companies/Companies";
import EditCompany from "views/app/Companies/EditCompany/EditCompany";
import { useAuth } from "components/ProvideAuth/hook";
import Credits from "views/app/Companies/Credits/Credits";
import Templates from "views/app/Administration/Templates";
import EditTemplate from "views/app/Administration/Templates/EditTemplate";
import UserProfile from "views/app/Home/Profile/UserProfile";
import ContactHome from "views/app/ContactHome/ContactHome";
import AssemblyPoints from "views/app/AssemblyPoints/AssemblyPoints";
import Chat from "views/app/Events/EventsTracking/PollEvents/Chat";
import ContactMessages from "views/app/ContactHome/ContactMessages/ContactMessages";
import PaymentPlan from "views/auth/PaymentPlan";
import TermPolicy from "views/auth/TermPolicy";

const App = (props) => {
  // const perms = useSelector(state => state?.userPermissions?.permissions);
  const auth = useAuth();
  const user = useAuth().user;

  const superAdmin = auth.user.user_type === "superAdmin";
  const contact = auth.user.user_type === "contact";
  // console.log('perms-> ',perms);
  return (
    <Suspense
      fallback={<Loading fixed={false} size="sm" type="ball-pulse-sync" />}
    >
      <Routes>
        {superAdmin && (
          <Route path="/" element={<PrivateRoute element={<Companies />} />} />
        )}
        <Route
          path="/companies"
          element={<PrivateRoute element={<Companies />} />}
        />
        <Route
          path="/company/edit/:id"
          element={<PrivateRoute element={<EditCompany />} />}
        />
        <Route
          path="/company/edit/:id/credits"
          element={<PrivateRoute element={<Credits />} />}
        />
        <Route
          path="/payment-select"
          element={<PrivateRoute element={<PaymentPlan login={true} />} />}
        />

        {!superAdmin && !contact && (
          <Route path="/" element={<PrivateRoute element={<Home />} />} />
        )}
        <Route
          path="/"
          element={
            <PrivateRoute
              element={
                user.user_type === "contact" ? <ContactHome /> : <Home />
              }
            />
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute
              element={
                user.user_type === "contact" ? <ContactHome /> : <Home />
              }
            />
          }
        />
        <Route
          path="/messages"
          element={
            <PrivateRoute
              element={
                user.user_type === "contact" ? <ContactMessages /> : <div />
              }
            />
          }
        />
        <Route
          path="/assemblyPoints"
          element={<PrivateRoute element={<AssemblyPoints />} />}
        />
        {/* <Route path="/home" element={<PrivateRoute element={<Home />} />} /> */}
        {/* <Route path="/administration" element={<PrivateRoute element={<Administration />} />} /> */}
        <Route
          path="home/profile"
          element={<PrivateRoute element={<UserProfile />} />}
        />
        <Route
          path="/admin/users"
          element={<PrivateRoute element={<Users />} />}
        />
        <Route
          path="/admin/users/edit/:id"
          element={
            <PrivateRoute
              element={<EditUser />}
              permission={routeAccess.canUpdateUsers()}
            />
          }
        />
        <Route
          path="/admin/users/add"
          element={
            <PrivateRoute
              element={<EditUser />}
              permission={routeAccess.canCreateUsers()}
            />
          }
        />

        <Route
          path="/admin/contacts"
          element={<PrivateRoute element={<Contacts />} />}
        />
        <Route
          path="/admin/contacts/edit/:id"
          element={
            <PrivateRoute
              element={<EditContact />}
              permission={routeAccess.canUpdateUsers()}
            />
          }
        />
        <Route
          path="/admin/contacts/add"
          element={
            <PrivateRoute
              element={<EditContact />}
              permission={routeAccess.canCreateUsers()}
            />
          }
        />
        <Route
          path="/admin/contacts/import"
          element={
            <PrivateRoute
              element={<ImportContact />}
              permission={routeAccess.canCreateUsers()}
            />
          }
        />

        <Route
          path="/admin/roles"
          element={
            <PrivateRoute
              element={<Roles />}
              permission={routeAccess.canViewRoles()}
            />
          }
        />
        <Route
          path="/admin/roles/edit/:id"
          element={
            <PrivateRoute
              element={<EditRole />}
              permission={routeAccess.canUpdateRoles()}
            />
          }
        />
        <Route
          path="/admin/roles/add"
          element={
            <PrivateRoute
              element={<EditRole />}
              permission={routeAccess.canCreateRoles()}
            />
          }
        />

        <Route
          path="/contacts"
          element={<PrivateRoute element={<Contacts />} />}
        />

        <Route path="/events" element={<PrivateRoute element={<Events />} />} />
        <Route
          path="/events/add"
          element={
            <PrivateRoute
              path="/events/add"
              element={<EditEvent />}
              permission={routeAccess.canCreateEvents()}
            />
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRoute
              path="/events/edit/:id"
              element={<EditEvent />}
              permission={routeAccess.canUpdateEvents()}
            />
          }
        />
        <Route
          path="/events/:id/general"
          element={
            <PrivateRoute
              path="/events/:id/general"
              element={<GeneralEvents />}
              permission={routeAccess.canCreateLocations()}
            />
          }
        />
        <Route
          path="/events/:id/poll"
          element={
            <PrivateRoute
              path="/events/:id/poll"
              element={<PollEvents />}
              permission={routeAccess.canCreateLocations()}
            />
          }
        />
        <Route
          path="/events/:id/poll/:userId/:contactId"
          element={
            <PrivateRoute
              path="/events/:id/poll/:userId/:contactId"
              element={<Chat />}
              permission={routeAccess.canCreateLocations()}
            />
          }
        />

        <Route
          path="/locations"
          element={<PrivateRoute element={<Locations />} />}
        />
        <Route
          path="/locations/add"
          element={
            <PrivateRoute
              path="/locations/add"
              element={<EditLocation />}
              permission={routeAccess.canCreateLocations()}
            />
          }
        />
        <Route
          path="/locations/edit/:id"
          element={
            <PrivateRoute
              path="/locations/edit/:id"
              element={<EditLocation />}
              permission={routeAccess.canUpdateLocations()}
            />
          }
        />
        <Route
          path="/locations/import"
          element={
            <PrivateRoute
              element={<ImportLocation />}
              permission={routeAccess.canCreateUsers()}
            />
          }
        />

        <Route
          path="/admin/templates"
          element={
            <PrivateRoute
              element={<Templates />}
              permission={routeAccess.canViewRoles()}
            />
          }
        />
        <Route
          path="/admin/templates/add"
          element={
            <PrivateRoute
              element={<EditTemplate />}
              permission={routeAccess.canViewRoles()}
            />
          }
        />
        <Route
          path="/admin/templates/edit/:id"
          element={
            <PrivateRoute
              element={<EditTemplate />}
              permission={routeAccess.canViewRoles()}
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default memo(App);
