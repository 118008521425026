import {memo} from 'react';
import styles from './TopHeader.module.scss';

const TopHeader = ({children}) => {

    return (
        <div className={styles.root}>
            {children}
        </div>
    );

};

export default memo(TopHeader);