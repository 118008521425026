import { memo, useEffect, useState } from 'react';
import styles from './PlanCalculator.module.scss';
import PublicLayout from 'layout/PublicLayout';
import { Box, Grid, Input, Slider, Tooltip, Typography } from '@mui/material';
import { MdInfo } from 'react-icons/md';


const PlanCalculator = props => {

    const RighContent = () => {
        const BASE_PRICE_100_PLUS = 3000;
        const BASE_PRICE_LESSTHAN_100 = 1500;

        const [value, setValue] = useState<number | string | Array<number | string>>(
            100,
        );

        const [sms, setSMS] = useState(2400);
        const [suggestedCredits, setSuggestedCredits] = useState(0);
        const [basePrice, setBasePrice] = useState(0);

        const handleSliderChange = (event: Event, newValue: number | number[]) => {
            setValue(newValue);
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value === '' ? '' : Number(event.target.value);
            setValue(newValue);
        };

        const handleBlur = () => {
            if (Number(value) < 0) {
                setValue(0);
            } else if (Number(value) > 5000) {
                setValue(5000);
            }
        };

        // const getPriceFromCents = cents => {
        //     return cents / 100
        // }

        const calulateSMS = employees => {
            if (employees >= 0) {
                const sms = employees * 2 * 12;
                setSMS(sms);
            }
        }

        useEffect(() => {
            calulateSMS(value);
        }, [value])

        useEffect(() => {
            let credits = 0;
            if (sms >= 100 && sms <= 2499) {
                credits = (sms * 9.0 / 100)
            }
            else if (sms >= 2500 && sms <= 4900) {
                credits = (sms * 8.5 / 100)
            }
            else if (sms >= 5000 && sms <= 9999) {
                credits = (sms * 7.5 / 100)
            }
            else if (sms >= 10000 && sms <= 19999) {
                credits = (sms * 7.0 / 100)
            }
            else if (sms >= 20000 && sms <= 49999) {
                credits = (sms * 6.5 / 100)
            }
            else if (sms >= 50000 && sms <= 99999) {
                credits = (sms * 6.0 / 100)
            }
            else if (sms >= 100000 && sms <= 249999) {
                credits = (sms * 5.5 / 100)
            }
            else if (sms >= 250000) {
                credits = (sms * 5.0 / 100)
            }
            setSuggestedCredits(Math.round(credits));
        }, [sms])

        useEffect(() => {
            const basePrice = (Number(value) < 100) ? BASE_PRICE_LESSTHAN_100 : BASE_PRICE_100_PLUS;
            setBasePrice(basePrice)
        }, [value])

        return <Box sx={{ width: 450 }}>
            <Tooltip title={`Base fee is $3000 for employees of 100+. $1500 for less than 100.
            SMS will be calculated by:
                            Enter # of employees x 2 x 12. Total # of suggested SMS.`}>
                <Typography id="input-slider" gutterBottom fontSize={18} fontWeight={'bold'}>
                    Enter No. of Employees

                    <MdInfo />
                </Typography>
            </Tooltip>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={0}
                        max={5000}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={3}>
                <Grid item xs>
                    <Input
                        value={value}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        style={{ width: '75px' }}
                        inputProps={{
                            step: 10,
                            min: 0,
                            max: 3000,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={3}>
                <Grid item xs>
                    For getting credits contact us at <a href={"mailto:support@emeva.io"}>support@emeva.io</a>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={4}>
                <Grid item xs>
                    Total # of Credits for {value} employees = {sms}
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={1}>
                <Grid item xs>
                    Cost for {sms} credits = ${suggestedCredits}
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={1}>
                <Grid item xs>
                    <Typography gutterBottom fontSize={18} fontWeight={'bold'}>Total (inc. ${basePrice} base price) = ${suggestedCredits + basePrice} (annually)</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" paddingTop={3}>
                <Grid item>
                    For a more information get in touch with us...
                    <br />
                    Email us at <a href={"mailto:support@emeva.io"}>support@emeva.io</a>
                </Grid>
            </Grid>
        </Box>
    }

    return (
        <PublicLayout rightContent={<RighContent />}>
            <div className={styles.root}>
                <div className={styles.message}>
                    Plan calculator
                </div>
            </div>
        </PublicLayout>
    );

};

export default memo(PlanCalculator);