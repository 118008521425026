import { memo } from "react";
import ReactSelect from "react-select";
import styles from "./Select.module.scss";
const Select = ({
  value,
  options,
  onChange,
  readOnly = false,
  isClearable = false,
  isMultiple = false,
  placeholder = null,
}) => {
  const formattedOptions = options?.map((option) => {
    if (typeof option !== "object") {
      return {
        value: option,
        label: option,
      };
    }
    return option;
  });

  const selectedOption =
    value || value === 0
      ? formattedOptions?.filter((item) => {
          if (isMultiple) {
            if (typeof value === "object" && Array.isArray(value)) {
              return value?.includes(item.value);
            } else if (typeof value === "string") {
              return value?.split?.(",")?.includes(item.value);
            }
          } else {
            return item.value === value;
          }
        })
      : null;

  return (
    <div className={styles.root}>
      <ReactSelect
        isMulti={isMultiple}
        menuPlacement="auto"
        // name={name}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
        menuPortalTarget={document.body}
        value={selectedOption}
        options={formattedOptions}
        isDisabled={readOnly}
        isClearable={isClearable}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default memo(Select);
