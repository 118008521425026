import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MdFileUpload,MdAttachFile } from 'react-icons/md';

import internalApi from 'shared/utils/internalApi';
import styles from './UploadButton.module.scss';


/*
value = {
  id: "2dd7dfde-0582-4898-ae1b-dd491c2a0559"
  fileName: "2dd7dfde-0582-4898-ae1b-dd491c2a0559.pdf"
  originalFileName: "BIA Report-Morton-v2.pdf"
  extension: "pdf"
  path: "/Volumes/Main/Users/nabeel/Projects/TaskItem LLC/carlo/git/chevron-backend/public/uploads"
}
*/

class FileUploader extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    accept: PropTypes.string,
    onUpload: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    name: '',
    value: null,
    label: 'Select From Your Computer',
    accept: '*'
  };

  static isImage(type) {
    if (!type) {
      return false;
    }
    return (
      _.findIndex(['jpg', 'png', 'jpeg'], ext => type.includes(ext)) !== -1
    );
  }

  constructor(props) {
    super(props);

    let fileUrl = '';
    let isImage = false;
    if (props?.value) {
      fileUrl = `${props?.value?.path}${props?.value?.fileName}`;
      isImage = FileUploader.isImage(props.value?.extension);
    }

    this.state = {
      loading: false,
      fileUrl,
      isImage,
      file: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        fileUrl: `${nextProps?.value?.path}${nextProps?.value?.fileName}`,
        isImage: FileUploader.isImage(nextProps.value?.extension),
      });
    }
  }

  onUploadImage = e => {
    e.persist();

    if (this.state.loading) {
      return;
    }

    const self = this;

    this.setState({ loading: true }, () => {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (() => fileEvt => {
        self.setState({
          fileUrl: fileEvt.target.result,
          loading: false,
          file,
          isImage: FileUploader.isImage(file.type),
        });
      })(file);

      reader.readAsDataURL(file);

      this.props.onUpload(file, this.props.accept);
    });
  };

  deleteFile = () => {
    internalApi.post(`asset/${this.props.value.id}/delete`).then(data => {
      this.setState({ file: null, fileUrl: null }, () => {
        if (typeof this.props.onDelete !== 'undefined') {
          this.props.onDelete(null);
        }
      });
    });
  }

  render() {
    return (
      <div className={styles.root}>
        <label className={styles.label}>
          <input accept={this.props.accept} type="file" hidden onChange={this.onUploadImage} />
            <div>
              <MdFileUpload size={30} /> {this.props.label}
            </div>
        </label>
      </div>
    );
  }

}

export default FileUploader;
