import { memo, useReducer } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import PublicLayout from 'layout/PublicLayout';
import styles from './ForgotPassword.module.scss';

import { useAuth } from 'components/ProvideAuth/hook';
import Input from 'components/Form/Input';
import Button from 'components/Button';
import Group from 'components/Group';
import basicReducer from 'reducers/basicReducer';
// import swal from 'sweetalert';
import rightImage from 'assets/reminder_re_fe15.svg';

const ForgotPassword = props => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    let [form, setForm]:[any,any] = useReducer(basicReducer, {
        email: '',
        password: '',
    });

    let [state, setState]:[any,any] = useReducer(basicReducer, {
        ssoLoginUrl: '',
    });

    let { from }: any = location?.state || { from: { pathname: "/verify-otp" } };
    
    const resetPassowrd = () => {
        auth.forgotPassword(form,() => {   
            navigate(`/verify-otp?email=${form?.email}`);
        });
    }
    return (
        <PublicLayout rightContent={<img src={rightImage} height={300} />}>
            <div className={styles.root}>
                    {/* <div className="text-center*">
                        <h3>Login</h3>
                    </div> */}
                    <form className="user">
                        <Group label={`Email Address`}>
                            <Input
                                type="email"
                                className="form-control form-control-user"
                                value={form?.email} 
                                name="email"
                                onChange={email => setForm({ email })}
                                placeholder="Enter Address" />
                        </Group>
                        <Group style={{marginTop:20}}>
                            <Button style={{width:400,fontWeight:'bold'}} contentDirection="center" view="primary" type="button" onClick={resetPassowrd}>Send OTP</Button>
                        </Group>
                            

                    </form>
                    {/* <hr /> */}
                    <br />
                    {/* <Group className="text-left">
                        <Link className="small link-red" to="/register">Create an Account!</Link>
                    </Group>
                    <br /> */}

                


            </div>
        </PublicLayout>
    );

};

export default memo(ForgotPassword);