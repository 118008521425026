import { memo, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
// import logo from 'assets/logo.png';
import logo from 'assets/logo_blue.png';
import { FaRegBell, FaCog, FaChevronDown,FaInbox } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import Dropdown, { DropdownItem } from 'components/Dropdown';
import { useAuth } from 'components/ProvideAuth/hook';
import { useSocket } from 'components/SocketBase/hook';
import { useSidebarToggle, useSidebarVisible } from 'hooks/sidebar';
import internalApi from "shared/utils/internalApi";
// import { fetchNotifications, updateNotificationStatus } from 'shared/api/header';
import basicReducer from 'reducers/basicReducer';
import cx from 'classnames';
import { BsBell, BsChat } from 'react-icons/bs';

let newMessageNotificationMap = new Map();

const Header = props => {
	const navigate = useNavigate();
	const sidebarVisible = useSidebarVisible();
	const toggleSidebar = useSidebarToggle();
	const auth = useAuth();
	const socket = useSocket();
	const openProfile = () => {
		navigate('/home/profile')
	}
	const [state, setState] = useReducer(basicReducer, {
		messages: [],
		newMessageNotification: [],
		isSocketUpdated: '',
		notificationCount: 0,
		isOpen: false
	});
	const logout = () => {
		auth.signout(() => {
			navigate('/login');
		});
	}

	// useEffect(() => {
	// 	fetchNotifications().then(list => {
	// 		const rec = list?.data?.records;
	// 		let cnt = 0;
	// 		if (rec) {
	// 			rec.forEach(i => {
	// 				if (i.seen == false) {
	// 					cnt += 1;
	// 				}
	// 			})
	// 			setState({ messages: rec, notificationCount: cnt });
	// 		}
	// 	});
	// }, [state.isSocketUpdated]);

	useEffect(() => {
		// let socket = io(internalApi.webSocketUrl(), { transports: ['websocket'] });
		// socket.on("notification", function (data: any) {
		// 	// console.log('socket data:- ' + data);
		// 	setState({ isSocketUpdated: data });
		// });

		let subscribed = socket.subscribe((event,data) => {
			switch(event){
				case "notification":{
					setState({ isSocketUpdated: data });
					break;
				}

				case "newMessageNotification":{
					// console.log('1. Header::newMessageNotification ->', `${data?.roomId} | ${data.modifiedBy.firstName}`);
					if(!newMessageNotificationMap.get(`${data?.roomId}${data.modifiedBy.id}`)){
						// console.log('2. Header::newMessageNotification ->', `${data?.roomId} | ${data.modifiedBy.firstName}`);
						newMessageNotificationMap.set(`${data?.roomId}${data.modifiedBy.id}`,true);
						setState({newMessageNotification:[{...data,seen:false},...state?.newMessageNotification]});
					}
		
					break;
				}
			}
        });
		// console.log('subscribed-> ',subscribed);

		return () => {
			// console.log('unsub-> ',subscribed);
			socket.unsubscribe(subscribed);
		}

	}, [state?.newMessageNotification]);

	// useEffect(() => {
	// 	console.log('socket.on->newMessageNotification');

		

	// },[state?.newMessageNotification]);

	const toggleDropdown = async (item) => {
		setState({ isOpen: !state.isOpen });
		if(!item?.seen){
			// await updateNotificationStatus(item['id']).then(list => {
			// 	const rec = list?.data?.records;
			// 	let cnt = 0;
			// 	if (rec) {
			// 		rec.forEach(i => {
			// 			if (i.seen == false) {
			// 				cnt += 1;
			// 			}
			// 		})
			// 		setState({ messages: rec, notificationCount: cnt });
			// 	}
			// });
		}
	}
	
	const toggleMessageDropdown = async (item) => {
		if(newMessageNotificationMap.get(`${item?.roomId}${item.modifiedBy.id}`)){
			newMessageNotificationMap.delete(`${item?.roomId}${item.modifiedBy.id}`);
			// newMessageNotificationMap.clear();
		}

		// console.log('item-> ',item);
		if(!item.seen){
			item.seen = true;
			setState({ newMessageNotification:[...state?.newMessageNotification] });
		}
		navigate(`/events/${item?.roomId}/chat`);
	}

	const openRelevantWindow = data => {
		// console.log('openRelevantWindow -> ',data);
        if(data?.meta?.info?.eventId){
            let eventId = data?.meta?.info?.eventId;
            // console.log('opening event ,', eventId);
            navigate(`/events/${eventId}/playbook`);
        }
    }

	let modalStyle = state.messages.length != 0 ? styles.customDropdownarea : styles.customNoItemDropdownarea;

	return (
		<>
			<div className={styles.root}>
				<div className={styles.logoContainer}>
					<img style={{ height: 50 }} src={logo} alt="Emeva" />
					<div onClick={() => toggleSidebar(!sidebarVisible)} className={styles.menuIcon}><GiHamburgerMenu color="#A3A2A3" /></div>
				</div>
				<div className={styles.rightNav}>
				
					{state?.newMessageNotification?.length > 0 && state?.newMessageNotification?.filter(i => !i.seen).length > 0 && <span style={{width:8,height:8,minWidth:8,top:-11}} className={styles.notificationCount}></span>}

					
					{state.notificationCount != 0 && <span className={styles.notificationCount}>{state.notificationCount}</span>}
					<span className={styles.seperator}></span>

					<Dropdown top={50} header={
						<>
							{auth?.user?.avatar && (
								// <span className={styles.profileCircle}><img src={`${internalApi.formatAssetUrl(auth?.user?.avatar)}`} alt="user" /></span>
								<span className={styles.profileCircle} style={{backgroundImage:`url(${internalApi.formatAssetUrl(auth?.user?.avatar)})`}}></span>
							)}
							{!auth?.user?.avatar && (
								<span className={styles.profileCircle}>{auth?.user?.first_name?.slice(0, 1)}{auth?.user?.last_name?.slice(0, 1)}</span>
							)}
                            <div style={{lineHeight:'20px',minWidth:100}}>
                                <div style={{color:'#A7B5C9'}}>Welcome</div>
    							<div style={{fontWeight:'bold'}}>{auth?.user?.first_name}</div>

                            </div>
							<span style={{ height: 14 }}><FaChevronDown /></span>
						</>
					}>
						<DropdownItem onClick={openProfile}>Profile</DropdownItem>
						<DropdownItem onClick={logout}>Logout</DropdownItem>
					</Dropdown>
				</div>
			</div>
			<div style={{ height: 55 }}></div>
		</>
	);

};

export default memo(Header);