import {memo} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Actions.module.scss';
import Button from 'components/Button';
import { FaTrash,FaEdit } from 'react-icons/fa';
import * as acl from 'shared/utils/accessControl';

const Actions = ({id,onDelete}) => {
    const navigate = useNavigate();
    const userPermissions = [];//useSelector((state:any) => state?.userPermissions);

    return (
        <div className={styles.root}>
            {acl.hasPerm(acl.canUpdateLocations(),userPermissions) && (
                <Button onClick={() => navigate(`edit/${id}`)}><FaEdit /></Button>
            )}
            {acl.hasPerm(acl.canDeleteLocations(),userPermissions) && (
                <Button onClick={() => onDelete(id)} view="danger" requireConfirmation={true} style={{marginLeft:10}}><FaTrash /></Button>
            )}
            {/* <Button style={{marginLeft:10}}>Copy</Button> */}
            {/* <Button>Edit</Button> */}
        </div>
    );

};

export default memo(Actions);