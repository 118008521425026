import axios from 'axios';
import config from './config';
import notify from './notify';
import storage from './storage';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: config.apiUrl,
});

instance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    let errorMessage = 'Something went wrong!';

    if (response) {
      if (response?.data && response?.data?.error) {
        errorMessage = response?.data?.message ? response?.data?.message : response?.data?.error;
      }

      if (response.status === 403 || response.status === 401) {
        storage.remove('token');
        toast.error(errorMessage);

        // window.location.reload();
        return Promise.reject(response);
      }
    }

    // if (store) {
    //   store.dispatch(pushError(errorMessage));
    // }
    //notify(errorMessage, 'error');
    return Promise.reject(response);
  },
);

export default instance;
