import {memo} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Actions.module.scss';
import Button from 'components/Button';
import { FaEdit , FaCheck , FaTimes } from 'react-icons/fa';
import { useAuth } from 'components/ProvideAuth/hook';
import { Tooltip } from '@mui/material';

const Actions = ({id, onDisable, approved}) => {
    const navigate = useNavigate();
    const superAdmin = useAuth().user.user_type === "superAdmin"
    const Icon = approved ? FaTimes : FaCheck
    return (
        <div className={styles.root}>
            {superAdmin && (
                <Button onClick={() => navigate(`../company/edit/${id}`)}><FaEdit /></Button>
            )}
            {superAdmin && (
                 <Tooltip
                 title={`${approved ? 'Click to Disable' : 'Click to Activate'}`}
                 placement="top"
                 >
                    <div style={{width: "fit-content"}}>
                        <Button onClick={() => onDisable(id)} view={ approved ? "danger" : "primary"} requireConfirmation={true} confirmationMessage='Are you sure you want to approve?'><Icon /></Button>
                    </div>
                </Tooltip>
            )}
        </div>
    );

};

export default memo(Actions);