import { memo, useEffect, useState, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import styles from "./Table.module.scss";
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Select from "components/Form/Select";
import Button from "components/Button";
import DragableRow from "./DragableRow";
import moment from "moment";
import { DateFormat } from "shared/utils/common";
import { Filter, DefaultColumnFilter } from "./filters";

const DNDProvider: any = DndProvider;

const fontStyle = {
  fontSize: 10,
};
const Table = ({
  columns,
  data,
  fetchData = null,
  showFilter = true,
  paginateData = true,
  loading = false,
  pageCount: controlledPageCount = 1,
  enableDrag = false,
  parentPageSize,
  totalCount = 0,
  pageChangeVisible = true
}) => {
  const [records, setRecords] = useState(data);

  const getRowId = useCallback((row) => {
    return row?.id || row?.col1;
  }, []);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: false,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
      // getRowId,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    let newRecords = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });
    setRecords(newRecords);
  };
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : "";
  };

  let recordsToList = paginateData ? page : rows;
  return (
    <div className={styles.root}>
      <DNDProvider backend={HTML5Backend}>
        <table className={styles.table} {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      // Apply the header 	cell props
                      let expandWidth: any = {};
                      // console.log('column?.width-> ',column);
                      if (column?.width) {
                        expandWidth.width = column?.width;
                      }
                      return (
                        <th
                          {...expandWidth}
                          className={column.className}
                          {...column.getHeaderProps()}
                        >
                          <div {...column.getSortByToggleProps()}>
                            {
                              // Render the header
                              column.render("Header")
                            }
                            {generateSortingIndicator(column)}
                          </div>
                          {showFilter && column.Header !== "Company Size" && (
                            <Filter column={column} />
                          )}
                          {/* { showFilter && column.Header === "Company Size" && 
                          <div style={{marginTop : "5px"}}>
                            <Select
                                value={companySize}
                                options={[{value:0,label:'Select option'},{value:"10-20",label:'10-20'},{value:"20-40",label:'20-40'},{value:"40-60",label:'40-60'},{value:"60-100",label:'60-100'}]}
                                onChange={size => {
                                  setCompanySizeValue(size?.value)
                                  if (size?.value)
                                    column.setFilter(size?.value)
                                  else
                                    column.setFilter(undefined)
                                }}
                              />
                            </div>
                        } */}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              // rows.map
              recordsToList.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);

                return enableDrag ? (
                  <DragableRow
                    key={index}
                    index={index}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                  />
                ) : (
                  // Apply the row props
                  <tr key={index} {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                paddingBottom: "10px",
                              },
                            })}
                          >
                            {
                              // Render the cell contents
                              cell.column.Header == "Modified On" ||
                                cell.column.Header == "Created On"
                                ? moment(cell.value).format(DateFormat)
                                : cell.column.Header == "Uploaded On"
                                  ? moment(cell.value).format("L")
                                  : cell.value
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </DNDProvider>

      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}

      {paginateData && (
        <div className={styles.pagination}>
          {/* <div className={styles.left}> */}
          <div style={{ width: '10%', marginRight: 10, visibility: pageChangeVisible ? 'visible' : 'hidden' }}>
            <Select
              value={pageSize}
              options={[5, 10, 20, 30, 40, 50, 100]}
              onChange={(out) => {
                // console.log('change ->',e);
                setPageSize(Number(out.value));
                parentPageSize(out.value);
              }}
            />
          </div>
          <div className={styles.page}>
            <Button
              style={{ fontSize: 10 }}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <FaAngleDoubleLeft />
            </Button>{" "}
            <Button
              style={fontStyle}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <FaAngleLeft />
            </Button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <Button
              style={fontStyle}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <FaAngleRight />
            </Button>{" "}
            <Button
              style={fontStyle}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <FaAngleDoubleRight />
            </Button>{" "}
          </div>

          {/* <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                 */}
          {/* </div>
          <div className={styles.right}> */}
          {loading ? (
            // Use our custom loading state to show a loading indicator
            <div>Loading...</div>
          ) : (
            <div>
              {pageSize * (pageIndex + 1) + 1 - pageSize}-
              {pageSize * (pageIndex + 1) - pageSize + page.length} of{" "}
              {totalCount}
            </div>
          )}
        </div>

        // </div>
      )}
    </div>
  );
};

export default memo(Table);
