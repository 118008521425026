import { memo, useEffect, useState, useReducer } from 'react';
import styles from './EditUsers.module.scss';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from 'components/BreadCrumbs';

// import { FaChevronCircleLeft } from 'react-icons/fa';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Group from 'components/Group';
import Button from 'components/Button';
import Heading from 'components/Heading';
// import faker from 'faker';
// import { saveItem,getItemById } from 'shared/api/users';
// import { fetchRoles  } from 'shared/api/roles';
// import { fetchItems as fetchCompany } from 'shared/api/company';
// import { copyAttr } from 'shared/utils/common';
import basicReducer from 'reducers/basicReducer';
import api from 'api';
import { useAuth } from 'components/ProvideAuth/hook';
import Switch from 'components/Switch';
import { Tooltip } from '@mui/material';
import { FiInfo } from 'react-icons/fi';
import { toast } from 'react-toastify';

const EditPerson = props => {
    const auth = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    // const [isLoading,setLoading]:[any,any] = useState(false);
    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    // const [companies,setCompanies] = useState([]);
    const [form, setForm]: [any, any] = useReducer(basicReducer, {
        uuid: '',
        first_name: '',
        last_name: '',
        email: '',
        contact: '',
        location: [],
        company_id: auth.company.id,
        create_as_contact: true,
        role_id: null,
        // password:''
    });

    useEffect(() => {
        api.roles.listRoles({ companyId: auth?.company?.id }).then?.(roles => {
            // console.log('roles ->',roles);
            if (roles?.items?.length > 0) {
                setRoles(roles?.items?.map?.(role => ({ value: role?.id, label: role?.title })));
            }
        });
        api.locations.listLocations({ companyId: auth?.company?.id, getAll:true }).then?.(locations => {
            // console.log('locations ->',locations);
            if (locations?.length > 0) {
                let locationsToUpdate = locations?.map?.(location => ({ value: location?.id, label: location?.title }));
                locationsToUpdate.sort((a, b) =>
                    a.label > b.label ? 1 : -1
                )

                locationsToUpdate.unshift({ value: 'all', label: 'All' });
                setLocations(locationsToUpdate);
            }
        });
    }, []);

    useEffect(() => {
        if (params.id) {
            api.users.getCompanyUser({ companyId: auth?.company?.id, userId: params?.id })?.then?.(userDetails => {
                // console.log('userDetails->',userDetails);
                setForm({
                    uuid: userDetails?.uuid,
                    first_name: userDetails?.first_name,
                    last_name: userDetails?.last_name,
                    email: userDetails?.email,
                    role_id: userDetails?.role_id,
                    contact: userDetails?.contact,
                    location: userDetails?.userToLocations?.map?.(ul => ul?.location_id)
                })
            });
        }
    }, [params.id]);

    const setUserLocations = (v: Array<{ value, label }>) => {
        let hasAll = v?.find?.(l => l?.value === 'all');
        if (hasAll) {
            setForm({ location: locations?.filter?.(l => l?.value !== 'all')?.map?.(i => i?.value) });
        } else {
            setForm({ location: v?.map?.(i => i?.value) });
        }
    }

    const createOrUpdate = () => {
        setLoading(true);
        let _toSave = { ...form };
        if (typeof _toSave?.uuid !== 'undefined' && !_toSave?.uuid) {
            delete _toSave?.uuid;
        }

        if (_toSave?.uuid) {
            api.users.updateCompanyUser(_toSave).then(out => {
                // console.log('out -> ', out);
                toast.success("User successfully updated")
                setLoading(false);
                navigate('/admin/users');
            });

        } else {
            api.users.registerCompanyUser(_toSave).then(out => {
                // console.log('out -> ', out);
                toast.success("User successfully created")
                setLoading(false);
                navigate('/admin/users');
            });

        }
    }


    return (
        <div className={styles.root}>
            <Breadcrumb list={[
                {
                    name: 'Users',
                    url: 'admin/users'
                },
                {
                    name: `${params?.id ? 'Edit' : 'Add'} User`
                },
            ]} />
            <Heading>{params?.id ? 'Edit' : 'Add'} User</Heading>
            <Group label="" vertical={false}>
                <Group label="First Name" style={{ marginRight: 10 }}>
                    <Input value={form?.first_name ?? ''} onChange={first_name => setForm({ first_name })} />
                </Group>
                <Group label="Last Name" style={{ marginRight: 10 }}>
                    <Input value={form?.last_name ?? ''} onChange={last_name => setForm({ last_name })} />
                </Group>
            </Group>
            <Group label="Role">
                <Select value={form?.role_id} options={roles} onChange={v => setForm({ role_id: v?.value })} />
            </Group>
            <Group label="Email">
                <Input value={form?.email ?? ''} onChange={email => setForm({ email })} />
            </Group>
            <Group label="Phone">
                <Input value={form?.contact ?? ''} onChange={contact => setForm({ contact })} />
            </Group>
            <Group label="Locations" style={{display: "flex", alignItems: "center", gap: "5px"}}>
                <Tooltip
                    title=" This user will only be able to notify contacts at the location(s) selected below."
                    placement="top"
                >
                    <div style={{marginTop :"5px", cursor: "pointer" }}>
                        <FiInfo size={15}></FiInfo>
                    </div>
                </Tooltip>
            </Group>
            <Group style={{marginTop : "-5px"}}>
                <Select value={form?.location} isMultiple={true} options={locations} onChange={setUserLocations} />
            </Group>
            {/* {!params.id && (
                <Group label="Also add as a Contact">
                    <Switch checked={form?.create_as_contact} onChange={create_as_contact => setForm({ create_as_contact })} />
                </Group>
            )} */}
            {/* {!params?.id && (
                <Group label="Password">
                    ** This will be auto generated **
                    <Input value={form?.password ?? ''} autoComplete={false} type="password" onChange={password => setForm({...form,password})} />
                </Group>

            )} */}
            {/* <Group label="Password">
                <Input value={form?.password ?? ''} autoComplete="off" type="password" onChange={password => setForm({password})} />
            </Group>
             */}


            <Group>
                <Button view="cancel" onClick={() => navigate('/admin/users')}>Cancel</Button>
                <Button disabled={isLoading} onClick={createOrUpdate} style={{ marginLeft: 10 }}>{params?.id ? 'Update' : 'Create'}</Button>
            </Group>
        </div>
    );

};

export default memo(EditPerson);