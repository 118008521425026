import Button from 'components/Button';
import Modal from 'components/Modal';
import styles from "./EditLocation.module.scss"

const Preview = ({ name= "", setFileModal , fileModal , handleFile, previewUrl , fileType ,  onSavePreview , setFileNameModal}) => {
    return (
        <Modal isOpen={fileModal} onClose={() =>  setFileModal(false)}>
            <h3>Attachments</h3>
            {previewUrl && <input className={styles.inputFile} value={name || ""} onChange={(e) => setFileNameModal(e.target.value)} placeholder={"Enter File Name"}/>}
            <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                {previewUrl && (
                    <>
                        {fileType?.includes('image') ? (
                            <img src={previewUrl} alt="File preview" style={{ height: "300px" }} />
                        ) : fileType === 'application/pdf' ? (
                            <embed src={previewUrl} width="100%" height="375" type="application/pdf" />
                        ) : fileType === 'text/csv' ? (
                            <p style={{ width: '100%' }}>{previewUrl}</p>
                        ) : ''
                        }
                    </>
                )}
            </div>
            <div className={styles.spaceBetween}>
                <label htmlFor="file-input" style={{ backgroundColor: " #007bff", color: "white", padding: "10px 20px", borderRadius: "10px", cursor: "pointer", fontSize: "18px" }}>
                    Choose File
                    <input
                        id="file-input"
                        type="file"
                        onChange={handleFile}
                        style={{ display: "none" }}
                        accept="application/pdf,image/*" 
                    />
                </label>
                
                <Button style={{ backgroundColor: " #007bff", color: "white", padding: "10px 20px", borderRadius: "10px", cursor: "pointer", fontSize: "18px"}} onClick={onSavePreview}>Save</Button>
            </div>    
        </Modal>
    )
}

export default Preview