import {memo} from 'react';
import styles from './Heading.module.scss';
import cx from 'classnames';

const Heading = ({children,size = 1,spacing = 'normal'}) => {

    return (
        <div className={cx(styles.root,styles[`size${size}`],styles[`spacing-${spacing}`])}>
            {children}
        </div>
    );

};

export default memo(Heading);