import Button from 'components/Button';
import Input from 'components/Form/Input';
import Group from 'components/Group';
import Modal from 'components/Modal';
import {memo, useState} from 'react';
import styles from './TwoFactorPinRequiredModal.module.scss';

const TwoFactorPinRequiredModal = ({onClose,onVerify}) => {
    const [pin,setPin] = useState('');

    const verfiy = () => {
        // console.log(pin);
        onVerify(pin);
    }

    return (
        <Modal isOpen={true} onClose={onClose}>
            <div className={styles.root}>
                <h2>Two-factor authentication</h2>
                <Group label={`Open your authentication app and enter the code below`}>
                    <Input placeholder='Enter 6 digit code' type="number" value={pin} onChange={pin => setPin(pin)} />
                </Group>
                <Group>
                    <Button disabled={pin?.length !== 6} onClick={verfiy}>Verify</Button>
                </Group>
            </div>
        </Modal>
    );

};

export default memo(TwoFactorPinRequiredModal);