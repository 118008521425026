import React from "react";
import styles from './Table.module.scss';

export const Filter = ({ column }) => {
    return (
        <div style={{ marginTop: 5, height: column.hideFilter && '2.6em' }}>
            {column.canFilter && column.render("Filter")}
        </div>
    )
}

export const DefaultColumnFilter = ({
    column,
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}) => {
    // console.log('column-> ',column);
    let inputType = 'text';
    switch (column?.type) {
        case 'date':
            inputType = 'date';
            break;
    }
    const onResetField = (accessor) => {
        document.getElementById(accessor).value = '';
        setFilter('');
    }
    return (
        <span style={{ display: 'flex' }}>
            <input
                id={column?.id}
                className={styles.search_input}
                type={inputType}
                value={filterValue || ""}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search...`}
                disabled={column.disabledField}
            />
            {inputType === 'date' && <div className={styles.clear_icon} onClick={() => onResetField(column?.id)}>✖</div>}
        </span>
    )
}