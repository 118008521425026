import { memo, useEffect, useState } from "react";
import styles from "./PollEvents.module.scss";
import { BreadCrumbs } from "components/BreadCrumbs";
import { CSVLink, CSVDownload } from "react-csv";
import { ApexOptions } from "apexcharts";

import Button from "components/Button";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import Card from "components/Card";
import Chart from "react-apexcharts";
import { Grid, Menu, MenuItem, Box, Typography, Chip } from "@mui/material";
import SearchBar from "components/SearchBar";
import message from "assets/message.png";
import options from "assets/options.png";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import AllClearModal from "./AllClearModal";
import Select from "components/Form/Select";

const PollEvents = (props) => {
  const auth = useAuth();
  const params = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const user = useAuth().user;
  const navigate = useNavigate();

  const [percentage, setPercentage] = useState(0);
  const [contactsAwaiting, setContactsAwaiting] = useState([]);
  const [respondedContacts, setRespondedContacts] = useState(0);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [csvContacts, setCsvContacts] = useState([]);
  const [labelColorMap, setLabelColorMap] = useState({});

  const [totalDurations, setTotalDurations] = useState("");
  const [timeRemaining, setTimeRemaining] = useState("");
  const [stopInterval, setStopInterval] = useState(false);
  const [eventToContactsFilter, setEventToContactsFilter] = useState([]);
  const [selectContactsOption, setSelectContactsOption] =
    useState("choose filter");
  const [optionList, setOptionList] = useState(["Choose Filter"]);
  const [defaultSelectOption, setDefaultSelectOption] =
    useState("Choose Filter");

  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Contact", key: "contact" },
    { label: "Status", key: "status" },
    { label: "Date / Time", key: "created_at" },
    { label: "Locations", key: "locations" },
  ];
  const parseDatetime = (datetime: string) => {
    try {
      const dt = new Date(datetime);
      if (dt.toString() !== "Invalid Date") {
        return dt;
      }
      return null;
    } catch (err: any) {
      console.error(err.message);
      return null;
    }
  };
  const chartOptions: ApexOptions = {
    labels: [],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const [pieChart, setPieChart] = useState({
    series: [],
    options: chartOptions,
  });

  useEffect(() => {
    api.locations
      .listLocations({ companyId: auth?.company?.id })
      .then((locations) => {
        if (locations?.items) {
          setLocations(locations?.items);
        }
      });
  }, [params?.id]);

  const afterContactStatusUpdate = () => {
    if (params?.id) {
      api.events
        .getEvent({ id: params?.id, companyId: auth?.company?.id })
        .then((eventDetails) => {
          if (eventDetails) {
            setCreatedAt(
              parseDatetime(
                eventDetails?.event_datetime || eventDetails?.created_at
              )
            );
            const contactsAwaiting =
              eventDetails?.eventToContacts?.filter((etc) => !etc?.status) ||
              [];
            const respondedContacts =
              eventDetails?.eventToContacts?.length - contactsAwaiting.length;
            const percentage = Math.round(
              (respondedContacts / eventDetails?.eventToContacts?.length) * 100
            );

            setContactsAwaiting(contactsAwaiting);
            setRespondedContacts(respondedContacts);
            setPercentage(percentage);
            setContacts([...eventDetails?.eventToContacts]);
            setEventDetails({ ...eventDetails });
            setEventToContactsFilter(eventDetails?.eventToContacts);
            const values =
              eventDetails?.options?.map((o, i) => {
                // const color = getEventOptionColor(o.title, i);
                return {
                  id: o.id,
                  name: o.title,
                  color: o.color,
                  count: eventDetails?.eventToContacts.reduce(
                    (prev, curr) =>
                      curr.status?.id === o.id ? prev + 1 : prev,
                    0
                  ),
                };
              }) || [];
            values.push({
              id: -1,
              name: "Awaiting Response",
              count: contactsAwaiting.length,
              color: "#0000FF",
            });
            const labels = values?.map((op) => op?.name);
            const series = values?.map((op) => op?.count);
            const colors = values?.map((op) => op?.color);

            setPieChart({
              ...pieChart,
              series,
              options: {
                ...pieChart.options,
                labels,
                colors,
                fill: {
                  colors,
                },
              },
            });
          }
        });
    }
  };

  useEffect(() => {
    if (params?.id) {
      api.events
        .getEvent({ id: params?.id, companyId: auth?.company?.id })
        .then((eventDetails) => {
          if (eventDetails) {
            setCreatedAt(
              parseDatetime(
                eventDetails?.event_datetime || eventDetails?.created_at
              )
            );
            const contactsAwaiting =
              eventDetails?.eventToContacts?.filter((etc) => !etc?.status) ||
              [];
            const respondedContacts =
              eventDetails?.eventToContacts?.length - contactsAwaiting.length;
            const percentage = Math.round(
              (respondedContacts / eventDetails?.eventToContacts?.length) * 100
            );

            setContactsAwaiting(contactsAwaiting);
            setRespondedContacts(respondedContacts);
            setPercentage(percentage);
            setContacts([...eventDetails?.eventToContacts]);
            setEventDetails({ ...eventDetails });
            setEventToContactsFilter(eventDetails?.eventToContacts);
            const labelColorMapping = {};
            const values =
              eventDetails?.options?.map((o, i) => {
                // const color = getEventOptionColor(o.title, i);
                labelColorMapping[o.title] = o.color;
                return {
                  id: o.id,
                  name: o.title,
                  color: o.color,
                  count: eventDetails?.eventToContacts.reduce(
                    (prev, curr) =>
                      curr.status?.id === o.id ? prev + 1 : prev,
                    0
                  ),
                };
              }) || [];
            setLabelColorMap(labelColorMapping);
            values.push({
              id: -1,
              name: "Awaiting Response",
              count: contactsAwaiting.length,
              color: "#0000FF",
            });
            const labels = values?.map((op) => op?.name);
            const series = values?.map((op) => op?.count);
            const colors = values?.map((op) => op?.color);

            setPieChart({
              ...pieChart,
              series,
              options: {
                ...pieChart.options,
                labels,
                colors,
                fill: {
                  colors,
                },
              },
            });
          }
        });
    }
  }, [params?.id]);

  useEffect(() => {
    let locations_ids = [];
    if (
      eventDetails?.eventToLocations &&
      Array.isArray(eventDetails?.eventToLocations)
    ) {
      locations_ids = eventDetails?.eventToLocations?.map?.(
        (i) => i?.location?.id
      );
    }

    const filteredLocations = locations.filter((loc) =>
      locations_ids.includes(loc.id)
    );

    const csvData = eventDetails?.eventToContacts.map((etc) => {
      return {
        firstname: etc?.user?.first_name,
        lastname: etc?.user?.last_name,
        email: etc?.user?.email,
        contact: etc?.user?.contact,
        status: etc?.status ? etc?.status?.title : "Awaiting Response",
      };
    });

    if (csvData?.length) {
      csvData[0] = {
        ...csvData[0],
        created_at: eventDetails?.event_datetime || eventDetails?.created_at,
        locations: filteredLocations?.map((fl) => fl?.title).toString(),
      };
      setCsvContacts([...csvData]);
    }
    setFilteredLocations([...filteredLocations]);
  }, [locations, eventDetails]);

  const AvatarInitials = ({ fname, lname }) => {
    return (
      <div className={styles.circle}>
        <span className={styles.initials}>
          {(
            fname?.toString().charAt(0) + lname?.toString().charAt(0)
          ).toUpperCase()}
        </span>
      </div>
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [isModalOpen, handleModal] = useState(false);
  const handleModalMethod = () => {
    handleModal(!isModalOpen);
  };

  const handleSearch = ({ value }) => {
    if (!value) {
      setEventDetails({ ...eventDetails, eventToContacts: [...contacts] });
    } else {
      setEventDetails({
        ...eventDetails,
        eventToContacts: eventDetails?.eventToContacts?.filter(
          (item) =>
            item?.user?.first_name
              ?.toLowerCase()
              .includes(value.toLowerCase()) ||
            item?.user?.last_name?.toLowerCase().includes(value.toLowerCase())
        ),
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentContact, setCurrentContact] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusUpdate = ({ optionId }) => {
    api.events
      .updateEventContactStatus({
        companyId: auth?.company?.id,
        eventId: eventDetails?.id,
        contactId: currentContact?.user?.uuid,
        payload: { optionId },
      })
      .then((statusResponse) => {
        afterContactStatusUpdate();
        handleClose();
      });
  };

  const timer = (create_start_date: any = "", event_end_date: any = "") => {
    let diffInMilliseconds;
    let startDate: any = new Date(create_start_date)?.getTime();
    let now: any = new Date()?.toISOString();
    now = new Date(now)?.getTime();
    if (!event_end_date) {
      diffInMilliseconds = Math.abs(startDate - now);
    } else {
      let end_date_time = new Date(event_end_date)?.getTime();
      diffInMilliseconds = Math.abs(end_date_time - startDate);
    }
    let diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    let diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    let diffInMinutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);
    let diffInSeconds = Math.floor((diffInMilliseconds / 1000) % 60);
    let diffString = `${diffInHours}h ${diffInMinutes}m ${diffInSeconds}s`;

    return diffString?.includes("NaN") ? timeRemaining : diffString;
  };

  let intervalId;
  const handleTimeInterval = (start_date_time: any) => {
    intervalId = setInterval(() => {
      if (eventDetails?.event_end_date) {
        setStopInterval(!stopInterval);
        const timeResult = timer(start_date_time);
        setTimeRemaining(timeResult);
      } else {
        const timeResult = timer(start_date_time);
        setTimeRemaining(timeResult);
      }
    }, 1000);
    return intervalId;
  };

  useEffect(() => {
    let intervalId, totalDuration;
    if (eventDetails) {
      if (eventDetails?.event_end_date) {
        totalDuration = timer(
          eventDetails?.event_datetime || eventDetails?.created_at,
          eventDetails?.event_end_date
        );
        setTotalDurations(totalDuration);
        setEndAt(parseDatetime(eventDetails?.event_end_date));
      } else {
        intervalId = handleTimeInterval(
          eventDetails?.event_datetime || eventDetails?.created_at
        );
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [eventDetails]);
  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [stopInterval]);

  useEffect(() => {
    eventDetails?.options?.length > 0 &&
      eventDetails?.options?.map((opt) => {
        setOptionList((pre) => [...pre, opt?.title]);
      });
  }, [eventDetails]);
  const filterHandle = (option) => {
    const filterOptions = eventDetails?.eventToContacts.filter(({ status }) => {
      return status?.title?.includes(option);
    });
    setSelectContactsOption(option);
    if (option?.includes("Choose Filter")) {
      setEventToContactsFilter(eventDetails?.eventToContacts);
    } else {
      setEventToContactsFilter(filterOptions);
    }
  };
  return (
    <div className={styles.root}>
      <BreadCrumbs
        list={[
          {
            name: "Event Tracking",
            url: "events",
          },
          {
            name: "Event Tracking",
          },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >
        <h2 className={styles.pageHeading}>Event Tracking</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            component="p"
            sx={{
              fontSize: "15px",
              display: "grid",
              placeItems: "center",
              border: "1px solid",
              borderColor:
                eventDetails?.status === "Open" ? " #De122b" : "black",
              borderRadius: "5px",
              background: "transparent",
              marginTop: eventDetails?.status === "Open" ? "5%" : "7%",
              height: "50%",
              padding: "3px 5px",
              fontWeight: "bold",
              color: eventDetails?.status === "Open" ? " #De122b" : "black",
            }}
          >
            <span>
              Status: {eventDetails?.status === "Open" ? "Active" : "Closed"}
            </span>
          </Typography>
          {eventDetails?.status === "Open" && (
            <div className={styles.btnWrapper}>
              <Button
                onClick={() => handleModalMethod()}
                style={{
                  marginLeft: 10,
                  width: "100%",
                }}
                contentDirection={"center"}
              >
                {"Send All Clear Alert"}
              </Button>
            </div>
          )}
        </div>
      </Box>
      <div>
        <div className={styles.root}>
          <Grid container columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
            <Grid item xs={12} sm={12} md={6}>
              <Card
                style={{
                  width: "100%",
                  minHeight: "67vh",
                  display: "block",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    justifyContent: " space-between",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                  }}
                >
                  <SearchBar onSearch={(e) => handleSearch(e)} pageSize={0} />
                  <Box sx={{ mt: { xs: 2, sm: 2, md: 0 } }}>
                    {eventDetails?.options?.length > 0 && (
                      <Select
                        isMultiple={false}
                        placeholder={defaultSelectOption}
                        value={selectContactsOption}
                        options={optionList}
                        onChange={({ value }) => filterHandle(value)}
                      />
                    )}
                  </Box>
                </Box>
                <div style={{ margin: "10px 0px 0px 0px", textAlign: "right" }}>
                  <CSVLink
                    className={styles.csvBtn}
                    data={csvContacts}
                    headers={headers}
                  >
                    Download Results
                  </CSVLink>
                </div>
                <div style={{ minHeight: "45vh", overflowY: "scroll" }}>
                  {eventToContactsFilter?.length > 0 ? (
                    eventToContactsFilter?.map((contact, ind) => {
                      return (
                        <Box
                          className={styles.eventTrackingCard}
                          style={{ marginRight: "0px" }}
                          key={`div-${ind}`}
                          sx={{
                            padding: {
                              xs: "4px 6px",
                              sm: "4px 6px",
                              md: "10px 20px",
                            },
                          }}
                        >
                          <Grid
                            container
                            columnSpacing={{ xs: 2, sm: 2, md: 4 }}
                          >
                            <Grid item xs={10} sm={10} md={10}>
                              {/* <img
                            style={{ height: 50, borderRadius: "30px" }}
                            src={avatar}
                            alt="Emeva"
                          /> */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                <AvatarInitials
                                  fname={contact?.user?.first_name?.toString()}
                                  lname={contact?.user?.last_name?.toString()}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <h4 style={{ margin: "3px 0px" }}>
                                    {capitalizeFirstLetter(
                                      contact?.user?.first_name?.toString()
                                    )}
                                    {capitalizeFirstLetter(
                                      contact?.user?.last_name?.toString()
                                    )}
                                  </h4>
                                  <label style={{ fontSize: "14px" }}>
                                    {contact?.user?.email}
                                  </label>

                                  {contact?.status && (
                                    <>
                                      <p
                                        style={{
                                          margin: "5px 0 0 0",
                                          maxWidth: "max-content",
                                          fontSize: "12px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {contact?.status ? (
                                          <Chip
                                            style={{
                                              color: "#fff",
                                              backgroundColor:
                                                labelColorMap[
                                                contact?.status?.title
                                                ] ?? "black",
                                            }}
                                            label={contact?.status?.title}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                      <label
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {contact?.status_updated_by
                                          ? ` (${contact?.status_updated_by?.first_name} ${contact?.status_updated_by?.last_name})`
                                          : ""}
                                      </label>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2}>
                              <div className={styles.iconContainer}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    marginRight: "0px",
                                  }}
                                >
                                  <img
                                    style={{ height: 24, cursor: "pointer" }}
                                    onClick={() =>
                                      navigate(
                                        `${user.uuid}/${contact.user.uuid}`
                                      )
                                    }
                                    src={message}
                                    alt="message"
                                  />
                                  {/* {!contact?.status && ( */}
                                  <>
                                    <button
                                      id="basic-button"
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={(event) => {
                                        handleClick(event);
                                        setCurrentContact(contact);
                                      }}
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: 24,
                                          cursor: "pointer",
                                        }}
                                        src={options}
                                        alt="options"
                                      />
                                    </button>

                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      {eventDetails?.options?.map((op, ind) => {
                                        return (
                                          <MenuItem
                                            key={`ind-${contact?.user?.uuid}-${ind}`}
                                            onClick={() =>
                                              handleStatusUpdate({
                                                optionId: op.id,
                                              })
                                            }
                                          >
                                            {op?.title}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>
                                  </>
                                  {/* )} */}

                                  {/* </div> */}
                                </Box>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      Not Found
                    </p>
                  )}
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card
                style={{
                  width: "100%",
                  minHeight: "67vh",
                }}
              >
                <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  <Grid xs={12} sm={6} md={7}>
                    <div style={{ paddingLeft: "4%" }}>
                      <h5
                        style={{
                          fontSize: "16px",
                          color: "#034CCD",
                          margin: "5px",
                        }}
                      >
                        {eventDetails?.name}
                      </h5>
                      <h5
                        style={{
                          fontSize: "14px",
                          margin: "0px",
                          marginBottom: "10px",
                          fontWeight: "normal",
                          marginLeft: "6px",
                        }}
                      >
                        {eventDetails?.message}
                      </h5>
                    </div>
                    <Box sx={{ width: "100%" }}>
                      <Chart
                        options={pieChart.options}
                        series={pieChart.series}
                        type="pie"
                        width="240px"
                        height="400px"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <div className={styles.chartInfo}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        Duration:
                        <span style={{ marginLeft: "5px" }}>
                          {totalDurations ? totalDurations : timeRemaining}
                        </span>
                      </label>
                      <br />
                      <label style={{ fontSize: "14px", marginTop: "-2.5%" }}>
                        <span style={{ fontWeight: 700 }}>Start: </span>{" "}
                        {moment(createdAt).format("M/D/YY")}{" "}
                        {moment(createdAt).format("hh:mm A")}
                      </label>
                      <br />

                      <label style={{ fontSize: "14px", marginTop: "-3.5%" }}>
                        <span style={{ fontWeight: 700 }}>End:</span>
                        <span
                          style={{ paddingRight: "5px", marginLeft: "7px" }}
                        >
                          {" "}
                          {endAt ? moment(endAt).format("M/D/YY") : "--"}
                        </span>
                        <span>
                          {endAt ? moment(endAt).format("hh:mm A") : "--"}
                        </span>
                      </label>
                      <br />
                      <h4
                        style={{
                          color: "#2b2b2b",
                          fontSize: "16px",
                          marginTop: "7px",
                          marginBottom: "5px",
                        }}
                      >
                        {percentage}%
                      </h4>
                      <label style={{ fontSize: "14px" }}>
                        {respondedContacts}/
                        {eventDetails?.eventToContacts?.length}
                      </label>

                      <div style={{ marginTop: "-2%" }}>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          Location(s):
                        </p>
                        {filteredLocations?.length
                          ? filteredLocations.map((etl, i) => (
                            <label className={styles.locationLabel} key={i}>
                              <span>{etl.title}</span>
                            </label>
                          ))
                          : ""}
                      </div>
                      <div style={{ marginTop: "-2%" }}>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <b>All Clear Message:</b>
                          {eventDetails?.clear_event_message}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <AllClearModal
        onClose={() => {
          handleModal(!isModalOpen);
        }}
        isOpen={isModalOpen}
        eventId={eventDetails?.id}
      ></AllClearModal>
    </div>
  );
};

export default memo(PollEvents);
