import { memo, useEffect, useState } from "react";
// import styles from './ImportCsvModal.module.scss';
import Modal from "components/Modal";
import Button from "components/Button";
import Table from "components/Table";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";

const LocationContactsModal = ({
  onClose,
  isOpen,
  locationIds,
  allSelected,
}) => {

  const auth = useAuth();
  const [contactsListData, setContactListData] = useState({
    items: [],
    meta: { totalItems: 0, totalPages: 0 },
    links: {},
  });
  const [page, setPage] = useState(1);
  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
  ];

  useEffect(() => {
    if (auth?.company?.id && locationIds.length > 0) {
      api.contacts
        .listContacts({
          companyId: auth?.company?.id,
          location: allSelected ? "all" : locationIds.join(","),
          page
        })
        .then?.((contacts) => {
          setContactListData({
            ...contacts,
          });
        });
    }
  }, [locationIds, page]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} maxWidth='50vw' title="Contacts List">
      <Table
        columns={columns}
        data={contactsListData?.items}
        pageCount={contactsListData?.meta?.totalPages}
        fetchData={(data) => setPage(data.pageIndex + 1)}
        parentPageSize={() => { }}
        totalCount={contactsListData?.meta?.totalItems}
        showFilter={false}
        pageChangeVisible={false}
      />
    </Modal>
  );
};

export default memo(LocationContactsModal);
