import { memo, useEffect, useReducer, useState } from 'react';
import styles from './EditCompany.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'components/BreadCrumbs';

import { toast } from 'react-toastify';
import Input from 'components/Form/Input';
import Group from 'components/Group';
import Button from 'components/Button';
import Heading from 'components/Heading';
import basicReducer from 'reducers/basicReducer';
import api from 'api';
import Card from 'components/Card';
import { useAuth } from 'components/ProvideAuth/hook';

const EditCompany = props => {
    const params = useParams();
    const navigate = useNavigate();
    const user = useAuth().user
    const [form, setForm] = useReducer(basicReducer, {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        userType: '',
        companyId: '',
        companyName: '',
        size: 0,
        contact: '',
        isSuccessfulRegistration: false,
        emailVerifiedAt : null
    })

    const [ twilioCredentials ,  setTwilioCredentials] = useReducer(basicReducer, {
        twilioAccountSID: '',
        twilioAuthToken: '',
        twilioPhoneNumber: '',
    })

    const [ paymentPlan ,  setPaymentPlan] = useReducer(basicReducer, {
        title: '',
        discription: '',
        features: [],
        daysRemaining: 0
    })

    const save = async () => {
       
        let _toSave =  {...form , id :params?.id};
        const saveItem = api.users.updateCompanyDetails;
        saveItem(_toSave).then(() => {
            toast.success("Successfully Updated the status")
            navigate(`/companies/`);
        }).catch(()=> toast.error("Something went wrong") );
    }

    const saveTwilio = async () => {
        if (!twilioCredentials.twilioAccountSID || !twilioCredentials.twilioAuthToken 
            || !twilioCredentials.twilioPhoneNumber) {
            
            toast.error("Fields can not be empty")
            return; 
        }
        let _toSave =  {...twilioCredentials , id :params?.id};
        const saveCredentials = api.users.updateTwilioCredentials;
        saveCredentials(_toSave).then( ()=> {
            toast.success("Successfully Saved the Twilio Credentials")
        }).catch(()=> 
            toast.error("Something went wrong")
        )

    }

    const isSuccessfulRegistrationApproved = async (currentValue) => {
        await setForm ({isSuccessfulRegistration : !currentValue})

        let _toSave =  { isSuccessfulRegistration: !currentValue, id :params?.id};
        const saveCredentials = api.users.updateSuccessfullyRegister;
        saveCredentials(_toSave).then( ()=> {
            if (!currentValue)
                toast.success("User registration approved")
            else
                toast.success("User registration disapproved")
        }).catch(()=> 
            toast.error("Something went wrong")
        )
    }
    useEffect(() => {
        if (params?.id) {
            api.users.listCompanyDetailsByUserId({ id: params?.id }).then( companyDetails => {
                if (companyDetails) {
                    setForm({
                        id: companyDetails?.user?.id,
                        firstName: `${companyDetails?.user?.firstName}`,
                        lastName:  `${companyDetails?.user?.lastName}`,
                        email: companyDetails?.user?.email,
                        userType: companyDetails?.user?.userType,
                        companyId: companyDetails?.company?.id,
                        companyName: companyDetails?.company?.name,
                        size: companyDetails?.company?.numberOfContacts,
                        contact: companyDetails?.company?.contact,
                        isSuccessfulRegistration: companyDetails?.user?.isSuccessfulRegistration,
                        emailVerifiedAt: companyDetails?.user?.emailVerifiedAt
                    });
                    setTwilioCredentials({
                        twilioAccountSID: companyDetails?.company?.twilioAccountSID,
                        twilioAuthToken: companyDetails?.company?.twilioAuthToken,
                        twilioPhoneNumber: companyDetails?.company?.twilioPhoneNumber,
                    })
                }
            });
            api.users.getCompanyPaymentPlan({id : params?.id}).then ( paymentPlan => {
                if (paymentPlan) {
                    setPaymentPlan({
                        title : paymentPlan?.title,
                        description : paymentPlan?.description,
                        features : paymentPlan?.features,
                    })
                }
            })
        }
    }, [params?.id]);

    useEffect(()=> {
        if (form?.emailVerifiedAt)
        {
            const timeDiff = Math.abs(new Date(form?.emailVerifiedAt).getTime() - new Date().getTime());
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
            setPaymentPlan( { daysRemaining : 7-diffDays })
        }
    } , [form?.emailVerifiedAt])

    return (
        <>
        <div className={styles.flex}>
            <Breadcrumb list={[
                {
                    name: 'Companies',
                    url: '../companies'
                },
                {
                    name: `${params?.id ? 'Edit' : 'Add'} Company`
                },
            ]} />
            <div className={styles.buttonsStyle}>
                <Button onClick={() => navigate(`../company/edit/${params?.id}/credits`) }>Credits</Button>
                {/* <Button onClick={() => isSuccessfulRegistrationApproved(form?.isSuccessfulRegistration)} requireConfirmation={true} confirmationMessage='Are you sure you want to proceed?' style={{backgroundColor : form?.isSuccessfulRegistration ? 'red' : ''}}>{form?.isSuccessfulRegistration ? "Disapprove" : "Approve"}</Button> */}
            </div>
        </div>
        <div className={styles.root}>
            <div className={styles.subRoot}>
                <Heading>{params?.id ? 'Edit' : 'Add'} Company</Heading>
                <Card style={{flexDirection: "column"}}>
                    <Group label="First Name" style={{ marginRight: 10 }}>
                        <Input value={form?.firstName} onChange={firstName => setForm({ firstName })} />
                    </Group>
                    <Group label="Last Name" style={{ marginRight: 10 }}>
                        <Input value={form?.lastName} onChange={lastName => setForm({ lastName })} />
                    </Group>
                    <Group label="Email" style={{marginRight : 10}}>
                        <Input value={form?.email} onChange={email => setForm({ email })} />
                    </Group>
                    <Group label="Contact">
                        <Input value={form?.contact} onChange={contact => setForm({ contact })} />
                    </Group>
                    <Group label="Company Name">
                        <Input value={form?.companyName} onChange={companyName => setForm({ companyName })} />
                    </Group>
                    <Group label="Number Of Contacts" >
                        {/* <Select
                            value={form.size}
                            options={[{value:1,label:'10-20'},{value:2,label:'20-40'},{value:3,label:'40-60'},{value:4,label:'60-100'}]}
                            onChange={size => setForm({ size:size?.value })}
                        /> */}
                        <Input type={"number"} value={form?.size} placeholder={"0-9999"} onChange={size => {if (String(size).length < 5) setForm ({size})}}/>
                    </Group>
                    <Group>
                        <Button onClick={() => navigate('/companies')} view="cancel">Cancel</Button>
                        <Button onClick={save} style={{ marginLeft: 10 }}>Save</Button>
                    </Group>
                </Card>
            </div>
            <div className={styles.subRoot} style={{display : "flex" , flexDirection: "column" , gap: "20px"}}>
                <div>
                    <Heading>Payment Plan</Heading>
                    <Card style={{flexDirection: "column" }}>
                        <Group label="Title: " className={styles.flex}>
                            {paymentPlan?.title}{ (paymentPlan?.daysRemaining < 1 && user.is_successful_registration) ? <span style={{color : "#d84856"}}>(expired)</span> : null}
                        </Group>
                        <Group label="Description: " className={styles.flex}>
                            {paymentPlan?.description}
                        </Group>
                        <Group label="Features: " className={styles.flex}>
                            {paymentPlan.features}
                        </Group>
                        <Group label="Days Remaining: " className={styles.flex}>
                            {paymentPlan?.daysRemaining}
                        </Group>
                    </Card>
                </div>
                <div>
                    <Heading>Twilio Credentials</Heading>
                    <Card style={{flexDirection: "column"}}>
                        <Group label="Twilio Account Sid" style={{ marginRight: 10 }}>
                            <Input type='password' marginLeft= "88%" value={twilioCredentials.twilioAccountSID} onChange={ twilioAccountSID => setTwilioCredentials({twilioAccountSID})} />
                        </Group>
                        <Group label="Twilio Auth Token" style={{ marginRight: 10 }}>
                            <Input type='password' marginLeft= "88%" value={twilioCredentials.twilioAuthToken} onChange={ twilioAuthToken => setTwilioCredentials({twilioAuthToken})} />
                        </Group>
                        <Group label="Twilio Phone No." style={{ marginRight: 10 }}>
                            <Input value={twilioCredentials.twilioPhoneNumber} onChange={ twilioPhoneNumber => setTwilioCredentials({twilioPhoneNumber})} />
                        </Group>
                        <Group>
                            <Button onClick={saveTwilio}>Save Credentails</Button>
                        </Group>
                    </Card>
                </div>
            </div>
        </div>
        </>
    );

};

export default memo(EditCompany);