import internalApi from "shared/utils/internalApi"

export const listUsers = ({limit = 10, page = 1,companyId , search=""} : { limit?: number, page?: number, companyId:string , search?: string}) => {
    const query = `limit=${limit}&page=${page}&search=${search}`;
    return internalApi.get({ server: `company/`, path: `${companyId}/users/?${query}` });
}

export const getCompanyUser = ({companyId,userId}) => {
    return internalApi.get({ server: `company/`, path: `${companyId}/users/${userId}` });
}

export const deleteUser = ({companyId,userId}) => {
    return internalApi.delete({ server: `company/`, path: `${companyId}/users/${userId}` });
}

export const registerCompanyUser = ({company_id,first_name,last_name,email,contact,create_as_contact,location,role_id}) => {
    return internalApi.post({ server: `company/`, path: `${company_id}/users/` },{company_id,first_name,last_name,email,contact,create_as_contact,location,role_id});
}

export const updateCompanyUser = ({uuid,company_id,first_name,last_name,email,contact,location,role_id}) => {
    return internalApi.patch({ server: `company/`, path: `${company_id}/users/${uuid}` },{company_id,first_name,last_name,email,contact,location,role_id});
}

export const listAdminUsers = ({companyId}) => {
    return internalApi.get({ server: `superadmin/`, path: `users/${companyId}/` });
}

export const listCompanyDetails = ({options}) => {
    return internalApi.get({ server: `superadmin/`, path: `companies?limit=${options.limit}&page=${options.page}` });
}

export const listCompanyDetailsByUserId = ({id}) => {
    return internalApi.get({ server: `superadmin/`, path: `company/${id}` });
}

export const updateCompanyDetails = ({
    id,
    firstName,
    lastName,
    email,
    userType,
    companyId,
    companyName,
    contact,
    size
}) => {
    return internalApi.patch({ server: `superadmin/`, path: `company/${id}` },{firstName,lastName,email,userType, companyId,companyName,contact, size});
}

export const changeStatusOfUser = ({id}) => {
    return internalApi.patch({ server: `superadmin/`, path: `companyUserStatus/${id}` });
}

export const updateSuccessfullyRegister = ({id , isSuccessfulRegistration}) => {
    return internalApi.patch({ server: `superadmin/`, path: `approveSuccefulRegistration/${id}` } ,{isSuccessfulRegistration});
}

export const updateTwilioCredentials = ({id , twilioAccountSID, twilioAuthToken ,twilioPhoneNumber}) => {
    return internalApi.patch({ server: `superadmin/`, path: `addTwillioCredentials/${id}` } ,{twilioAccountSID, twilioAuthToken ,twilioPhoneNumber});
}

export const getCompanyPaymentPlan = ({id}) => {
    return internalApi.get({ server: `superadmin/`, path: `getCompanyPaymentPlan/${id}` });
}

export const getCompanyCredits = ({id}) => {
    return internalApi.get({ server: `superadmin/`, path: `getCompanyCredits/${id}` });
}

export const addCompanyCredits = ({id , credits}) => {
    return internalApi.patch({ server: `superadmin/`, path: `addCompanyCredits/${id}` },{credits});
}