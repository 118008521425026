import { Divider, Fab, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import api from "api";
import BreadCrumbs from "components/BreadCrumbs";
import { useAuth } from "components/ProvideAuth/hook";
import { useEffect, useRef, useState } from "react";
import { BsSendFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SendSMSRequest } from "types/sms";
import styles from "./PollEvents.module.scss";

const useStyles = makeStyles(() => ({
    table: {
      minWidth: 650,
    },
    chatSection: {
      width: '100%',
      height: '80vh'
    },
    headBG: {
      backgroundColor: '#e0e0e0'
    },
    borderRight500: {
      borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
      height: '65vh',
      overflowY: 'auto',
      color: "white"
    },
    leftSide:  {
      display: "flex",
      justifyContent : "end",
      maxWidth: "100%",
      paddingLeft :  "60%"
    },
    leftSideText  : {
      backgroundColor : "#0E80D7",
      padding : "10px",
      maxWidth : "fit-content",
      borderRadius: "10px",
    },
    rightSide : {
        textAlign : "start",
        wdith: "100%",
    },
    rightSideText : {
        backgroundColor : "grey",
        width: "fit-content",
        padding : "10px",
        borderRadius: "10px"
    },
    selectedChat: {
        cursor : "pointer",
        borderRadius : "10px"
    },
    rightSideTextTime : {
      color: "grey",
      width: "fit-content",
    },
    leftSideTextTime : {
      color: "grey",
      maxWidth : "fit-content",
    }
  }));
const Chat = () => {
  const classes = useStyles();
  const params = useParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [eventContacts , setEventContacts] = useState([]);
  const [smses , setSmses] = useState([]);
  const [newMessage ,  setNewMessage] = useState("");
  const [selectedContact, setSelectedContact] = useState<any>()
  const messageAreaRef = useRef(null);

  const scrollToBottom = () => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    setNewMessage("")
    if (selectedContact){
        const payload : SendSMSRequest = {
            to: `${selectedContact?.contact}`,
            body: newMessage,
            receiver_uuid: selectedContact?.user?.uuid,
            sender_uuid: `${auth.user.uuid}`,
        }
        api.events.sendEventSMS(Number(auth.company.id), Number(params.id) , payload).then((res)=> {
            setTimeout(() => {
                getMessages();
              }, 500);
        }).catch((err) => toast.error(err))
    }
  }

  const getMessages = () => {
    api.events.getEvent({ id: params?.id, companyId: auth?.company?.id }).then((events) => {
        setEventContacts(events?.eventToContacts)
        events?.eventToContacts?.forEach((contact) => {
            if (params?.contactId === contact?.user?.uuid){
              setSelectedContact(contact);
              api.events?.getMessagesOfContact(Number(auth?.company?.id ), Number(params?.id) , params?.userId ,  params?.contactId ).then((result) => {
                setSmses(result)
              })
            }
        })
    })
  }

  const AvatarInitials = ({ fname, lname }) => {
    return (
      <div className={styles.circle}>
        <span className={styles.initials}>
          {(
            fname?.toString().charAt(0) + lname?.toString().charAt(0)
          ).toUpperCase()}
        </span>
      </div>
    );
  };

  const contactChanged = (contact) => {
    navigate(`../events/${params?.id}/poll/${auth.user.uuid}/${contact.user.uuid}`);
  }

  useEffect(() => {
    if (params) {
        getMessages()
    }
  },[params])

  useEffect(()=>{
    scrollToBottom();
  },[smses.length  , smses])

  useEffect(() => {
    const sse = new EventSource(`${process.env.REACT_APP_API_URL}company/${auth?.company?.id}/events/sms/sse`,
      { withCredentials: false });
    const getRealtimeData = (data) => {
      getMessages();
    }
    sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
    sse.onerror = () => {
      sse.close();
    }
    return () => {
      sse.close();
    };
  }, []);
  return (
      <div>
        <BreadCrumbs
          list={[
            {
              name: "Event Tracking",
              url: "events",
            },
            {
              name: "Event Tracking",
              url: `events/${params?.id}/poll`
            },
            {
              name: "Messaging",
            },
          ]}
        />
        <Grid container>
            <Grid item xs={12} >
                <Typography variant="h5" className="header-message" style={{marginBottom : "8px"}}>Messaging</Typography>
            </Grid>
        </Grid>
        <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={3} className={classes.borderRight500}>
                <List>
                    <ListItem>
                        <ListItemText primary={`${selectedContact?.user?.first_name} ${selectedContact?.user?.last_name}`}></ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    {
                      eventContacts?.map((contact ,  index) => {                            
                          return (
                              <ListItem key={index} className={classes.selectedChat} style={{backgroundColor : selectedContact?.user?.uuid === contact?.user?.uuid ? "#8895A1"  : ""}} onClick={() => contactChanged(contact)}>
                                    <ListItemIcon>
                                      <AvatarInitials
                                        fname={contact?.user?.first_name?.toString()}
                                        lname={contact?.user?.last_name?.toString()}
                                      />
                                  </ListItemIcon>
                                  <ListItemText primary={`${contact.user.first_name} ${contact.user.last_name}`}></ListItemText>
                              </ListItem>
                          )
                      })
                    }
                </List>
            </Grid>
            <Grid item xs={9}>
                <List className={classes.messageArea} ref={messageAreaRef}>
                {
                  smses?.map((sms , index) => {
                      const dateString = sms?.created_at;
                      const date = new Date(dateString);
                      const timeString = date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
                      return (
                          <ListItem key={index}>
                              <Grid container>
                                  <Grid item xs={12}>
                                    <div className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSide : classes.leftSide}>
                                          <div style={{display: "flex" , gap: "5px" ,width: "fit-content"}}>
                                          {sms?.receiver?.uuid===auth?.user?.uuid && 
                                          <div style={{width : "50px" , display :"flex" , alignItems  :"end"}}>
                                            <AvatarInitials
                                            fname={sms?.sender?.first_name?.toString()}
                                            lname={sms?.sender?.last_name?.toString()}
                                            />
                                          </div>
                                          }
                                          <ListItemText className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSideText : classes.leftSideText}>{sms?.body}</ListItemText>
                                      </div>
                                        </div>
                                        <div className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSide : classes.leftSide}>
                                          <ListItemText className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSideTextTime : classes.leftSideTextTime}>{timeString}</ListItemText>
                                      </div>
                                  </Grid>
                              </Grid>
                          </ListItem>
                      )
                  })
                }
                </List>
                <Divider />
                <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                        <TextField id="outlined-basic-email" label="Type Something" fullWidth value={newMessage} onChange={(val) =>  setNewMessage(val.target.value)}/>
                    </Grid>
                    <Grid item xs={1} style={{paddingLeft: "10px"}}>
                        <Fab color="primary" aria-label="add" onClick={() => sendMessage()}><BsSendFill /></Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </div>
  );
}

export default Chat;