import { Box, Tooltip, Typography, Slider, Input, Grid,Container } from "@mui/material";
import { useState, useEffect } from "react";
import { MdInfo } from "react-icons/md";
import styles from './PaymentPlan.module.scss';
import Button from 'components/Button';
import storage from "shared/utils/storage";
const RightContent = ({setPrice,handlePaymentPlanSelection,selectedCard,setCredits,login}) => {
    const BASE_PRICE_100_PLUS = 3000;
    const BASE_PRICE_LESSTHAN_100 = 1500;
    const company = storage.get('company');
    const [value, setValue] = useState<number | string | Array<number | string>>(
        company ? company?.number_of_contacts:100,
    );

    const [sms, setSMS] = useState(2400);
    const [suggestedCredits, setSuggestedCredits] = useState(0);
    const [basePrice, setBasePrice] = useState(0);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
    };

    useEffect(()=> {
        setPrice(suggestedCredits + basePrice )
    },[suggestedCredits ,  basePrice])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value === '' ? '' : Number(event.target.value);
        setValue(newValue);
    };

    const handleBlur = () => {
        if (Number(value) < 0) {
            setValue(0);
        } else if (Number(value) > 5000) {
            setValue(5000);
        }
    };

    // const getPriceFromCents = cents => {
    //     return cents / 100
    // }

    const calulateSMS = employees => {
        if (employees >= 0) {
            const sms = employees * 2 * 12;
            setSMS(sms);
            setCredits(sms)
        }
    }

    useEffect(() => {
        calulateSMS(value);
    }, [value])

    useEffect(() => {
        let credits = 0;
        if (sms >= 100 && sms <= 2499) {
            credits = (sms * 9.0 / 100)
        }
        else if (sms >= 2500 && sms <= 4900) {
            credits = (sms * 8.5 / 100)
        }
        else if (sms >= 5000 && sms <= 9999) {
            credits = (sms * 7.5 / 100)
        }
        else if (sms >= 10000 && sms <= 19999) {
            credits = (sms * 7.0 / 100)
        }
        else if (sms >= 20000 && sms <= 49999) {
            credits = (sms * 6.5 / 100)
        }
        else if (sms >= 50000 && sms <= 99999) {
            credits = (sms * 6.0 / 100)
        }
        else if (sms >= 100000 && sms <= 249999) {
            credits = (sms * 5.5 / 100)
        }
        else if (sms >= 250000) {
            credits = (sms * 5.0 / 100)
        }
        setSuggestedCredits(Math.round(credits));
    }, [sms])

    useEffect(() => {
        const basePrice = (Number(value) < 100) ? BASE_PRICE_LESSTHAN_100 : BASE_PRICE_100_PLUS;
        setBasePrice(basePrice)
    }, [value])

    return ( <>
            <div className={styles.paymentPlan}>
    <Box sx={{ width: {xs:"98%",md:'97%',lg:"70%"} }} className={styles.employeeCard}>
        <Tooltip title={`Base fee is $3000 for employees of 100+. $1500 for less than 100.
        SMS will be calculated by:
                        Enter # of employees x 2 x 12. Total # of suggested SMS.`}>
            <Typography id="input-slider" sx={{textAlign:'center',margin:"1rem 0.5rem 0.5rem 0.5rem"}} gutterBottom fontSize={18} fontWeight={'bold'}>
                Enter No. of Employees
                <MdInfo />
            </Typography>
        </Tooltip>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs sx={{display:'flex',justifyContent:'center',alignItem:'center'}}>
                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    min={0}
                    max={5000}
                    style={{width:'90%'}}
                />
            </Grid>
        </Grid>
        <Grid container  alignItems="center" >
            <Grid item xs 
            sx={{display:'flex',justifyContent:'center',alignItem:'center'}}>
                <Input
                    value={value}
                    disableUnderline={true} 
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    sx={{ width: '75px', fontSize: "22px", fontWeight: 600,        
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                      },
              }}
                    inputProps={{
                        step: 10,
                        min: 0,
                        max: 3000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                        inputMode: 'numeric', 
                    }}
                />
            </Grid>
        </Grid>
        <Grid container alignItems="center" paddingTop={2}>
            <Grid item xs sx={{textAlign:'center' , fontWeight : 600}}>
                Total # of Credits for {value} employees = {sms}
            </Grid>
        </Grid>
        {/* <Grid container alignItems="center" >
            <Grid item xs sx={{textAlign:'center'}}>
                Cost for {sms} credits = ${suggestedCredits}
            </Grid>
        </Grid>
        <Grid container  alignItems="center" >
            <Grid item xs sx={{textAlign:'center'}}>
                <Typography gutterBottom sx={{textAlign:'center'}} fontSize={14} fontWeight={'bold'}>Total (inc. ${basePrice} base price) = ${suggestedCredits + basePrice} (annually)</Typography>
            </Grid>
        </Grid> */}
        <Box sx={{margin: "10px 0px", display: "flex" , justifyContent: "center"}}>
                <Button onClick={()=>handlePaymentPlanSelection()}>Proceed {selectedCard ? login ?  `as trial` : `to login`  :  `to payment`}</Button>
            </Box>
    </Box>
    </div>
            <Grid container spacing={1} alignItems="center"  paddingTop={1.2}>
            <Grid item xs sx={{textAlign:'center',fontSize:'1rem',fontWeight:500}}>
                For a more information get in touch with us...<a href={"mailto:support@emeva.io"}>support@emeva.io</a>
            </Grid>
        </Grid>
            {/* <Grid container spacing={1} alignItems="center" >
            <Grid item xs sx={{textAlign:'center',fontSize:'12px'}}>
            For getting credits contact us at <a href={"mailto:support@emeva.io"}>support@emeva.io</a>
            </Grid>
        </Grid> */}
        </>
    )
}

export default RightContent;