import internalApi from "shared/utils/internalApi"
import storage from 'shared/utils/storage';


export const changePaymentPlan = ({cardNumber="" , price=0 , credits=0 }) => {
    const company_id = storage.get("companyId");
    const user = storage.get('user');
    return internalApi.post({ server: `company/`, path: `${company_id}/paymentPlan` },{cardNumber,price,user,credits});
}

export const paymentSuccess  = ({payment_intent ,payment_intent_client_secret})  => {
    const company_id = storage.get("companyId");
    return internalApi.post({ server: `company/`, path: `${company_id}/paymentPlan/paymentSuccess` },{payment_intent , payment_intent_client_secret});
}

export const paymentCancelSubscription  = ()  => {
    const company_id = storage.get("companyId");
    return internalApi.post({ server: `company/`, path: `${company_id}/paymentPlan/paymentCancelSubscription` });
}
