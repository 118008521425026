import { memo, useEffect, useReducer, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Users.module.scss';
import Table from 'components/Table';
import Heading from 'components/Heading';
import Button from 'components/Button';
import TopHeader from 'components/TopHeader';
import SearchBar from 'components/SearchBar';

import TopActionBar from 'components/TopActionBar';
import Actions from './Actions';
// import faker from 'faker';
import Breadcrumb from 'components/BreadCrumbs';
// import { fetchItems, deleteItem, unlockItem } from 'shared/api/users';
import basicReducer from 'reducers/basicReducer';
import api from 'api';
import { useAuth } from 'components/ProvideAuth/hook';
import { toast } from 'react-toastify';

var tempSearchValue = '';
const Users = props => {
	const auth = useAuth();
	const [data, setData] = useState([]);
	const [pageCount, setPageCount] = useState(0)
	const [pageSize, setPageSize] = useState(10);
	const navigate = useNavigate();
	const [state, setState] = useReducer(basicReducer, {
		list: [],
		pageSize: 10
	});

	const columns = useMemo(
		() => [
			{
				Header: 'First Name',
				accessor: 'first_name',
			},
			{
				Header: 'Last Name',
				accessor: 'last_name',
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Role',
				accessor: 'roleName',
			},
			// {
			// 	Header: 'Locations',
			// 	accessor: 'locations',
			// },
			{
				Header: 'Modified By',
				accessor: 'modifiedBy',
			},
			{
				Header: 'Modified On',
				accessor: 'created_at',
				type: 'date'
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableSortBy: true,
				disableFilters: true,
				hideFilter: true
			},
		],
		[]
	);

	const deleteUser = id => {
		api.users.deleteUser({ companyId: auth.company.id, userId: id }).then(deleted => {
			toast.success("Deleted Successfully");
			loadRecord({ action: 'loadList' });
		})
	}

	const unlockUser = id => {
		// unlockItem(id).then(unlocked => {
		// 	toast.success(unlocked.payload.message);
		// 	loadRecord({ action: 'loadList' });
		// });
	}
	const loadRecord = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
		let filter = { search: value ?? tempSearchValue };
		if (action === 'search') {
			tempSearchValue = value;
			// filter = Object.assign({}, { search: value });
		}
		filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
		// console.log('auth?.profile->',auth?.profile);
		api.users.listUsers({ ...filter, companyId: auth?.company?.id }).then(users => {
			// console.log('users',users);
			let formattedData = [];
			if (users?.meta?.totalItems > 0) {

				formattedData = users?.items?.map(item => {

					let roleName = item?.role?.title;
					let locations = item?.userToLocations?.map?.(l => l?.location?.title)?.join?.(', ');
					let actions = <Actions id={item?.uuid} deleteItem={deleteUser} unlockItem={unlockUser} />;

					if (item?.user_type === 'admin' || item?.user_type === 'superAdmin') {
						roleName = 'Admin';
						locations = 'ALL';
						actions = <div>No Actions for Admin</div>;
					}

					return ({
						...item,
						id: item?.uuid,
						roleName,
						locations,
						// companyName: i?.company?.name,
						modifiedBy: item?.updated_by?.first_name ? `${item?.updated_by?.first_name} ${item?.updated_by?.last_name}` : item?.created_by?.first_name ? `${item?.created_by?.first_name} ${item?.created_by?.last_name}` : "",
						actions,
					})
				});
				setPageCount(Math.ceil(users.meta.totalItems / pageSize));
				setState({ totalCount: users.meta.totalItems })
			}
			setData(formattedData);
		});
	}

	const fetchData = useCallback(({ pageSize, pageIndex }) => {
		loadRecord({ action: 'loadList', pageSize, pageIndex: pageIndex + 1 });
	}, []);

	let updatePageSize = size => {
		setPageSize(size);
	}

	return (
		<div className={styles.root}>
			<Breadcrumb list={[
				{
					name: 'Users'
				}
			]} />
			<TopHeader>
				<Heading>Users</Heading>
				<SearchBar onSearch={loadRecord} pageSize={pageSize} />
			</TopHeader>
			<TopActionBar>
				<Button onClick={() => navigate('add')}>Add</Button>
			</TopActionBar>
			<Table columns={columns} data={data} pageCount={pageCount} fetchData={fetchData} parentPageSize={updatePageSize} totalCount={state?.totalCount} />
		</div>
	);

};

export default memo(Users);