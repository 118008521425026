import React, { useEffect, useState } from 'react';
import styles from './ImportContact.module.scss';
import api from 'api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'components/ProvideAuth/hook';
import Breadcrumb from 'components/BreadCrumbs';
import Button from 'components/Button';
import { toast } from 'react-toastify';

const ImportContact = () => {

    const auth = useAuth();
    const [createLocations, setCreateLocations] = useState<boolean>(false);
    const [ignoreHeader, setIgnoreHeader] = useState<boolean>(true);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [formData , setFormData] = useState<any>()

    const CreateContact = (_toSave) => {
        setLoading(true)
        api.contacts.importContacts( auth.company.id , _toSave).then((out) => {
            toast.success(`Created contacts: ${out?.newUser}. Updated contacts: ${out?.updateUser}`);
            navigate('/admin/contacts');
        }).catch((error)=>{
            toast.error(`Something went wrong`);
            console.error ("catch " , error)
        }).finally(()=> {
            setLoading(false);
        });
    }

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (!event.target.files) {
            console.error("No file uploaded");
            return;
        }
        setFileName(event.target.files[0].name);
        const file = event.target.files[0]
        
        const formDatum= {
            file ,
            isFileUpload: true,
            ignoreHeader: ignoreHeader,
            createLocationIfNotExists: createLocations
        }
        setFormData(formDatum)
        
    };

    const uploadFile = () => {
        if (formData){
            CreateContact(formData);
        }
    };

    useEffect(()=> {
        if (formData)
        {
            const formDatum= {
                ...formData,
                ignoreHeader: ignoreHeader,
                createLocationIfNotExists: createLocations
            }
            setFormData(formDatum)
        }   
    },[ignoreHeader , createLocations])

    return (
        <div className={styles.root}>
            <Breadcrumb list={[
                {
                    name: 'Contacts',
                    url: '/admin/contacts'
                },
                {
                    name:`Import Contacts`
                },
            ]} />
            <div className={styles.heading}>Import Contact</div>
            <div>Select a CSV file to begin importing contacts</div>
            <div>Please make sure the order of the columns matches the following:</div>
            <div>First Name, Last Name, Email, Mobile Number, Location Name</div>
            <div className={styles['file-picker']}>
                <label htmlFor="file-input" className={styles['file-picker-label']}>
                    <div className={styles['file-picker-placeholder']}>Click or drag file here to upload</div>
                    <div style={{ alignItems : "center" , display : "flex" , justifyContent : "space-around"}}>
                        <label htmlFor="file-input" style={{ backgroundColor: " #007bff", color: "white", padding: "10px 20px", borderRadius: "10px", cursor: "pointer", fontSize: "18px" }}>
                            Choose File
                            <input
                                id="file-input"
                                type="file"
                                onChange={handleFileInputChange}
                                accept=".csv"
                                style={{ display: "none" }}
                            />
                        </label>
                    </div>
                    {fileName ? <p>{fileName}</p> : null}
                </label>
            </div>
            <label style={{display: 'flex', alignItems: 'center',}}>
                <input type="checkbox" defaultChecked={ignoreHeader} onChange={() => setIgnoreHeader(!ignoreHeader)} style={{marginRight: '5px'}} />
                <span style={{fontSize: '16px'}}>Ignore Headers</span>
            </label>
            <label style={{display: 'flex', alignItems: 'center'}}>
                <input type="checkbox" defaultChecked={createLocations} onChange={() => setCreateLocations(!createLocations)} style={{marginRight: '5px'}} />
                <span style={{fontSize: '16px'}}>Create Locations If they don't exist</span>
            </label>
            <Button  disabled={isLoading} className={styles['file-picker-button']} onClick={uploadFile} style={{width : "145px"}}>Import Contacts</Button>
        </div>
    );
};

export default ImportContact;
