import {memo,useEffect,useReducer} from 'react';
// import styles from './SocketBase.module.scss';
// import internalApi from "shared/utils/internalApi";
import basicReducer from 'reducers/basicReducer';
import socketClient from 'shared/utils/socket';
import {useProvideSocket} from './hook';
import { useAuth } from 'components/ProvideAuth/hook';
import socketContext from './context';
let socket = socketClient();

const SocketBase = ({children}) => {
    const auth = useAuth();
    const provideSocket = useProvideSocket(socket);
    
    const [state, setState] = useReducer(basicReducer, {
        messages: [],
        isSocketUpdated: ''
    });

    useEffect(() => {
        if(auth?.token){
            socket.auth = {token:auth?.token};
            socket?.connect?.();
        }else if(!auth?.token){
            socket?.disconnect?.();
        }
    },[auth?.token]);

    useEffect(() => {

        // socket.on("notification", function (data: any) {
        //     // console.log('socket data:- ' + data);
        //     setState({ isSocketUpdated: data });
        // });

        // socket.on('newMessageNotification',response => {
        //     // console.log('SocketBase::newMessageNotification->',response);
        //     provideSocket.publish('newMessageNotification',response);
        // });

        return () => {
            socket?.disconnect?.();
            // // provideSocket.setSocket(null);
            // socket.off("notification");
            // socket.off("newMessageNotification");
        }
    }, []);

    return (
        <socketContext.Provider value={provideSocket}>
            {children}
        </socketContext.Provider>
    );

};

export default memo(SocketBase);