import { memo, useMemo, useState,useReducer, useEffect, useCallback } from 'react';
import styles from './Roles.module.scss';
import Table from 'components/Table';
import Heading from 'components/Heading';
import Button from 'components/Button';
import TopHeader from 'components/TopHeader';
import TopActionBar from 'components/TopActionBar';
import Actions from './Actions';
import SearchBar from 'components/SearchBar';
// import faker from 'faker';
import Breadcrumb from 'components/BreadCrumbs';
import { useNavigate } from 'react-router-dom';
// import { fetchRoles,deleteRoles } from 'shared/api/roles';
import basicReducer from 'reducers/basicReducer';
import api from 'api';
import { useAuth } from 'components/ProvideAuth/hook';


var tempSearchValue = '';
const Roles = props => {
	const auth = useAuth();
	const [roles, setRoles] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const navigate = useNavigate();
	const [state, setState] = useReducer(basicReducer, {
		list: [],
		pageSize: 10
	});

	const columns = useMemo(
		() => [
			{
				Header: 'Title',
				accessor: 'title',
				hideFilter: true,
				disableFilters: true,
			},
			{
			    Header: 'Modified By',
			    accessor: 'modifiedBy',
			},
			{
			    Header: 'Modified On',
			    accessor: 'modifiedOn',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				disableSortBy: true,
				disableFilters: true,
				hideFilter: true,
				width: 120
			},
		],
		[]
	);

	const onDelete = id => {
		api.roles.deleteRole({roleId:id,companyId:auth.company?.id}).then(deleted => {
			// swal("Deleted Successfully");
			loadList({ action: 'loadList' });
		});
	}

	const loadList = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
		let filter = { search: value ?? tempSearchValue };
		if (action === 'search') {
			tempSearchValue = value;
			// filter = Object.assign({}, { search: value });
		}
		filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
		api.roles.listRoles({...filter,companyId: auth?.company?.id}).then(roles => {
			let formattedData = [];
			if (roles?.items) {
				formattedData = roles?.items?.map(item => ({
					...item,
					title: <span className="link" onClick={() => navigate(`edit/${item?.id}`)}>{item?.title}</span>,
					actions: <Actions id={item?.id} onDelete={onDelete} />,
					modifiedBy: item?.user?.first_name ?  `${item?.user?.first_name} ${item?.user?.last_name}` : ""
				}))
				setPageCount(Math.ceil(roles.meta.totalItems / pageSize));
				setState({ totalCount:roles.meta.totalItems })
			}
			setRoles(formattedData)
		});
	}

	const fetchData = useCallback(({ pageSize, pageIndex }) => {
		loadList({ action: 'loadList', pageSize, pageIndex: pageIndex + 1 });
	}, []);

	let updatePageSize = size => {
		setPageSize(size);
	}

	return (
		<div className={styles.root}>
			<Breadcrumb list={[{
				name: 'Roles'
			}]} />
			<TopHeader>
				<Heading>Roles</Heading>
				<SearchBar onSearch={loadList} pageSize={pageSize} />
			</TopHeader>
			<TopActionBar>
				<Button onClick={() => navigate('/admin/roles/add')}>Add</Button>
			</TopActionBar>
			<Table columns={columns} data={roles} showFilter={false} pageCount={pageCount} fetchData={fetchData} parentPageSize={updatePageSize} totalCount={state?.totalCount} />
		</div>
	);

};

export default memo(Roles);