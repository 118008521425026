import { memo, useReducer } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import PublicLayout from "layout/PublicLayout";
import styles from "./Login.module.scss";
import TermPolicy from "../TermPolicy/index";
import { useAuth } from "components/ProvideAuth/hook";
import Input from "components/Form/Input";
import Button from "components/Button";
import Group from "components/Group";
import basicReducer from "reducers/basicReducer";
// import swal from 'sweetalert';
import rightImage from "assets/reminder_re_fe15.svg";
// import { useCompanyId } from 'hooks/useCheckCompany';
import { to } from "shared/utils/common";
import TwoFactorPinRequiredModal from "./TwoFactorPinRequiredModal";

const Login = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  // const companyId = useCompanyId();

  let [form, setForm]: [any, any] = useReducer(basicReducer, {
    email: "",
    password: "",
    pin2fa: "",
  });

  let [state, setState]: [any, any] = useReducer(basicReducer, {
    ssoLoginUrl: "",
    twoFactorPinRequired: false,
  });

  let { from }: any = location?.state || { from: { pathname: "/" } };

  const handleLoginResponse = (err, response) => {
    if (err) {
      if (err?.pinRequired) {
        return setState({ twoFactorPinRequired: true });
      } else {
        return console.error(err);
      }
    }
    // console.log('err-> ',err);
    // console.log('response -> ',response);
    navigate(from);
  };

  const onVerify = async (pin2fa) => {
    let [err, response] = await to(
      auth.signin({ ...form, /*companyId,*/ pin2fa })
    );
    handleLoginResponse(err, response);
  };

  const doLogin = async () => {
    let [err, response] = await to(auth.signin({ ...form /*,companyId*/ }));
    handleLoginResponse(err, response);
  };
  return (
    <PublicLayout>
      {/* <PublicLayout rightContent={<img src={rightImage} height={300} />}> */}
      <div className={styles.root}>
        {/* <div className="text-center*">
                        <h3>Login</h3>
                    </div> */}
        <form className="user">
          <Group label={`Email`}>
            <Input
              type="text"
              className="form-control form-control-user"
              value={form?.email}
              name="email"
              onChange={(email) => setForm({ email })}
              placeholder="Email"
            />
          </Group>
          <Group label={`Password`}>
            <Input
              type="password"
              className="form-control form-control-user"
              value={form?.password}
              name="password"
              onChange={(password) => setForm({ password })}
              placeholder="Password"
              marginLeft="335px"
            />
          </Group>
          <Group>
            <div className={styles.keepLoggedIn}>
              <div className="custom-control custom-checkbox small">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck"
                />
                <label className="custom-control-label" htmlFor="customCheck">
                  Keep me logged in
                </label>
              </div>
              <Link className={styles.forgotPassword} to="/forgot-password">
                Forgot your password?
              </Link>
            </div>
          </Group>
          <Group style={{ marginTop: 20 }}>
            <Button
              style={{ width: "100%", fontWeight: "bold" }}
              contentDirection="center"
              view="primary"
              type="button"
              onClick={doLogin}
            >
              Login
            </Button>
          </Group>
        </form>
        {/* <hr /> */}
        <br />
        {/* <Group className="text-left">
                        <Link className="small link-red" to="/register">Create an Account!</Link>
                    </Group>
                    <br /> */}
        {/* <Group className="text-left">
                        <a className={styles.ssoLoginUrl} href={state?.ssoLoginUrl}>
                        Sign in with <b style={{marginLeft:5}}>SSO</b>  
                        </a>
                    </Group> */}
        <div className={styles.registerWrapper}>
          Don't have an account?&nbsp;
          <a className={styles.termsPolicy} href="/register">
            Sign Up
          </a>
        </div>
        <Group className={styles.privacyMain}>
          <div className={styles.privacyFirst}>
            By using our services you agree to our{" "}
          </div>
          <div>
            <span
              className={styles.termsPolicy}
              onClick={() =>
                window.open(
                  "https://continuityinnovations.com/terms-of-use-emeva",
                  "_blank"
                )
              }
            >
              Terms of Use
            </span>
            &nbsp;and&nbsp;
            <span
              className={styles.termsPolicy}
              onClick={() =>
                window.open(
                  "https://continuityinnovations.com/privacy-policy",
                  "_blank"
                )
              }
            >
              Privacy Policy
            </span>
          </div>
        </Group>
        {state?.twoFactorPinRequired && (
          <TwoFactorPinRequiredModal
            onVerify={onVerify}
            onClose={() => setState({ twoFactorPinRequired: false })}
          />
        )}
      </div>
    </PublicLayout>
  );
};

export default memo(Login);
