import {memo} from 'react';

const Checkbox = ({ value = '', checked = false, disabled,onChange }: { value?: string; checked?: boolean; disabled?: Boolean;onChange?:Function; }) => {
  const _onChange = ({ target }) => {
   
    if (disabled) {
      return;
    }

    const { /*name,*/ checked } = target;

    onChange(checked);
  };

  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        value={value}
        disabled={disabled && !checked}
        onChange={_onChange}
      // onClick={this.props.onClick}
      // style={this.props.style}
      />
      {value && <span>{value}</span>}
    </label>
  );
}

export default memo(Checkbox);
