import { Suspense } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import ProvideAuth from 'components/ProvideAuth';
import SocketBase from 'components/SocketBase';
import Loading from 'components/Loading';
import RootRouter from 'routes/Root';

// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <JotaiProvider>
      <ProvideAuth>
        <SocketBase>
          <Suspense fallback={<Loading fixed={true} size="sm" type="ball-pulse-sync" />}>
            <ToastContainer />
            <BrowserRouter>
              <Suspense fallback={<Loading fixed={true} size="sm" type="ball-pulse-sync" />}>
                  <RootRouter />
              </Suspense>
            </BrowserRouter>
          </Suspense>
        </SocketBase>
      </ProvideAuth>
    </JotaiProvider>
  );
}

export default App;
