import _ from 'lodash';
import config from './config';
import axios from './restClient';
import storage from './storage';

class InternalApi {
  static formatUrl(path) {
    if (_.isString(path)) {
      // return `/resource/${path}`;
      return `/${path}`;
    }
    return `/${path.server}${path.path}`;
  }

  static apiUrl(path) {
    if (_.isString(path)) {
      return `${config.apiUrl}resource/${path}`;
    }
    return `${config.apiUrl}${path.server}${path.path}`;
  }

  static webSocketUrl() {
	// let userDetail = sessionStorage.getItem('user');
	// if(userDetail){
  //   userDetail = JSON.parse(userDetail);
  // }
	// return `${config('api.wsUrl')}/?userId=${userDetail?.id}`;
	return `${config.wsUrl}`;
  }

  static formatAssetUrl(fileName) {
    if (fileName) {
      return `${config.apiUrl}resource/asset/${fileName}?token=${storage.get('token')}`;
    } else {
      return '';
    }
  }

  static get(urlPath, rest:any = {}) {
	const requestParams = Object.assign({}, rest, {
		method: 'GET',
		params: Object.assign({}, rest.params || {}, {
			// token: storage.get('token'),
		}),
		responseType: rest.isFileDownload ? 'blob' : 'json',
		headers: Object.assign({}, rest.headers || {}, {
			'Authorization': `Bearer ${storage.get('token')}`,
			'companyid': storage.get('companyId'),
		}),
	});

	return axios(InternalApi.formatUrl(urlPath), requestParams).then(res => {
		if (rest.isFileDownload) {
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', rest.fileName);
			document.body.appendChild(link);
			link.click();
		}
		return res?.data;
	});
}

static post(urlPath, data:any = {}, headers:any = {}) {
	let formData = new FormData();
	if (data.file && data.isFileUpload) {
		for (const property in data) {
			if (property !== 'isFileUpload') {
				formData.append(`${[property]}`, data[property])
			}
		}
	}
	const requestParams:any = {
		method: 'POST',
		data: data.isFileUpload ? formData : data,
		params: {
			// token: storage.get('token'),
		},
		headers: Object.assign({}, headers, {
			'Content-Type': data.isFileUpload ? "multipart/form-data" : 'application/json',
			'Authorization': `Bearer ${storage.get('token')}`,
			'companyId': storage.get('companyId'),
		}),
	};
	return axios(InternalApi.formatUrl(urlPath), requestParams).then(
		res => res?.data,
	);
}

static put(urlPath, data = {}, headers = {}) {
	const requestParams:any = {
		method: 'PUT',
		data,
		params: {
			// token: storage.get('token'),
		},
		headers: Object.assign({}, headers, {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${storage.get('token')}`,
			'companyId': storage.get('companyId'),
		}),
	};

	return axios(InternalApi.formatUrl(urlPath), requestParams).then(
		res => res?.data,
	);
}

static patch(urlPath, data = {}, headers = {}) {
	const requestParams:any = {
		method: 'PATCH',
		data,
		params: {
			// token: storage.get('token'),
		},
		headers: Object.assign({}, headers, {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${storage.get('token')}`,
			'companyId': storage.get('companyId'),
		}),
	};

	return axios(InternalApi.formatUrl(urlPath), requestParams).then(
		res => res?.data,
	);
}


  static delete(urlPath, data = {}, headers = {}) {
    const requestParams:any = {
      method: 'DELETE',
      data,
      params: {
        // token: storage.get('token'),
      },
      headers: Object.assign({}, headers, {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${storage.get('token')}`,
        'companyId': storage.get('companyId'),
      }),
    };

    return axios(InternalApi.formatUrl(urlPath), requestParams).then(
      res => res?.data,
    );
  }

  static uploadFile(name, file, options = {}) {
    const data = new FormData();

    data.append(name, file);
    data.append('name', name);
    data.append('options', JSON.stringify(options));

    const requestParams:any = {
      method: 'POST',
      data,
      params: {
        // token: storage.get('token'),
      },
      headers: Object.assign(
        {},
        {
          'Authorization': `Bearer ${storage.get('token')}`
        },
        {
          'companyId': storage.get('companyId'),
        },
      ),
    };

    return axios(
      InternalApi.formatUrl('attachment/upload'),
      requestParams,
    ).then(res => res?.data);
  }

  static uploadAsset(name, file, options = {}) {
    const data = new FormData();

    data.append(name, file);
    data.append('name', name);
    data.append('options', JSON.stringify(options));

    const requestParams:any = {
      method: 'POST',
      data,
      params: {
        // token: storage.get('token'),
      },
      headers: Object.assign(
        {},
        {
          'Authorization': `Bearer ${storage.get('token')}`
        }
      ),
    };

    return axios(
      InternalApi.formatUrl('asset/upload'),
      requestParams,
    ).then(res => res?.data);
  }
}

export default InternalApi;