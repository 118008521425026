import { ThreeDots } from 'react-loader-spinner';

import classNames from 'classnames';

// import './component.scss';
const Loading = props => {
  const { type, active, isLight,size, fixed } = props;

  const className = classNames(props.className, {
    'loader-fixed': fixed,
    'loader-overlay': active,
    'loader-light': isLight,
  });
  const customWrapperStyle = {
    position: "absolute",
    top: '50%',
    left: "50%",
    transform: 'translate(-50%, -50%)', // Corrected transform property
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Example background color and opacity
    borderRadius: '50%', // To make the wrapper round if desired
    width: '100px', // Example width
    height: '100px',
    zIndex: '100000'
  };  

  return fixed ?  
  <div className={className} style={{zIndex  : 100000}}>
  <ThreeDots 
    height="100" 
    width="100" 
    radius="9"
    color="#0860fb" 
    ariaLabel="three-dots-loading"
    wrapperStyle={customWrapperStyle}
    visible={fixed}
    />
</div>:null;
};

export default Loading;
