import { memo, useCallback, useEffect, useReducer, useState } from 'react';
import styles from './Credits.module.scss';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'components/BreadCrumbs';
import Table from "components/Table";
import Heading from 'components/Heading';
import Card from 'components/Card';
import api from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Form/Input';
import { toast } from 'react-toastify';
import TopHeader from 'components/TopHeader';
import basicReducer from 'reducers/basicReducer';
import { useAuth } from 'components/ProvideAuth/hook';
import Group from 'components/Group';
import { useNavigate } from 'react-router-dom';
import { getFormattedDateTime } from 'shared/utils/common';

let tempSearchValue = ""
const columns = [ 
    {   
        Header: "Credit(s) Used",
        accessor: "creditsUsed",
    },
    {
        Header: "Action",
        accessor: "action",
    },
    {
        Header: "Event",
        accessor: "creditDetails",
    },
    {
        Header: "Date/Time",
        accessor: "dateTime",
        type: 'date'
    },
];

const Credits = props => {
    
    const params = useParams();
    const user = useAuth().user
    const superAdmin = user.user_type === "superAdmin";
    const [open, setOpen] = useState(false);
    const [newCredits , setNewCredits] = useState<number>();
    const navigate = useNavigate();

    const breadcrumbList = [
        {
          name: 'Companies',
          url: '../companies',
        },
        {
          name: 'Edit Company',
          url: `../company/edit/${params?.id}`,
        },
        {
            name: `Company Credits`
        }
    ];
      
   const breadcumbList2 = [
        {
        name: `Company Credits`
        }
   ] 
      

    const [state, setState] = useReducer(basicReducer, {
        list: [ {
            creditsUsed : 1,
            creditDetails: 2,
            dateTime: 3,
            action: 4,
        }],
        pageSize: 10,
        showImportModal: false,
        totalCount: 0
    });
    const [pageCount, setPageCount] = useState(0);

    const [ credits ,  setCredits] = useState({
        allotted: 3000,
        used: 2000,
        available: 1000,
    });

    const [ paymentPlan ,  setPaymentPlan] = useReducer(basicReducer, {
        title: '',
        discription: '',
        features: [],
        daysRemaining: 0
    })

	const loadList = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
        let filter = { search: value ?? tempSearchValue };
		if (action === 'search') {
			tempSearchValue = value;
		}
        filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
        api.creditLogs.listCreditLogs({ options: filter, userId: params?.id }).then((list) => {

            let formattedData = [];
            if (list?.items?.length > 0) {
                formattedData = list?.items?.map((item) => ({
                        creditsUsed : item?.credits_used,
                        creditDetails: item?.description,
                        dateTime: getFormattedDateTime(item?.created_at),
                        action: item?.title,
                }));
                setPageCount(Math.ceil(list?.meta?.totalItems / pageSize));
                setState({ totalCount: list?.meta?.totalItems });
            }
            setState({ list: formattedData });

          });
      };

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        loadList({ action: "loadList", pageSize, pageIndex: pageIndex + 1 });
    }, []);

    const updatePageSize = (size) => {
        setState({ pageSize: size });
    }

    const addCredits = () => {
       
        if (newCredits)
            api.users.addCompanyCredits({id: params?.id , credits: newCredits}).then(() => {
                toast("Successfully updated credits");
                const number = Number(newCredits)
                setCredits({allotted : credits.allotted + number , available: credits.available + number , used: credits.used });
                setOpen(false);
                setNewCredits(null)
            }).catch( () => toast("Something went wrong"))
    }

    const paymentDetails = async() => {
        await api.users.getCompanyPaymentPlan({id : params?.id}).then ( paymentPlan => {
            if (paymentPlan) {
                setPaymentPlan({
                    title : paymentPlan?.title,
                    description : paymentPlan?.description,
                    features : paymentPlan?.features,
                })
            }
        })
       await api.users.getCompanyCredits({id: params?.id}).then((result)=> {
            setCredits(result)
        })
    }

    const companyuserDetails = async(planCheck = false) =>{
        await api.users.listCompanyDetailsByUserId({ id: params?.id }).then( companyDetails => {
            if (companyDetails) {
                if (!companyDetails?.company?.paymentSuccessDate){
                    const timeDiff = Math.abs(new Date(companyDetails?.user?.emailVerifiedAt).getTime() - new Date().getTime());
                    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    const updatedDaysRemaining = paymentPlan?.title === 'Trial' && planCheck === false
                    ? 7 - diffDays
                    : 0;
                    setPaymentPlan({ daysRemaining: updatedDaysRemaining });
                }
                else{
                    const timeDiff = Math.abs(new Date(companyDetails?.company?.paymentSuccessDate).getTime() - new Date().getTime());
                    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                    setPaymentPlan( { daysRemaining : 365 -diffDays })
                }
            }
        });
    }
    useEffect(()=> {
        if (params?.id) {
            paymentDetails();
        }
    },[params])

  useEffect(() => {
    if(paymentPlan?.title && params.id){
        companyuserDetails(false);
    }
  },[paymentPlan.title]);

    const Subscription = async () => {
        try {
         const res = await api.payment.paymentCancelSubscription();
          if(res && params?.id){
                 toast("Subscription canceled successfully");
                 paymentDetails();
                 companyuserDetails(true);
          }
        } catch (error) {
            toast.error("Failed to cancel subscription!");
        }
    }
 
    return (
        <div>
            <Modal isOpen={open} onClose={()=> setOpen(false)} title={"Add Credits"}>
                <Input type="number" value={newCredits} onChange={newCredits => setNewCredits(newCredits)} />
                <div className={styles.flex} style={{width : "150px", marginTop : "20px"}}>
                    <Button onClick={addCredits}>Add</Button>
                    <Button view="secondary" onClick={() => setOpen(false)}>Cancel</Button>
                </div>
            </Modal>
            <Breadcrumb list={superAdmin ? breadcrumbList : breadcumbList2} />
            <div className={styles.headingFlex}>
                <Heading>Credits</Heading>
                {superAdmin && <Button onClick={()=> {setOpen(true)}}>Add Credits</Button>}
            </div>
            <div className={styles.cardDiv}>
                <Card style={{display : "flex" ,flexDirection: "column", gap:"30px" , fontSize : "20px" , width : "290px"}}>
                    Credits Allotted
                    <div className={styles.cardStyle}>
                        {credits.allotted}
                    </div>
                </Card>
                <Card style={{display : "flex" ,flexDirection: "column", gap:"30px" , fontSize : "20px", width : "290px"}}>
                    Credits Used
                    <div className={styles.cardStyle}>
                        {credits.used}
                    </div>
                </Card>
                <Card style={{display : "flex" ,flexDirection: "column", gap:"30px" , fontSize : "20px", width : "290px"}}>
                    Credits Available
                    <div className={styles.cardStyle}>
                        {credits.available}
                    </div>
                </Card>
            </div>
            {!superAdmin && <div style={{maxWidth : "500px" , width: "100%" , marginBottom: "20px"}}>
                <Heading>Plan Details</Heading>
                <Card style={{flexDirection: "column" }}>
                    <Group label="Plan Type:" className={styles.flex}>
                        {paymentPlan?.title} { (paymentPlan?.daysRemaining < 1 && user.is_successful_registration) ? <span style={{color : "#d84856"}}>(expired)</span> : null}
                    </Group>
                    {/* <Group label="Description: " className={styles.flex}>
                        {paymentPlan?.description}
                    </Group>
                    <Group label="Features: " className={styles.flex}>
                        {paymentPlan.features}
                    </Group> */}
                    <Group label="Days Remaining: " className={styles.flex}>
                        {paymentPlan?.daysRemaining}
                    </Group>
                    {/* <Group className={styles.planCalculatorLink}>
                        For calculating the credits required for your company 
                        <a href="#" onClick={() => navigate('/plan-calculator')} style={{marginLeft : "5px",color: "#4169DF"}}>
                            click here 
                        </a>
                    </Group> */}
                    <Group>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {paymentPlan?.title === "Trial" ? (
                        <span
                            style={{ color: "#4169DF", cursor: "pointer", fontWeight: 500 }}
                            onClick={() => navigate(`/payment-select`)}
                        >
                            Update Payment Plan
                        </span>
                        ) : (
                        <>
                            For additional credits, contact{" "}
                            <span style={{ color: "#4169DF" }}>help@continuityinnovations.com</span>
                        </>
                        )}
                        {paymentPlan?.title === "Trial" && paymentPlan?.daysRemaining > 0 ? (
                        <span onClick={Subscription} style={{ color: "#FF0000", cursor: "pointer", fontWeight: 500 }}>
                            Cancel Your Subscription
                        </span>
                        ):''}
                    </div>
                    {paymentPlan?.title === "Premium" && paymentPlan?.daysRemaining > 0 && (
                        <>
                        <br />
                        <span onClick={Subscription} style={{ color: "#FF0000", cursor: "pointer", fontWeight: 500, marginTop: '5px' }}>
                            Cancel Your Subscription
                        </span>
                        </>
                    )}
                    { paymentPlan?.daysRemaining > 0 && <p style={{ margin: '2' }}>
                        To cancel your Subscription, please send an email at{" "}
                        <a href={"mailto:support@emeva.io"}>support@emeva.io</a>
                    </p>}
                    </Group>
                </Card>
            </div>}
            <TopHeader>
                <Heading>Credit Logs</Heading>
            </TopHeader>
			<Table columns={columns} data={state?.list} pageCount={pageCount} fetchData={fetchData} parentPageSize={updatePageSize} totalCount={state?.totalCount} />
        </div>
       
    );

};

export default memo(Credits);