import { memo } from 'react';
import Modal from 'components/Modal';
import UploadButton from 'components/UploadButton';
import Button from 'components/Button';

const PreviewImageModal = ({ previewUrl = null, fileType = null, onClose }) => {

    return (
        <Modal onClose={onClose} isOpen={true}>
            <div>
                <h2>Preview</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px' }}>
                {previewUrl && (
                    <>
                        {fileType?.includes('image') ? (
                            <img src={previewUrl} alt="File preview" style={{ height: "300px" }} />
                        ) : fileType === 'application/pdf' ? (
                            <embed src={previewUrl} width="100%" height="375" type="application/pdf" />
                        ) : fileType === 'text/csv' ? (
                            <p style={{ width: '100%' }}>{previewUrl}</p>
                        ) : ''
                        }
                    </>
                )}



            </div>
            <Button view="secondary" style={{ marginLeft: 10 }} onClick={onClose}>Close</Button>

        </Modal>
    );

};

export default memo(PreviewImageModal);