import {memo} from 'react';
import styles from './TopActionBar.module.scss';

const TopActionBar = ({children}) => {

    return (
        <div className={styles.root}>
            {children}
        </div>
    );

};

export default memo(TopActionBar);