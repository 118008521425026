import internalApi from "shared/utils/internalApi";

export const listContacts = ({
  limit = 10,
  page = 1,
  companyId,
  search = "",
  location = "all",
}: {
  limit?: number;
  page?: number;
  companyId: string;
  search?: string;
  location?: string;
}) => {
  const query = `limit=${limit}&page=${page}&search=${search}&location=${location}`;
  return internalApi.get({
    server: `company/`,
    path: `${companyId}/contacts/?${query}`,
  });
};

// export const getCompanyUser = ({companyId,userId}) => {
//     return internalApi.get({ server: `company/`, path: `${companyId}/users/${userId}` });
// }

export const deleteContact = ({ companyId, userId }) => {
  return internalApi.delete({
    server: `company/`,
    path: `${companyId}/contacts/${userId}`,
  });
};

export const createContact = ({
  company_id,
  first_name,
  last_name,
  email,
  contact,
  location,
}) => {
  return internalApi.post(
    { server: `company/`, path: `${company_id}/contacts/` },
    { company_id, first_name, last_name, email, contact, location }
  );
};

export const updateContact = ({
  uuid,
  company_id,
  first_name,
  last_name,
  email,
  contact,
  location,
}) => {
  return internalApi.patch(
    { server: `company/`, path: `${company_id}/contacts/${uuid}` },
    { company_id, first_name, last_name, email, contact, location }
  );
};

// export const importContacts = async (
//     companyId: string,
//     payload: FormData
//   ) => {
//     return  internalApi.post(`${companyId}/contacts/import`, payload, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     })
//   }

export const importContacts = async (companyId: string, payload: any) => {
  return internalApi.post(
    { server: `company/`, path: `${companyId}/contacts/import` },
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
