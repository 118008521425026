import { memo, Suspense, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { getEnvItem } from "shared/utils/getEnvironment";
// import styles from './Root.module.scss';
import App from "routes/App";
import Login from "views/auth/Login";
import Register from "views/auth/Register";
import ForgotPassword from "views/auth/ForgotPassword";
import { useAuth } from "components/ProvideAuth/hook";
import storage from "shared/utils/storage";
import PageNotFound from "views/public/PageNotFound";
import UseCompanyUrl from "views/public/UseCompanyUrl";
import PlanCalculator from "views/public/PlanCalculator";
import VerifyOTP from "views/auth/VerifyOTP";
import PaymentPlan from "views/auth/PaymentPlan";

const Root = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  let inactivityTimer;
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(logout, 15 * 60 * 1000);
  };

  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  const logout = () => {
    auth.signout(() => {
      navigate("/login");
    });
  };

  useEffect(() => {
    resetInactivityTimer();
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("mousedown", handleUserActivity);

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("mousedown", handleUserActivity);
    };
  }, []);

  // TBD
  useEffect(() => {
    if (!auth.user && storage.get("user")) {
      auth.loadUserFromSession();
      // navigate('/');
    }
  }, [auth.user]);

  useEffect(() => {
    // transfers sessionStorage from one tab to another
    var sessionStorage_transfer = function (event) {
      if (!event) {
        event = window.event;
      } // ie suq
      if (!event.newValue) return; // do nothing if no value to work with
      if (event.key == "getSessionStorage") {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
      } else if (event.key == "sessionStorage" && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue);
        for (var key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }

      if (!auth.user && storage.get("user")) {
        auth.loadUserFromSession();
        // navigate('/');
      }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem("getSessionStorage", "init");
      localStorage.removeItem("getSessionStorage");
    }
  }, []);

  if (auth.user && !location?.pathname.includes("/plan-calculator")) {
    return <App />;
  } else {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* <Route path="/" element={<UseCompanyUrl />} /> */}
          <Route path="/" element={<Login />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          <Route path="/reset-password" element={null} />
          {/* <Route path="/:companyAlias" element={<Login />} /> */}
          {/* <Route path="*" element={<PageNotFound />} /> */}
          <Route path="/plan-select" element={<PaymentPlan />} />
          <Route path="/plan-calculator" element={<PlanCalculator />} />
        </Routes>
      </Suspense>
    );
  }
};

export default memo(Root);
