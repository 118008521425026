import internalApi from "shared/utils/internalApi";
import { SendSMSRequest } from "types/sms";

export const listEvents = ({
  limit = 10,
  page = 1,
  companyId,
  search = "",
}) => {
  const query = `limit=${limit}&page=${page}&search=${search}`;
  return internalApi.get({
    server: `company/`,
    path: `${companyId}/events/?${query}`,
  });
};

export const getOpenEvents = ({ companyId, id }) => {
  return internalApi.get({
    server: `company/`,
    path: `${companyId}/events/${id}/openEvents`,
  });
};

export const getEvent = ({ companyId, id }) => {
  return internalApi.get({
    server: `company/`,
    path: `${companyId}/events/${id}`,
  });
};

export const createEvent = ({
  companyId,
  name,
  message,
  event_type,
  is_drill,
  labels,
  colors,
  locations_ids,
  trigger_now,
  save_as_template,
  event_datetime,
  is_only_template = false,
}) => {
  return internalApi.post(
    { server: `company/`, path: `${companyId}/events/` },
    {
      name,
      message,
      event_type,
      is_drill,
      labels,
      colors,
      locations_ids,
      trigger_now,
      save_as_template,
      event_datetime,
      is_only_template,
    }
  );
};

export const updateEvent = ({
  companyId,
  id,
  name,
  message,
  event_type,
  is_drill,
  labels,
  locations_ids,
  trigger_now,
  save_as_template,
  event_datetime,
  options,
  eventToLocations,
}) => {
  return internalApi.patch(
    { server: `company/`, path: `${companyId}/events/${id}` },
    {
      name,
      message,
      event_type,
      is_drill,
      labels,
      locations_ids,
      trigger_now,
      save_as_template,
      event_datetime,
      options,
      eventToLocations,
    }
  );
};

export const deleteEvent = ({ companyId, id }) => {
  return internalApi.delete({
    server: `company/`,
    path: `${companyId}/events/${id}`,
  });
};

export const updateEventContactStatus = ({
  companyId,
  eventId,
  contactId,
  payload = {},
}) => {
  return internalApi.patch(
    {
      server: `company/`,
      path: `${companyId}/events/${eventId}/contacts/${contactId}`,
    },
    payload
  );
};

export const updateAllClearStatus = ({ companyId, eventId, payload = {} }) => {
  return internalApi.post(
    { server: `company/`, path: `${companyId}/events/${eventId}/all-clear` },
    payload
  );
};

export const sendEventSMS = async (
  companyId: number,
  eventId: number,
  payload: SendSMSRequest
) => {
  internalApi.post(
    { server: `company/`, path: `${companyId}/events/${eventId}/smses` },
    payload
  );
};

export const getMessagesOfContact = async (
  companyId: number,
  eventId: number,
  sender: string,
  reciever: string
) => {
  return internalApi.get({
    server: `company/`,
    path: `${companyId}/events/${eventId}/smses/${sender}/${reciever}`,
  });
};
