import { memo, useEffect, useState } from "react";
import styles from "./GeneralEvents.module.scss";
import Breadcrumb, { BreadCrumbs } from "components/BreadCrumbs";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import Card from "components/Card";
import { useParams } from "react-router-dom";

const GeneralEvents = (props) => {
  const auth = useAuth();
  const params = useParams();

  const [eventDetails, setEventDetails] = useState(null);
  const [locations, setLocations] = useState([]);
  const [createdAt, setCreatedAt] = useState(null);

  const parseDatetime = (datetime: string) => {
    try {
      const dt = new Date(datetime);
      if (dt.toString() !== "Invalid Date") {
        return dt;
      }
      return null;
    } catch (err: any) {
      console.error(err.message);
      return null;
    }
  };

  useEffect(() => {
    api.locations
      .listLocations({ companyId: auth?.company?.id })
      .then((locations) => {
        if (locations?.items) {
          setLocations(locations?.items);
        }
      });
  }, [params?.id]);

  useEffect(() => {
    if (params?.id) {
      api.events
        .getEvent({ id: params?.id, companyId: auth?.company?.id })
        .then((eventDetails) => {
          if (eventDetails) {
            let locations_ids = [];
            if (
              eventDetails?.eventToLocations &&
              Array.isArray(eventDetails?.eventToLocations)
            ) {
              locations_ids = eventDetails?.eventToLocations?.map?.(
                (i) => i?.location?.id
              );
            }
            const filteredLocations = locations.filter((loc) =>
              locations_ids.includes(loc.id)
            );
            setCreatedAt(
              parseDatetime(
                eventDetails?.event_datetime || eventDetails?.created_at
              )
            );
            setEventDetails({ ...eventDetails, filteredLocations });
          }
        });
    }
  }, [locations]);

  return (
    <div className={styles.root}>
      <BreadCrumbs
        list={[
          {
            name: "Event Tracking",
            url: "events",
          },
          {
            name: "Event Tracking",
          },
        ]}
      />
      <div>
        <h2>Event Tracking</h2>
      </div>
      <div>
        <div className={styles.root}>
          <Card
            style={{
              width: "50%",
            }}
          >
            <div
              className={styles.eventTrackingCard}
              style={{ marginRight: "0px" }}
            >
              <h4 style={{ color: "#034CCD" }}>{eventDetails?.name}</h4>
              <label>{eventDetails?.message}</label>
              <h5 style={{ marginBottom: "10px", marginTop: "10px" }}>
                {eventDetails?.status === "Scheduled"
                  ? "Scheduled For: "
                  : "Sent At: "}
                {createdAt?.toLocaleTimeString()} {createdAt?.toDateString()}
              </h5>
              <div>
                {eventDetails?.filteredLocations.map((etl, i) => (
                  <label className={styles.locationLabel} key={i}>
                    <span>{etl.title}</span>
                  </label>
                ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
      ></div>
    </div>
  );
};

export default memo(GeneralEvents);
