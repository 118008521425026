import { Divider, Fab, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import api from "api";
import BreadCrumbs from "components/BreadCrumbs";
import { useAuth } from "components/ProvideAuth/hook";
import { useEffect, useRef, useState } from "react";
import { BsSendFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SendSMSRequest } from "types/sms";
import styles from "./ContactMessages.module.scss"

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '65vh',
    overflowY: 'auto',
    color: "white"
  },
  leftSide:  {
    display: "flex",
    justifyContent : "end",
    maxWidth: "100%",
    paddingLeft :  "60%"
  },
  leftSideText  : {
    backgroundColor : "#0E80D7",
    padding : "10px",
    maxWidth : "fit-content",
    borderRadius: "10px",
  },
  rightSide : {
      textAlign : "start",
      width: "50%",
  },
  rightSideText : {
      backgroundColor : "grey",
      maxWidth: "fit-content",
      padding : "10px",
      borderRadius: "10px"
  },
  selectedChat: {
      cursor : "pointer",
      borderRadius : "10px",
  },
  rightSideTextTime : {
    color: "grey",
    width: "fit-content"
  },
  leftSideTextTime : {
    color: "grey",
    maxWidth : "fit-content"
  }
}));

const ContactMessages = () => {

  const classes = useStyles();
  const params = useParams();
  const auth = useAuth();
  const user = auth.user;

  const [smses , setSmses] = useState([]);
  const [newMessage ,  setNewMessage] = useState("");
  const messageAreaRef = useRef(null);
  const [receiverUuid, setRecieverUuid] = useState("");
  const [ eventId, setEventId ] = useState("");
  const [reciever , setReciever] = useState<any>();


  const scrollToBottom = () => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    setNewMessage("");
    const payload : SendSMSRequest = {
        to: `${reciever?.contact}`,
        body: newMessage,
        receiver_uuid :  receiverUuid,
        sender_uuid: `${user?.uuid}`,
        in_app: true,
    }
    api.events.sendEventSMS(Number(auth.company.id), Number(eventId) , payload).then((res)=> {
        setTimeout(() => {
            getMessages();
          }, 1000);
    }).catch((err) => toast.error("error as  " + err))
  }
  const getMessages = () => {

    if  (eventId && receiverUuid)
      api.events?.getMessagesOfContact(Number(auth?.company?.id ),Number(eventId) , user?.uuid , receiverUuid  ).then((result) => {
        setSmses(result)
      })

  }

  const AvatarInitials = ({ fname, lname }) => {
    return (
      <div className={styles.circle}>
        <span className={styles.initials}>
          {(
            fname?.toString().charAt(0) + lname?.toString().charAt(0)
          ).toUpperCase()}
        </span>
      </div>
    );
  };

  useEffect (()=> {
    api.events.getOpenEvents({ companyId: user.company_id , id: user.uuid}).then((events)=>{
        if (events.length > 0)
        { 
          setRecieverUuid(events[0]?.created_by?.uuid);
          setEventId(events[0]?.id);
          const receivers = events[0]?.chats[0];
          setReciever(receivers)
        }
    }).catch((err)=> {
        toast.error("error" , err)
    })
  } , [])


  useEffect (()=> {
    if (eventId && receiverUuid )
      api.events.getEvent({ id: eventId, companyId: auth?.company?.id }).then((events) => {
        events?.eventToContacts?.forEach((contact) => {
            if (user?.uuid === contact?.user?.uuid){
              api.events?.getMessagesOfContact(Number(auth?.company?.id ),Number(eventId) , user?.uuid , receiverUuid  ).then((result) => {
                setSmses(result)
              })
            }
        })
    })
  },[eventId , receiverUuid])



  useEffect(()=>{
    scrollToBottom();
  },[smses.length  , smses])

  useEffect(() => {
    const sse = new EventSource(`${process.env.REACT_APP_API_URL}company/${auth?.company?.id}/events/sms/sse`,
      { withCredentials: false });
    const getRealtimeData = (data) => {
      getMessages();
    }
    sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
    sse.onerror = () => {
      sse.close();
    }
    return () => {
      sse.close();
    };
  }, []);
  
  return (
      <div>
        <BreadCrumbs
          list={[
            {
              name: "Messaging",
            },
          ]}
        />
        <Grid container>
            <Grid item xs={12} >
                <Typography variant="h5" className="header-message" style={{marginBottom : "8px"}}>Messaging</Typography>
            </Grid>
        </Grid>
        <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={3} className={classes.borderRight500}>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <AvatarInitials
                              fname={reciever?.name?.toString() || ""}
                              lname={reciever?.name?.toString() || ""}
                            />
                        </ListItemIcon>
                        <ListItemText primary={`${reciever?.name}`}></ListItemText>
                    </ListItem>
                </List>
                <Divider />
            </Grid>
            <Grid item xs={9}>
                <List className={classes.messageArea} ref={messageAreaRef}>
                    {
                      smses?.map((sms , index) => {
                          const dateString = sms?.created_at;
                          const date = new Date(dateString);
                          const timeString = date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
                          console.log("sms" , sms?.receiver)
                          return (
                            <ListItem key={index}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSide : classes.leftSide}>
                                          <div style={{display: "flex" , gap: "5px" ,width: "fit-content"}}>
                                          {sms?.receiver?.uuid===auth?.user?.uuid && 
                                          <div style={{width : "50px" , display :"flex" , alignItems  :"end"}}>
                                            <AvatarInitials
                                            fname={sms?.sender?.first_name?.toString()}
                                            lname={sms?.sender?.last_name?.toString()}
                                            />
                                          </div>
                                          }
                                          <ListItemText className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSideText : classes.leftSideText}>{sms?.body}</ListItemText>
                                          </div>
                                        </div>
                                        <div className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSide : classes.leftSide}>
                                          <ListItemText className={sms?.receiver?.uuid===auth?.user?.uuid ? classes.rightSideTextTime : classes.leftSideTextTime}>{timeString}</ListItemText>
                                        </div>
                                    </Grid>
                                </Grid>
                            </ListItem>
                          )
                      })
                    }
                </List>
                <Divider />
                <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                        <TextField id="outlined-basic-email" label="Type Something" fullWidth value={newMessage} onChange={(val) =>  setNewMessage(val.target.value)}/>
                    </Grid>
                    <Grid item xs={1} style={{paddingLeft: "10px"}}>
                        <Fab color="primary" aria-label="add" onClick={() => sendMessage()}><BsSendFill /></Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </div>
  );
}

export default ContactMessages;