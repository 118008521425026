import classNames from 'classnames';
import { memo, useState } from 'react';
import cs from './component.module.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Button from 'components/Button';

let timerHandle = null;

interface InputAutoTrigger {
  length: number;
  delay: number;
  cb: Function;
}

const Input = ({value,onChange = () => null,name = null,placeholder = '',type = 'text',invalid = false,readOnly = false,className,pattern,bordered = false,noborder = false,lg = false,addon = null,style,autoTrigger = null,autoComplete = '',onKeyPress = null,onFocus = null,onBlur = null, accept = null, marginLeft = ""}:{
  value:string | any;
  onChange:Function;
  name?:string;
  placeholder?:string;
  type?:string;
  accept?: any;
  invalid?:boolean;
  readOnly?:boolean;
  className?:string;
  pattern?:string;
  bordered?:boolean;
  noborder?:boolean;
  lg?:boolean;
  addon?:any;
  style?:any;
  autoComplete?:string;
  onKeyPress?:any;
  onFocus?:any;
  onBlur?:any;
  marginLeft?: string;
  autoTrigger?:InputAutoTrigger;}) => {

  const [showPassword, setShowPassword] = useState(false);

  const _onChange = e => {
    const { name, value, files } = e.target;
    onChange?.(type === 'file' ? files : value, name);

    if(typeof autoTrigger !== 'undefined' && autoTrigger && autoTrigger?.cb){
      doAutoTrigger(value);
    }
  };

  const _onKeyPress = e => {
    const code = e.which;

    if (code === 13 && e.target.value !== '') {
      onKeyPress?.(code);
    }
  };

  const doAutoTrigger = (value) => {
    if(timerHandle){
      clearTimeout(timerHandle);
      timerHandle = null;
    }

    doDelayedAutoTrigger(value);
  }

  const doDelayedAutoTrigger = (value) => {
    let lengthToCheck = (autoTrigger && autoTrigger.length) || 3;
    if(value.length >= lengthToCheck || value.length === 0){
      timerHandle = setTimeout(()=>{
        timerHandle = null;
        autoTrigger?.cb?.call?.(undefined,value);
  
      },autoTrigger?.delay || 1000);
    }
  }

  // console.log("input text being rendered => ",field);
    return (
        <>
          <input
            style={{...style , position: "relative" }}
            className={classNames(cs.root,className, {
              [cs.noborder]: noborder,
              [cs.bordered]: bordered,
              [cs.invalid]: invalid,
              [cs.lg]: lg,
              [cs.readonly]: readOnly,
              [cs.noRightRadius]: addon,
            })}
            // className={classNames(className)}
            name={name}
            pattern={pattern}
            value={value}
            placeholder={placeholder}
            type={showPassword ? 'text' : type}
            readOnly={readOnly}
            autoComplete={autoComplete}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={_onChange}
            accept={accept}
            onKeyDown={e => {
              if (e.which === 13) {
                _onKeyPress?.(e);
              } else {
                _onChange?.(e);
              }
            }}
            
          />
          {type === "password" && (
            <button
              onClick={() => setShowPassword(!showPassword)}
              type='button'
              style={{border: "none" , background: 0 , position : "relative" , marginLeft :"-40px", marginTop: "10px"}}
            >
              {showPassword ? (
                <FaEyeSlash/>
              ) : (
                <FaEye />
              )}
            </button>
          )}
          </>   
    );
}

export default memo(Input);
