import * as auth from "./auth.api";
import * as users from "./users.api";
import * as contacts from "./contacts.api";
import * as roles from "./roles.api";
import * as locations from "./locations.api";
import * as modules from "./modules.api";
import * as events from "./events.api";
import * as templates from "./templates.api";
import * as stats from "./stats.api";
import * as creditLogs from "./creditLogs.api"
import * as payment from "./payment.api"
const api = {
    auth,
    users,
    contacts,
    roles,
    locations,
    events,
    templates,
    stats,
    modules,
    creditLogs,
    payment
};

export { api };
export default api;