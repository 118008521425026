import { memo, useState } from 'react';
import styles from './SearchBar.module.scss';
import Input from 'components/Form/Input';

const SearchBar = ({ onSearch = null, width = 250, pageSize = 10 }) => {
	let [value, setValue] = useState('');

	const _onSearch = v => {
		// console.log('about to search now',v);
		if (onSearch) {
			onSearch({ action: 'search', value: v, pageSize: pageSize });
		}
	}

	return (
		<div className={styles.root}>
			<Input name="search" placeholder="Search"
				autoTrigger={{ length: 2, delay: 500, cb: _onSearch }}
				style={{ width }} value={value} onChange={setValue} />
		</div>
	);

};

export default memo(SearchBar);