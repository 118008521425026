import { useState, useContext } from "react";
import authContext from "./context";
import storage from "shared/utils/storage";
import { toast } from "react-toastify";
import api from "api";
// import { CinnoUserAttributes } from 'shared/types/user';
import { to } from "shared/utils/common";
interface ICompanyMeta {
  zoom: {
    key: string;
    secret: string;
    accountEmail: string;
  };
  twitter: {
    listId: string;
  };
}

interface ICompany {
  alias: string;
  allowPublicRegistration: boolean;
  authType: string;
  id: string;
  isActive: string;
  lockoutPeriod: number;
  logo: string;
  meta: ICompanyMeta;
  name: string;
  numberOfCharactersAllowed: number;
  numberOfPriorPasswordsAllowed: number;
  passwordExpiration: number;
  twilioPhoneNo: string;
  twilioSID: string;
  twilioToken: string;
}

interface IUseAuth {
  company: ICompany;
  // profile: ICompany;
  user: any; //CinnoUserAttributes;
  // updateUser(user:Partial<CinnoUserAttributes>):Promise<any>;
  token: string;
  loadUserFromSession: Function;
  setCompany: Function;
  updateNotifications: Function;
  checkCompany(alias: string): Promise<any>;
  // [n: string]: any;
  signin: Function;
  signup: Function;
  updateProfile: Function;
  changePassword: Function;
  forgotPassword: Function;
  verifyOTP: Function;
  resetForgotPasswordFinal: Function;
  // signupLead:Function;
  // reset:Function;
  signout: Function;
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    if (cb) {
      setTimeout(cb, 100); // fake async
    }
  },
  signup(cb) {
    fakeAuth.isAuthenticated = true;
    if (cb) {
      setTimeout(cb, 100); // fake async
    }
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export const useAuth = (): IUseAuth => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  // const [profile, setUserProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [company, _setCompany] = useState(null);

  const setCompany = (data) => {
    // console.log('about to set company -> ', data);
    _setCompany(data);
    storage.set("company", data);
  };

  const signin = (form: any) => {
    return new Promise((resolve, reject) => {
      return api.auth
        .signIn(form)
        .then((res) => {
          // if(res?.data?.pinRequired){
          // 	return reject({pinRequired:true});
          // }else{
          // console.log('signin-> ',res?.response);
          storage.set("token", res?.access_token);
          storage.set("user", res?.user);
          // storage.set('profile', res?.profile);
          storage.set("company", res?.profile);
          storage.set("companyId", res?.company_id);
          setToken(res?.access_token);
          setUser(res?.user);
          // setUserProfile(res?.profile);
          _setCompany(res?.profile);
          resolve(res);
          // }
        })
        .catch((error) => {
          console.log("error->", error);
          return reject({ error });
        });
    });
  };

  const checkCompany = (alias: string) => {
    return new Promise((resolve, reject) => {
      api.auth
        .getCompanyByAlias(alias)
        .then((res) => {
          if (res?.data) {
            storage.set("companyId", res?.data?.id);
            storage.set("company", res?.data);
            _setCompany(res?.data);
            resolve(res?.data);
          }
          // setTimeout(() => {
          //     // history.push('/');
          // },3000);
        })
        .catch((err) => {
          storage.set("company", null);
          storage.set("companyId", null);
          _setCompany(null);
          reject(err);
          // console.log(err?.data?.payload?.error);
          // toast.error(err?.data?.payload?.error);
          // toast('Something went wrong');
        });
    });
  };

  const signup = (form: any, cb) => {
    // console.log('form-> ',form);
    return api.auth
      .signUp(form)
      .then((res) => {
        // console.log('response',res);
        // toast.success("Signup successful! Select Payment Package.");
        // setUser("user");
        storage.set("token", res?.access_token);
        storage.set("user", res);
        storage.set("company", res?.role?.company);
        storage.set("companyId", res?.company_id);

        if (cb) {
          cb(res,true);
        }
        // setTimeout(() => {
        //     history.push('/');
        // }, 0);
      })
      .catch((err) => {
        cb(err,false);
        toast.error(err.data.message[0]);
      });
    // return fakeAuth.signup(() => {
    //   setUser("user");
    //   if(cb){
    //     cb();
    //   }
    // });
  };

  const reset = (form: any, cb) => {
    return api.auth
      .resetPassword(form)
      .then((res) => {
        // console.log('response',res);
        toast.success(res.payload.message || "Success!");
        // setUser("user");
        if (cb) {
          cb();
        }
        // setTimeout(() => {
        //     // history.push('/');
        // },3000);
      })
      .catch((err) => {
        // console.log(err);
        // toast('Something went wrong');
      });
  };

  const forgotPassword = (form: any, cb) => {
    return api.auth
      .forgotPassword(form)
      .then((res) => {
        // console.log('response',res);
        toast.success("Verify OTP in order to reset password");
        // setUser("user");
        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        console.log(err);
        toast('Something went wrong');
      });
  };

  const verifyOTP = (form: any, cb) => {
    return api.auth
      .verifyOTP(form)
      .then((res) => {
        console.log('response',res);
        toast.success("Success! Set your new password!");
        // setUser("user");
        if (cb) {
          cb();
        }
        // setTimeout(() => {
        //     // history.push('/');
        // },3000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message || 'Something went wrong');
      });
  };

  const resetForgotPasswordFinal = (form: any, cb) => {
    return api.auth
      .resetForgotPassword(form)
      .then((res) => {
        console.log('response',res);
        toast.success("Success!");
        // setUser("user");
        if (cb) {
          cb();
        }
        // setTimeout(() => {
        //     // history.push('/');
        // },3000);
      })
      .catch((err) => {
        console.log(err);
        // toast('Something went wrong');
      });
  };

  const changePassword = (form: any, cb) => {
    return api.auth
      .changePassword(form)
      .then((res) => {
        if (res) {
          toast.success("User password changed successful");
          return cb(true);
        }
        return toast.error("Please Try Again !");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const updateProfile = (form: any, cb: Function) => {
    return api.auth
      .updateProfile(form)
      .then((res) => {
        toast.success("User profile update successful");
        storage.set("user", res.user);
        cb(res?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      storage.remove("token");
      storage.remove("user");
      storage.remove("company");
      storage.remove("profile");
      storage.remove("companyId");
      setUser(null);
      setToken(null);
      setCompany(null);
      if (cb) {
        cb();
      }
    });
  };

  const setAvatar = (avatar) => {
    let updatedUser = Object.assign({}, user, {
      avatar: avatar,
    });
    storage.set("user", updatedUser);
    setUser(storage.get("user"));
  };

  const loadUserFromSession = () => {
    setUser(storage.get("user"));
    setToken(storage.get("token"));
    // setUserProfile(storage.get('profile'));
    setCompany(storage.get("company"));
  };

  const updateNotifications = (form: any, cb: Function) => {
    return api.auth
      .updateNotifications(form)
      .then((res) => {
        storage.set("user", res);
        cb(res);
      })
      .catch((err) => {
        console.log("sms error", err);
      });
  };

  // const updateUser = async (data:Partial<CinnoUserAttributes>) => {
  // 	let [err,updatedUser] = await to(api.profile.updateUser(data));
  // 	if(!err){
  // 		setUser({...user,...data});
  // 	}

  // 	if(err){
  // 		throw err;
  // 	}

  // 	return updatedUser;
  // 	//return [err,updatedUser];
  // }

  return {
    setCompany,
    token,
    company,
    user,
    // profile,
    // updateUser,
    signin,
    signup,
    reset,
    forgotPassword,
    signout,
    loadUserFromSession,
    setAvatar,
    checkCompany,
    updateProfile,
    changePassword,
    updateNotifications,
    resetForgotPasswordFinal,
    verifyOTP
  };
};
