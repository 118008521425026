import { memo, useReducer, useCallback, useState } from "react";
import styles from "./Events.module.scss";
import Table from "components/Table";
import Heading from "components/Heading";
import Button from "components/Button";
import TopHeader from "components/TopHeader";
import TopActionBar from "components/TopActionBar";
import Actions from "./Actions";
import SearchBar from "components/SearchBar";
// import faker from 'faker';
import Breadcrumb from "components/BreadCrumbs";
import { useNavigate } from "react-router-dom";

import basicReducer from "reducers/basicReducer";
// import { fetchItems, deleteItem } from 'shared/api/location';
import * as acl from "shared/utils/accessControl";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import { toast } from "react-toastify";
import { getFormattedDateTime } from "shared/utils/common";

var tempSearchValue = "";
const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date Sent",
    accessor: "event_datetime",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableSortBy: true,
    disableFilters: true,
    hideFilter: true,
  },
];

const Locations = (props) => {
  const auth = useAuth();
  const userPermissions = []; //useSelector((state:any) => state?.userPermissions);

  const navigate = useNavigate();
  const [state, setState] = useReducer(basicReducer, {
    list: [],
    pageSize: 10,
    showImportModal: false,
  });
  const [pageCount, setPageCount] = useState(0);

  const onDelete = (id) => {
    const form = {
      companyId: auth.company.id,
      id
    }
    api.events.deleteEvent(form).then(() => {
      toast.success("Successfully deleted");
      loadList({ action: 'loadList' });
    }).catch((err) => {
      toast.error(err)
    })
  };

  const showTracking = () => {
    console.log("showTracking");
  };

  const loadList = ({ action, value, pageSize = 10, pageIndex = 1 }: any) => {
    let filter = { search: value ?? tempSearchValue };
    if (action === "search") {
      tempSearchValue = value;
      // filter = Object.assign({}, { search: value });
    }
    filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
    api.events
      .listEvents({ ...filter, companyId: auth?.company?.id })
      .then((list) => {
        let formattedData = [];
        if (list?.items?.length > 0) {
          formattedData = list?.items?.map((item) => ({
            // title: <span className="link" onClick={() => navigate(`edit/${item?.id}`)}>{item?.title}</span>,
            name: item?.name,
            message: item?.message,
            event_datetime: getFormattedDateTime(item?.event_datetime ?? item?.created_at),
            status: item?.status,
            actions: (
              <Actions
                id={item?.id}
                onDelete={onDelete}
                eventType={item?.event_type}
                showTracking={showTracking}
              />
            ),
            modifiedBy: item?.created_by?.first_name ? `${item?.created_by?.first_name} ${item?.created_by?.last_name}` : "",
            modifiedOn: item?.createdAt,
            id: item?.id,
          }));
          setPageCount(Math.ceil(list.meta?.totalItems / pageSize));
          setState({ totalCount: list.meta?.totalItems });
        }
        setState({ list: formattedData });
      });
  };

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    loadList({ action: "loadList", pageSize, pageIndex: pageIndex + 1 });
  }, []);

  let updatePageSize = (size) => {
    setState({ pageSize: size });
  };

  return (
    <div className={styles.root}>
      <Breadcrumb
        list={[
          {
            name: "Events",
          },
        ]}
      />
      <TopHeader>
        <Heading>Events</Heading>
        <SearchBar onSearch={loadList} pageSize={state.pageSize} />
      </TopHeader>
      {acl.hasPerm(acl.canCreateEvents(), userPermissions) && (
        <TopActionBar>
          <Button onClick={() => navigate("add")}>Add Event</Button>
        </TopActionBar>
      )}
      <Table
        columns={columns}
        data={state?.list}
        pageCount={pageCount}
        fetchData={fetchData}
        parentPageSize={updatePageSize}
        totalCount={state?.totalCount}
      />
    </div>
  );
};

export default memo(Locations);
