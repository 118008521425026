import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './component.module.scss';

class Textarea extends React.Component {
  onChange = e => {
    const { name, value } = e.target;
    this.props.onChange(value, name);
  };

  shouldComponentUpdate(nextProps, nextState) {

    if (this.props.value !== nextProps.value) {
      return true;
    }

    if (this.props.name && this.props.name !== nextProps.name) {
      return true;
    }

    return false;
  }

  onClick = e => {
    const { name, value } = e.target;
    if (this.props && this.props.onClick) {
      this.props.onClick(name, value);
    }
  }

  render() {
    const { name, 
            value, 
            placeholder, 
            className,
            readonly, 
            bordered,
            style = {}
     } = this.props;

    // console.log(field, " Text area rendered");
    return (
        <textarea
          style={style}
          className={classNames(styles.root,className, {
            [styles.bordered]: bordered,
            [styles.invalid]: this.props.invalid,
            [styles.readonly]: readonly,
            [styles.normaltextAreaHeight]: this.props.normalHeight
          })}
          // className={classNames(className)}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readonly}
          onChange={this.onChange}
          onClick={this.onClick}
         
        />
    );
  }
}

// Textarea.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   invalid: PropTypes.string,
//   readonly: PropTypes.bool,
//   bordered: PropTypes.bool,
//   onChange: PropTypes.func,
//   onClick: PropTypes.func,
//   normalHeight: PropTypes.bool
// };

Textarea.defaultProps = {
  placeholder: '',
  invalid: null,
  readonly: false,
  bordered: false,
  name: '',
  onChange: () => false,
  normalHeight: false
};

export default Textarea;
