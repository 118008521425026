import HomeWidget from "components/HomeWidget";
import { memo, useEffect, useState } from "react";
import { BsCalendarEventFill } from "react-icons/bs";
import { FiLock, FiUsers } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdAdminPanelSettings } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import styles from "./Home.module.scss";
import { useAuth } from "components/ProvideAuth/hook";
import _contacts from 'assets/icons/_contacts.png';
import _locations from 'assets/icons/_locations.png';
import _role from 'assets/icons/_role.png';
import _events from 'assets/icons/_events.png';
import _users from 'assets/icons/_users.png';
import _settings from 'assets/icons/_settings_.png';
import _templates from 'assets/icons/_templates_.png';

interface IMenu {
  title: string;
  subTitle: string;
  icon: any;
  location?: string;
}

const menu = [];

const Home = (props) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [dashboardStats, setDashboardStats] = useState(null);

  useEffect(() => {
    api.stats
      .getDashboardStats({ companyId: auth?.company?.id })
      .then?.((stats) => {
        if (stats) {
          setDashboardStats(stats);
        }
      });
  }, []);

  return (
    <div className={styles.root}>
      <div>
        <h3 className={styles.heading}>Welcome to Emeva</h3>
        <div className={styles.widgets}>
          <HomeWidget
            onClick={() => navigate("/events/")}
            backgroundColor="#fff"
            label="Events"
            icon={
              <img style={{ height: 70 }} src={_events} alt="Emeva" />
            }
          >
            {dashboardStats?.events} Events
          </HomeWidget>
          <HomeWidget
            onClick={() => navigate("/locations/")}
            backgroundColor="#fff"
            label="Locations"
            icon={
              <img style={{ height:70 }} src={_locations} alt="Emeva" />
            }
          >
            {dashboardStats?.locations} Locations
          </HomeWidget>
          <HomeWidget
            onClick={() => navigate("/admin/contacts")}
            backgroundColor="#fff"
            label="Contacts"
            icon={
              <img style={{ height: 70 }} src={_contacts} alt="Emeva" />
            }
          >
            {dashboardStats?.contacts} Contacts
          </HomeWidget>
        </div>
      </div>

      <div>
        <h3 className={styles.heading}>Administration</h3>
        <div className={styles.widgets}>
          {/* <HomeWidget onClick={() => navigate('/administration')} label="Administration" icon={<MdAdminPanelSettings size={30} style={{ cursor: 'pointer' }} />}>2 Users,2 Roles</HomeWidget> */}

          <HomeWidget
            onClick={() => navigate("/admin/users")}
            backgroundColor="#fff"
            label="Users"
            icon={
                <img style={{ height: 70 }} src={_users} alt="Emeva" />
            }
          >
            {dashboardStats?.users} Users
          </HomeWidget>
          <HomeWidget
            onClick={() => navigate("/admin/roles")}
            label="Roles"
            backgroundColor="#fff"
            icon={
              <img style={{ height: 70 }} src={_role} alt="Emeva" />
            }
          >
            {dashboardStats?.roles} Roles
          </HomeWidget>
          <HomeWidget
            onClick={() => navigate("/admin/templates")}
            label="Templates"
            backgroundColor="#fff"
            icon={
              <img style={{ height: 70 }} src={_templates} alt="Template" />
            }
          >
            {dashboardStats?.templates} Templates
          </HomeWidget>
          <HomeWidget
            onClick={() => navigate(`/company/edit/${auth?.user?.uuid}/credits`)}
            label="Settings"
            backgroundColor="#fff"
            icon={
              <img style={{ height: 70 }} src={_settings} alt="Credits" />
            }
          >
            {"-"}
          </HomeWidget>

          {/* <HomeWidget onClick={() => navigate('/contacts/')} backgroundColor='#CBD0FF' label="Contacts" icon={<IoMdContacts size={30} style={{ cursor: 'pointer' }} />}>5 Contacts</HomeWidget> */}
        </div>
      </div>
    </div>
  );
};

export default memo(Home);
