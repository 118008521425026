import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import styles from "./Stripe.module.scss";
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import { useState } from 'react';
import api from 'api';
import storage from 'shared/utils/storage';

const CheckoutForm = ({login = false}) => {
  const [IsLoading,setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!elements.getElement(PaymentElement)) {
      toast.error("Card information is missing.");
      return;
    }
    
    api.payment.paymentSuccess({payment_intent :"", payment_intent_client_secret: "success"}).then((res)  =>  {
      if (res)
      {

      }
    })

    setLoading(true);
    if (!login){
      storage.remove("token");
      storage.remove("user");
      storage.remove("company");
      storage.remove("profile");
      storage.remove("companyId");
    }
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:  process.env.REACT_APP_URL,
      },
    });


    if (result.error) {
      console.error(result.error.message);
      setLoading(false);
      api.payment.paymentSuccess({payment_intent :"", payment_intent_client_secret: "error"}).then((res)  =>  {})
      //toast.error("Something went  wrong during payment!");
    }
  };

  return (
    <>
        <Loading fixed={IsLoading} size="sm" type="ball-pulse-sync" style={{zIndex : 9999}}/>
    {elements && <form id="pyment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className={styles.submitButton}>Submit</button>
    </form>}
    </>
  )
};

export default CheckoutForm;