import {
  memo,
  useEffect,
  useReducer,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Contacts.module.scss";
import Table from "components/Table";
import Heading from "components/Heading";
import Button from "components/Button";
import TopHeader from "components/TopHeader";
import SearchBar from "components/SearchBar";

import TopActionBar from "components/TopActionBar";
import Actions from "./Actions";
// import faker from 'faker';
import Breadcrumb from "components/BreadCrumbs";
// import { fetchItems, deleteItem, unlockItem } from 'shared/api/users';
import basicReducer from "reducers/basicReducer";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import { toast } from 'react-toastify';
import { UserType } from "types";
import Select from "components/Form/Select";

var tempSearchValue = "";
const Users = (props) => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [state, setState] = useReducer(basicReducer, {
    list: [],
    pageSize: 10,
    locations: [],
    selected_location: "all",
  });

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Modified By",
        accessor: "modifiedBy",
      },
      {
        Header: "Modified On",
        accessor: "modifiedOn",
        type: "date",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
        hideFilter: true,
      },
    ],
    []
  );

  const deleteUser = (id) => {
    api.users
      .deleteUser({ companyId: auth.company.id, userId: id })
      .then((deleted) => {
        toast.success("Deleted Successfully");
        loadRecord({ action: "loadList", location: state.selected_location });
      });
  };

  const unlockUser = (id) => {
    // unlockItem(id).then(unlocked => {
    // 	toast.success(unlocked.payload.message);
    // 	loadRecord({ action: 'loadList' });
    // });
  };

  useEffect(() => {
    api.locations
      .listLocations({ companyId: auth?.company?.id, getAll: true })
      .then?.((locations) => {
        if (locations?.length > 0) {
          let locationsToUpdate = locations?.map?.((location) => ({
            value: location?.id,
            label: location?.title,
          }));

          locationsToUpdate.sort((a, b) => (a.label > b.label ? 1 : -1));
          locationsToUpdate.unshift({ value: "all", label: "All" });
          setState({ locations: locationsToUpdate });
        }
      });
  }, []);

  const loadRecord = ({
    action,
    value,
    pageSize = 10,
    pageIndex = 1,
    location = null,
  }: any) => {
    let filter = { search: value ?? tempSearchValue, location };
    if (action === "search") {
      tempSearchValue = value;
      // filter = Object.assign({}, { search: value });
    }

    if (location !== "all") {
      filter.location = location;
    }

    filter = Object.assign(filter, { limit: pageSize || 10, page: pageIndex });
    api.contacts
      .listContacts({ ...filter, companyId: auth?.company?.id })
      .then((users) => {
        let formattedData = [];
        if (users?.meta?.totalItems > 0) {
          formattedData = users?.items?.map((item) => {
            item.modifiedBy = item?.modifiedBy?.fullName;
            let roleName = item?.role?.title;
            let locations = item?.userToLocations
              ?.map?.((l) => l?.location?.title)
              ?.join?.(", ");
            let actions = (
              <Actions
                id={item?.uuid}
                deleteItem={deleteUser}
                unlockItem={null}
              />
            );

            if (
              item?.user_type === "admin" ||
              item?.user_type === "superAdmin"
            ) {
              roleName = "Admin";
              locations = "ALL";
              actions = <div>No Actions for Admin</div>;
            }

            return {
              ...item,
              id: item?.uuid,
              // roleName,
              locations,
              // companyName: i?.company?.name,
              actions,
              modifiedBy: item?.updated_by?.first_name ? `${item?.updated_by?.first_name} ${item?.updated_by?.last_name}` : item?.created_by?.first_name ? `${item?.created_by?.first_name} ${item?.created_by?.last_name}` : "",
              modifiedOn: item?.updated_at,
            };
          });
          setPageCount(Math.ceil(users.meta.totalItems / pageSize));
          setState({ totalCount: users.meta.totalItems });
        }
        setData(formattedData);
      });
  };

  const setLocations = (v: Array<{ value; label }>) => {
    let hasAll = v?.find?.((l) => l?.value === "all");
    if (hasAll) {
      setState({
        locations_ids: state?.locations
          ?.filter?.((l) => l?.value !== "all")
          ?.map?.((i) => i?.value),
      });
    } else {
      setState({ locations_ids: v?.map?.((i) => i?.value) });
    }
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      loadRecord({
        action: "loadList",
        pageSize,
        pageIndex: pageIndex + 1,
        location: state.selected_location,
      });
    },
    [state.selected_location]
  );

  let updatePageSize = (size) => {
    setPageSize(size);
  };

  const changeSelectedLocation = (location) => {
    setState({ ...state, selected_location: location });
  };

  return (
    <div className={styles.root}>
      <Breadcrumb
        list={[
          {
            name: "Contacts",
          },
        ]}
      />
      <TopHeader>
        <Heading>Contacts</Heading>
        <div style={{ display: "flex" }}>
          <Select
            isMultiple={false}
            value={state?.selected_location}
            options={state?.locations}
            onChange={(e) => {
              changeSelectedLocation(e.value);
            }}
          />
          <SearchBar onSearch={loadRecord} pageSize={pageSize} />
        </div>
      </TopHeader>
      <TopActionBar>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={() => navigate("add")}>Add Contact</Button>
          <Button onClick={() => navigate("import")}>Import Contacts</Button>
        </div>
      </TopActionBar>
      <Table
        columns={columns}
        data={data}
        pageCount={pageCount}
        fetchData={fetchData}
        parentPageSize={updatePageSize}
        totalCount={state?.totalCount}
      />
    </div>
  );
};

export default memo(Users);
