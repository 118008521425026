import {memo} from 'react';
// import styles from './Switch.module.scss';
import ReactSwitch from "react-switch";

const Switch = ({checked,onChange}:{checked:boolean;onChange:any;}) => {

    return (
        <ReactSwitch
            onColor="#1C66AC"
            handleDiameter={12}
            height={18}
            width={28}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={checked ? true : false}
            onChange={onChange}
            // id="isRequired"
        />
    );

};

export default memo(Switch);