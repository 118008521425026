import { memo, useEffect, useReducer, useState } from "react";
import { Country, State, City } from "country-state-city";
import styles from "./EditLocation.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "components/BreadCrumbs";
import Select from "components/Form/Select";
// import PreviewLocationFile from './PreviewLocation';

import { toast } from "react-toastify";
import Input from "components/Form/Input";
import Group from "components/Group";
import Button from "components/Button";
import Heading from "components/Heading";
// import faker from 'faker';
// import { saveItem,getItemById } from 'shared/api/location';
import basicReducer from "reducers/basicReducer";
// import { copyAttr } from 'shared/utils/common';
import Validator from "shared/utils/form-validator";
import api from "api";
import { useAuth } from "components/ProvideAuth/hook";
import Textarea from "components/Form/Textarea";
import { FaTrash } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import PreviewImageModal from "../PreviewImageModal";
import Preview from "./PreviewImageModal/Preview";

let validator = new Validator({
  title: {
    required: true,
    message: "Location title is required",
  },
});

let usStates = State.getStatesOfCountry("US");
let usstatesOptions = usStates?.map((s) => ({
  value: s?.isoCode,
  label: s?.name,
}));

const EditGroup = (props) => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  let [state, setState] = useReducer(basicReducer, {
    errors: {},
    submitInProgress: false,
    states: usstatesOptions,
  });
  const [form, setForm] = useReducer(basicReducer, {
    id: "",
    title: "",
    address: "",
    city: "",
    state: "",
    country: "US",
    primary_assembly_point: "",
    secondary_assembly_point: "",
    zip: "",
  });
  const [forumData, setForumData] = useState<any>();
  const [indexOpen, setIndexOpen] = useState<number>();
  const [fileType, setFileType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [locationFiles, setLocationFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [fileNameModal, setFileNameModal] = useState("");
  const [backendFileId, setBackendFileId] = useState();

  const isValid = () => {
    let [isValid, errors] = validator.isValid(form);

    setState({
      errors,
    });
    return { isValid, errors };
  };

  const saveOrCreate = async () => {
    let results = [];
    if (attachments?.length) {
      const apiPromises = attachments.map((att) =>
        api.locations.uploadFile(auth?.company?.id, att)
      );
      results = await Promise.all(apiPromises);
      console.log(results, fileNames);
    }

    const isValidResponse = isValid();
    // console.log('isValidResponse-> ',isValidResponse);
    if (!isValidResponse.isValid) {
      setState({ submitInProgress: false });

      for (let key of Object.keys(isValidResponse.errors)) {
        toast.error(isValidResponse.errors[key]);
        break;
      }
      return;
    }

    let _toSave = {
      ...form,
      companyId: auth?.company?.id,
      locationFiles: results,
    };
    if (typeof _toSave.id !== "undefined" && !_toSave?.id) {
      delete _toSave?.id;
    }

    if (_toSave?.id) _toSave.existingFiles = locationFiles;

    let saveItem = !_toSave?.id
      ? api.locations.createLocation
      : api.locations.updateLocation;

    saveItem(_toSave).then((saved) => {
      console.log("saved->", saved);
      // if(saved && saved?.payload?.data?.id){
      if (_toSave?.id) {
        toast.success(`Changes saved`);
        navigate(`/locations/`);
      } else {
        navigate(`/locations/`);
        // navigate(`/locations/edit/${saved?.payload?.data?.id}`)
        toast.success(`Location successfully created`);
      }
      // navigate(`../edit/${saved?.payload?.data?.id}`);
      // }
    });
  };

  useEffect(() => {
    if (form?.country) {
      let states = State.getStatesOfCountry(form?.country);
      // console.log('states-> ',states);
      setForm({ state: "" });
      setState({
        states: states?.map((s) => ({ value: s?.isoCode, label: s?.name })),
      });
    }
  }, [form?.country]);

  useEffect(() => {
    if (params?.id) {
      api.locations
        .getLocation({ id: params?.id, companyId: auth?.company?.id })
        .then((locationDetails) => {
          if (locationDetails) {
            setForm(locationDetails);
            if (locationDetails?.locationFiles) {
              setLocationFiles(locationDetails?.locationFiles);
            }
          }
        });
    }
  }, [params?.id]);

  const onSavePreview = () => {
    if (forumData && !forumData?.file) {
      locationFiles[indexOpen].file_name = fileNameModal;
      setLocationFiles([...locationFiles]);
      setIsModalOpen(false);
      return;
    }

    if (forumData && forumData?.file) {
      const updatedFile = new File([forumData.file], fileNameModal, {
        type: forumData.file.type,
      });
      const obj = {
        ...forumData,
        file: updatedFile,
      };
      setForumData(obj);

      if (backendFileId) {
        api.locations
          ?.deletLocationFile({
            companyId: auth?.company?.id,
            id: backendFileId,
          })
          .then(() => {
            locationFiles.splice(indexOpen, 1);
            setLocationFiles([...locationFiles]);
            attachments.push(obj);
            setAttachments([...attachments]);
            setIsModalOpen(false);
          });
        setBackendFileId(undefined);
      } else {
        if (indexOpen < 0) {
          if (forumData.file && fileNameModal) {
            attachments.push(obj);
            setAttachments([...attachments]);
          }
          setIsModalOpen(false);
          return;
        }
        attachments[indexOpen] = obj;
        setAttachments([...attachments]);
        setIsModalOpen(false);
      }
    }
  };

  const handleFilePreview = (event: any) => {
    const files = event.target.files;
    if (!files?.length) return;
    if (files?.length > 1)
      return toast.error("Please attach one file at a time");
    else {
      setFileNameModal(files[0]?.name);
      const formDatum = {
        file: files[0],
        isFileUpload: true,
      };
      setForumData(formDatum);
      const file = formDatum?.file;
      if (!file) {
        setPreviewUrl(null);
        return;
      }

      setFileType(file?.type);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
        setIsModalOpen(true);
      };

      const supportedFileTypes = ["image", "application/pdf"];
      if (file && supportedFileTypes.some((type) => file.type.includes(type))) {
        return fileReader.readAsDataURL(file);
      } else if (file?.type === "text/csv") {
        return fileReader.readAsText(file);
      } else
        return toast.error(
          "Please choose file in (PNG,JPG,GIF,SVG, PDF and CSV )"
        );
    }
  };

  const removeFile = (index: number) => {
    console.log(index);
    if (index > -1) {
      attachments.splice(index, 1);
      setAttachments([...attachments]);
      setPreviewUrl(null);
    }
  };

  const removeFileBackend = (fileId: number, index: number) => {
    api.locations
      ?.deletLocationFile({ companyId: auth?.company?.id, id: fileId })
      .then(() => {
        locationFiles.splice(index, 1);
        setLocationFiles([...locationFiles]);
      });
  };

  const previewFile = (index: number, isUrl = false) => {
    if (!isUrl) {
      if (index > -1) {
        const file = attachments[index]?.file;
        if (!file) {
          setPreviewUrl(null);
          return;
        }

        setFileType(file?.type);
        const fileReader = new FileReader();
        fileReader.onload = () => {
          setPreviewUrl(fileReader.result);
          setFileNameModal(file?.name);
          setIndexOpen(index);
          setIsModalOpen(true);
        };

        const supportedFileTypes = ["image", "application/pdf"];
        if (
          file &&
          supportedFileTypes.some((type) => file.type.includes(type))
        ) {
          return fileReader.readAsDataURL(file);
        } else if (file?.type === "text/csv") {
          return fileReader.readAsText(file);
        } else
          return toast.error(
            "Please choose file in (PNG,JPG,GIF,SVG, PDF and CSV )"
          );
      }
    } else {
      const file = locationFiles[index];
      setPreviewUrl(
        `${process.env.REACT_APP_API_URL}uploads/locations${file?.file_path}`
      );
      setFileType(file?.type);
      setFileNameModal(file?.file_name);
      setIndexOpen(index);
      setBackendFileId(file?.id);
      setIsModalOpen(true);
    }
  };

  const ChangeFileName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (index > -1) {
      const fileName = e.target.value;
      const file = attachments[index].file;
      const updatedFile = new File([file], fileName, { type: file.type });
      const updatedAttachment = { ...attachments[index], file: updatedFile };
      const updatedAttachments = [...attachments];
      updatedAttachments[index] = updatedAttachment;
      setAttachments(updatedAttachments);
    }
  };

  const editFileName = (e: any, index: number): void => {
    if (index > -1) {
      const fileName = e.target.value;
      locationFiles[index].file_name = fileName;
      setLocationFiles([...locationFiles]);
    }
  };

  const manageFiles = () => {
    setPreviewUrl(undefined);
    setFileType(undefined);
    setIndexOpen(-1);
    setFileNameModal("");
    setIsModalOpen(true);
  };

  return (
    <div className={styles.root}>
      <Breadcrumb
        list={[
          {
            name: "Locations",
            url: "locations",
          },
          {
            name: `${params?.id ? "Edit" : "Add"} Location`,
          },
        ]}
      />
      <Heading>{params?.id ? "Edit" : "Add"} Location</Heading>

      <Group label="Location Name">
        <Input value={form?.title} onChange={(title) => setForm({ title })} />
      </Group>
      <Group label="">
        <Group label="Address">
          <Input
            value={form?.address}
            onChange={(address) => setForm({ address })}
          />
        </Group>
        <Group label="" vertical={false}>
          <Group label="City" style={{ marginRight: 10 }}>
            <Input value={form?.city} onChange={(city) => setForm({ city })} />
          </Group>
          <Group label="State">
            <Select
              value={form?.state}
              options={state?.states}
              onChange={(v) => setForm({ state: v.value })}
            />
          </Group>
        </Group>
        <Group vertical={false}>
          <Group label="Zip" style={{ marginRight: 10 }}>
            <Input value={form?.zip} onChange={(zip) => setForm({ zip })} />
          </Group>
          <Group label="Country">
            <Select
              value={form?.country}
              options={Country.getAllCountries()?.map((country) => ({
                value: country?.isoCode,
                label: country?.name,
              }))}
              onChange={(v) => setForm({ country: v.value })}
            />
          </Group>
        </Group>
        <Group label="Primary Assembly Point">
          <Textarea
            value={form?.primary_assembly_point}
            onChange={(primary_assembly_point) =>
              setForm({ primary_assembly_point })
            }
          />
        </Group>
        <Group label="Secondary Assembly Point">
          <Textarea
            value={form?.secondary_assembly_point}
            onChange={(secondary_assembly_point) =>
              setForm({ secondary_assembly_point })
            }
          />
        </Group>
      </Group>
      {(attachments.length > 0 || locationFiles?.length > 0) && (
        <Group label="Attachments">
          {attachments.map((attachment, index) => {
            return (
              <div key={`attach-${index}`} className={styles.fileContainer}>
                <div>
                  <label className={styles.locationLabel}>
                    <span onClick={() => previewFile(index)}>
                      {attachment?.file?.name}
                    </span>
                  </label>
                </div>
                <Tooltip title="Remove" placement="top">
                  <span
                    className={styles.cursorPointer}
                    onClick={() => removeFile(index)}
                  >
                    <FaTrash />
                  </span>
                </Tooltip>
              </div>
            );
          })}
          {locationFiles.map((locFile, index) => {
            return (
              <div key={`file-${index}`} className={styles.fileContainer}>
                <div key={`file-${index}`}>
                  <label className={styles.locationLabel}>
                    <span onClick={() => previewFile(index, true)}>
                      {locFile?.file_name}
                    </span>
                  </label>
                </div>
                <Tooltip title="Remove" placement="top">
                  <span
                    className={styles.cursorPointer}
                    onClick={() => removeFileBackend(locFile?.id, index)}
                  >
                    <FaTrash />
                  </span>
                </Tooltip>
              </div>
            );
          })}
        </Group>
      )}
      {isModalOpen && (
        <Preview
          name={fileNameModal}
          setFileNameModal={setFileNameModal}
          previewUrl={previewUrl}
          setFileModal={setIsModalOpen}
          fileType={fileType}
          fileModal={isModalOpen}
          handleFile={handleFilePreview}
          onSavePreview={onSavePreview}
        />
      )}
      <Button onClick={() => manageFiles()} style={{ width: "121px" }}>
        Add New File
      </Button>
      <Group>
        <Button onClick={() => navigate("/locations")} view="cancel">
          Cancel
        </Button>
        <Button onClick={saveOrCreate} style={{ marginLeft: 10 }}>
          Save
        </Button>
      </Group>
    </div>
  );
};

export default memo(EditGroup);
