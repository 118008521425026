import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import api from 'api';
import { useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export default function Stripe({prices,credits , login = false}) {
  const [strPromise , setStrPromise] = useState(stripePromise)
  const [options, setOptions] = useState<any>();
  const [price,setPrice] = useState(prices);

  useEffect(() => {
    if (stripePromise){
      setStrPromise(stripePromise)
      api.payment.changePaymentPlan({ price,credits }).then((res)=>{
        if (res?.clientSecret)
          setOptions({clientSecret : res.clientSecret})
      })
    }
  },[stripePromise, credits , price])

  useEffect(() => {
    if (prices)
      setPrice(prices)
  },[prices])

  return (
    <div style={{width :"50%", margin: "auto" , marginTop: "5%"}}>
      {strPromise && options && <Elements stripe={strPromise} options={options}>
        <CheckoutForm login={login}/>
      </Elements>}
    </div>
 
  
  );
};