import React, { useReducer, useContext, useEffect, useState } from "react";
import styles from "./Profile.module.scss";
import Breadcrumb from "components/BreadCrumbs";
import Card from "components/Card";
import { Grid, Box } from "@mui/material";
import basicReducer from "reducers/basicReducer";
import Input from "components/Form/Input";
import Group from "components/Group";
import Button from "components/Button";
import Heading from "components/Heading";
import { AntSwitch } from "./Toggle";
import authContext from "components/ProvideAuth/context";
import { useAuth } from "components/ProvideAuth/hook";
import { to } from "shared/utils/common";
const UserProfile = () => {
  const auth = useAuth();
  const userContext = useContext(authContext);
  const [profileResponse, setProfileResponse] = useState(false);
  const {
    first_name,
    last_name,
    contact,
    uuid,
    email,
    notify_via_email,
    notify_via_sms,
  } = userContext?.user;
  const [form, setForm]: [any, any] = useReducer(basicReducer, {
    first_name: "",
    last_name: "",
    contact: "",
    uuid: "",
    email: "",
    notify_via_email: true,
    notify_via_sms: true,
  });
  const [changePassword, setChangePassword] = useReducer(basicReducer, {
    password: "",
    newPassword: "",
    uuid: "",
  });

  useEffect(() => {
    setForm({
      first_name,
      last_name,
      contact,
      uuid,
      email,
      notify_via_email,
      notify_via_sms,
    });
    setChangePassword({ uuid });
  }, [userContext, profileResponse]);

  const updateProfileHandle = async () => {
    let [err, response] = await to(
      auth.updateProfile(form, reRenderUserProfile)
    );
  };

  const changePasswordHandle = async () => {
    let [err, response] = await to(
      auth.changePassword(changePassword, reSetPasswordFields)
    );
  };
  const reRenderUserProfile = (res: any) => {
    userContext.user = res;
    setProfileResponse(!profileResponse);
  };

  const reSetPasswordFields = (res: boolean) => {
    if (res) {
      setChangePassword({
        password: "",
        newPassword: "",
        uuid: "",
      });
    }
  };

  const sendNotifications = async (key: string) => {
    let [err, response] = await to(
      auth.updateNotifications({ uuid, key }, reRenderUserProfile)
    );
  };

  return (
    <div className={styles.root}>
      <Breadcrumb
        list={[
          {
            name: "UserProfile",
            url: "home/profile",
          },
        ]}
      />
      <Heading>User Profile</Heading>
      <Grid container columnSpacing={{ xs: 2, sm: 2, md: 4 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Card
            style={{
              width: "100%",
              minHeight: "67vh",
              display: "block",
            }}
          >
            <Group label="First Name">
              <Input
                value={form?.first_name}
                onChange={(first_name) => setForm({ first_name })}
              />
            </Group>
            <Group label="Last Name">
              <Input
                value={form?.last_name}
                onChange={(last_name) => setForm({ last_name })}
              />
            </Group>

            <Group label="Mobile Number">
              <Input
                value={form?.contact}
                onChange={(contact) => setForm({ contact })}
              />
            </Group>
            <Group label="Email">
              <Input
                value={form?.email}
                onChange={() => {
                  return false;
                }}
                readOnly={true}
              />
            </Group>

            <Group>
              <Button
                style={{
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  marginTop: "7%",
                  fontWeight: "bold",
                }}
                onClick={updateProfileHandle}
              >
                <span>Update</span>
              </Button>
            </Group>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card
            style={{
              width: "100%",
              minHeight: "67vh",
              display: "block",
            }}
          >
            <Group label="Current Password">
              <Input
                type="password"
                marginLeft="30%"
                value={changePassword?.password}
                onChange={(password) => setChangePassword({ password })}
              />
            </Group>{" "}
            <Group label="New Password">
              <Input
                type="password"
                marginLeft="30%"
                value={changePassword?.newPassword}
                onChange={(newPassword) => setChangePassword({ newPassword })}
              />
            </Group>{" "}
            <Group>
              <Button
                style={{
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  marginTop: "9%",
                  fontWeight: "bold",
                }}
                onClick={changePasswordHandle}
              >
                <span>Change Password</span>
              </Button>
            </Group>
            <Group>
              <p style={{ fontWeight: "bold" }}>Notifications Settings</p>
              <Box
                sx={{
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Email Notifications</p>
                <AntSwitch
                  checked={notify_via_email}
                  onClick={() => {
                    sendNotifications("email");
                  }}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Box>

              <Box
                sx={{
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>SMS Notifications</p>
                <AntSwitch
                  onClick={() => {
                    sendNotifications("sms");
                  }}
                  checked={notify_via_sms}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Box>
            </Group>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserProfile;
