import { memo } from "react";
// import styles from './ImportCsvModal.module.scss';
import Modal from "components/Modal";
import UploadButton from "components/UploadButton";
import Button from "components/Button";
import internalApi from "shared/utils/internalApi";
import { toast } from 'react-toastify';
// import { importLocations } from 'shared/api/location';

const ImportCsvModal = ({ onClose }) => {
  const onFileUploaded = (file, accept) => {
    if (file) {
      if (file.size <= 50000000) {
        // console.log('accept', accept);
        internalApi
          .uploadAsset("CsvFile", file, { accept })
          .then(async ({ payload }) => {
            // assetId: payload?.item?.id
            // console.log('payload->',payload);
            let assetId = payload?.item?.id;
            // importLocations(assetId).then(imported => {
            //     // console.log('imported-> ',imported);
            //     notify("Locations imported successfully");
            //     onClose();
            // }).catch(err => {
            //     console.log(err);
            // })
          });
      } else {
        toast.error("File size is too large");
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={true}>
      <div>
        <h2>Import your csv file</h2>
      </div>
      <div>
        Csv header must contain these values: <br />
        <br />
        Name, Address 1, Address 2, City, State, Country, Zip
      </div>
      <div
        style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
      >
        <UploadButton
          name={"file"}
          value={""}
          label={"Upload csv file from your computer"}
          accept={"csv"}
          onUpload={onFileUploaded}
        />
        <Button view="secondary" style={{ marginLeft: 10 }} onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ImportCsvModal);
