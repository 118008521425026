import { memo, useState } from "react";
import cx from "classnames";
import Loading from "../Loading";
import styles from "./Button.module.scss";
// import swal from "sweetalert";
// import swalr from '@sweetalert/with-react';
import Modal from "./../Modal";
import { ObjectValues } from "shared/utils/common";

const ButtonViewType = Object.freeze({
  primary: "primary" as const,
  secondary: "secondary" as const,
  link: "link" as const,
  linkDanger: "linkDanger" as const,
  cancel: "cancel" as const,
  danger: "danger" as const,
  success: "success" as const,
  white: "white" as const,
  transparent: "transparent" as const,
  transparentWithBorder: "transparentWithBorder" as const,
});

type ButtonViewType = ObjectValues<typeof ButtonViewType>;

const Button = ({
  children,
  view = "primary",
  contentDirection = "left",
  size = "",
  disabled = false,
  isLoading = false,
  onClick = null,
  requireConfirmation = false,
  confirmationMessage = "Are you sure you want to delete this item?",
  ...rest
}: {
  view?: ButtonViewType;
  children;
  contentDirection?: string;
  size?: any;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: any;
  requireConfirmation?: boolean;
  confirmationMessage?: string;
  [n: string]: any;
}) => {
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const onButtonClick = (e) => {
    if (onClick && !disabled) {
      if (requireConfirmation) {
        toggleDeleteModal(true);
      } else {
        onClick(e);
      }
    }
  };

  const hideDeleteModal = () => {
    toggleDeleteModal(false);
  };

  return (
    <>
      <div
        {...rest}
        onClick={onButtonClick}
        className={cx(
          styles.root,
          styles?.[view],
          styles?.[size],
          disabled ? styles.disabled : ""
        )}
      >
        {isLoading && (
          <>
            <Loading
              isLight={true}
              size="sm"
              fixed={false}
              type="ball-pulse-sync"
            />
          </>
        )}
        {!isLoading && (
          <div className={cx(styles.inner, styles?.[contentDirection])}>
            {children}
          </div>
        )}
      </div>
      {showDeleteModal && (
        <Modal isOpen={true} title={`Confirmation`} onClose={hideDeleteModal}>
          <div dangerouslySetInnerHTML={{ __html: confirmationMessage }} />
          <div style={{ paddingTop: 20, textAlign: "right" }}>
            <Button
              style={{ marginRight: 10 }}
              onClick={(e) => {
                hideDeleteModal();
                onClick(e);
              }}>
              Yes
            </Button>
            <Button view="secondary" onClick={hideDeleteModal}>
              No
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default memo(Button);
