import { memo, useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
// import ClickOutside from 'react-click-outside';
import styles from './Dropdown.module.scss';
import cx from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';

const Dropdown = ({ children, title = 'Type here', header = null, top = 40, right = 0,left = null, customStyling = '', showMenu = false, onMenuVisibilityChange = null }) => {
    const [isOpen, setIsOpen] = useState(showMenu);
    const el = useRef(null);

    // const ref = useRef();
    // State for our modal
    // const [isModalOpen, setModalOpen] = useState(false);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(el, () => setMenuVisibility(false));

    useEffect(() => {
        if(showMenu !== isOpen){
            setIsOpen(showMenu);
        }
    },[showMenu]);


    const setMenuVisibility = value => {
        setIsOpen(value);
        if(onMenuVisibilityChange){
            setTimeout(() => {
                onMenuVisibilityChange(value);
            },0);
        }
    }
    // const closeMenu = e => {
    //     // console.log('about to close',e);

    //     // if (!el.current.contains(e.target)) {
    //         // console.log('closed',e);
    //         setIsOpen(false);
    //     // }
    // }

    const toggleMenu = (e) => {
        // console.log('toggle menua',isOpen);
        e.preventDefault();
        e.stopPropagation();
        setMenuVisibility(!isOpen);
    }
    return (
        <div ref={el} className={styles.ddWrapper}>
            <div onClick={toggleMenu} className={styles.ddHeader}>
                {header}
                {!header && (
                    <>
                        <span>{title}</span>
                        <span><FaChevronDown /></span>
                    </>
                )}
            </div>
            {isOpen && (
                <>
                    <div onClick={() => setMenuVisibility(false)} className={styles.overlay}></div>
                    <div style={{ top, right,left }} className={cx(styles.ddList, customStyling)}>
                        <div>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </div>
    );

};

export default memo(Dropdown);