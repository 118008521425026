// import defaultConfig from '../config/default';
import getEnvironment, { getEnvItem } from './getEnvironment';

const env = getEnvironment();

class CinnoConfig {

  get apiUrl(){
    return getEnvItem('API_URL');
  }
  get wsUrl(){
    return getEnvItem('WS_URL');
  }

}

let cinnoConfig = new CinnoConfig();
export default cinnoConfig;

// const environmentConfig = require(`../config/${env}`).default;

// let defaultConfig = {};

// const config = Object.assign({}, defaultConfig, environmentConfig);

// export default path => {
//   const pathParts = path.split('.');

//   return pathParts.reduce((parent, current) => parent[current], config);
// };
