class Storage {
  set = (key, item) => {

    try {
      sessionStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      window.console.error(e);
    }
  }

  get = (key) => {

    let result = null;
    let value = sessionStorage.getItem(key);
    if(typeof value !== 'undefined' && value !== null){
      try {
        result = JSON.parse(value);
        if(result){
          if(typeof result === 'string' && result.substr(0,1) === '"' && result.substr(-1,1) === '"'){
            result = result.substr(1);
            result = result.substr(0,result.length-1);
          }
        }
      } catch (e) {
        // window.console.error(e);
      }
  
    }
    return result;
  }

  remove = key => {

    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      window.console.error(e);
    }
  }
};

let storage = new Storage();
export default storage;