import {memo} from 'react';
// import PrivateRoute from 'components/PrivateRoute';
import AppLayout from 'layout/AppLayout';
import AppRoutes from 'routes/App/AppRoutes';
import { useOnMount } from 'hooks/useOnMount';
// import { useModuleGroup } from 'atoms/moduleGroups/hook';
import api from 'api/index';
// import { useSetTableList } from 'atoms/module/hook';
import { to } from 'shared/utils/common';
// import { Provider } from 'react-redux';
// import store from 'shared/utils/store';
// import { store } from 'shared/store/store';
// import IdleLogout from 'components/IdleLogout';
// import styles from './App.module.scss';

const App = props => {

    // let setTable = useSetTableList();
    // let [moduleGroup,setModuleGroup] = useModuleGroup();

    // useOnMount(() => {

    //     loadTables();
    //     if(!moduleGroup?.loaded){
    //         api.tableGroup.loadTableGroups().then(response => {
    //             let groups = response?.data ?? [];
    //             setModuleGroup({groups,loaded:true});
    //         });
    //     }
    // });

    // const loadTables = async () => {
    //     let [err, tableResponse] = await to(api.table.loadTables());
    //     setTable(tableResponse?.data);
    // }


    return (
        //<Provider store={store}>
            <AppLayout>
                <AppRoutes />
            </AppLayout>
        //    <IdleLogout />
        //</Provider>
    );

};

export default memo(App);